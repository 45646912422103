import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SkeletonLoaderIntroComponent } from './skeleton-loader-intro/skeleton-loader-intro.component'
import { SkeletonLoaderFeatureComponent } from './skeleton-loader-feature/skeleton-loader-feature.component'
import { SkeletonLoaderPackageComponent } from './skeleton-loader-package/skeleton-loader-package.component'
import { SkeletonLoaderSwiperComponent } from './skeleton-loader-swiper/skeleton-loader-swiper.component'
import { SkeletonLoaderInvestorComponent } from './skeleton-loader-investor/skeleton-loader-investor.component'

@NgModule({
  declarations: [
    SkeletonLoaderIntroComponent,
    SkeletonLoaderFeatureComponent,
    SkeletonLoaderPackageComponent,
    SkeletonLoaderSwiperComponent,
    SkeletonLoaderInvestorComponent,
  ],
  imports: [CommonModule],
  exports: [
    SkeletonLoaderIntroComponent,
    SkeletonLoaderFeatureComponent,
    SkeletonLoaderPackageComponent,
    SkeletonLoaderSwiperComponent,
    SkeletonLoaderInvestorComponent,
  ],
})
export class SkeletonLoaderModule {}
