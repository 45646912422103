import { Injectable, PLATFORM_ID, Inject } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'

@Injectable({
  providedIn: 'root',
})
export class ApplicationStateService {
  private isMobileResolution: boolean
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      //  && !environment.production
      if (window.innerWidth < 768) {
        this.isMobileResolution = true
      } else {
        this.isMobileResolution = false
      }
    }
  }

  public getIsMobileResolution(): boolean {
    return this.isMobileResolution
  }
}
