import { Injectable } from '@angular/core'
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment';

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
  constructor(public tokenExtractorService: HttpXsrfTokenExtractor) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const host = environment.eventTrackerEndpointUrl

    const xsrfToken = this.tokenExtractorService.getToken() as string
    const headerName = 'X-XSRF-TOKEN'

    if (req.url.startsWith(host) && xsrfToken !== null && !req.headers.has(headerName)) {
      req = req.clone({
        withCredentials: true,
        headers: req.headers.set(headerName, xsrfToken),
      })
    }

    return next.handle(req)
  }
}
