<section class="flowaccount-faq-accordion-squidex">
  <h2 class="faq-title">{{ headingDefault }}</h2>
  <div class="box-container">
    <ng-container *ngFor="let faq of ServiceList; let i = index">
      <div class="faq-box">
        <section class="faq-box__toggle" [ngClass]="{ active: checkCurrentFaqTab(i + 1) }">
          <div class="title" (click)="onClickToggleFAQ(i + 1)">
            <div
              class="toggle-title"
              [innerHtml]="translate.currentLang === 'th' ? faq.data.heading.th : faq.data.heading.en"
            ></div>
            <i class="fa fa-angle-{{ checkCurrentFaqTab(i + 1) ? 'up' : 'down' }}"></i>
          </div>
          <div
            class="faq-box__content content-i-{{ i + 1 }}"
            [ngClass]="{ active: checkCurrentFaqTab(i + 1) }"
            [innerHtml]="translate.currentLang === 'th' ? faq.data.description.th : faq.data.description.en"
          ></div>
        </section>
      </div>
    </ng-container>
  </div>
</section>
