import { Action, createAction, props } from '@ngrx/store'

export function getDefaultTranslation(): 'th' | 'en' {
  const url = new URL(window.location.href)
  let lang: 'th' | 'en' = 'th'
  if (url.pathname.startsWith('/en')) {
    lang = 'en'
  }
  return lang
}

export function getLanguagePath(lang) {
  return lang === 'th' ? '' : 'en'
}

export const ISMOBILE = 'ISMOBILE'
export const ISNOTMOBILE = 'ISNOTMOBILE'
export const SET_FEATURE = 'SET_FEATURE'
export const SET_LANG = 'SET_LANG'
export interface Feature {
  [key: string]: any
}
/**
 * @deprecated move to '@flowaccount/core/ui' IPublicAppState
 */
export interface IAppState {
  isMobile: boolean
  features: Feature
  lang: 'th' | 'en'
  langPath: string
}

export const initialState: IAppState = {
  isMobile: false,
  features: {},
  lang: getDefaultTranslation(),
  langPath: getDefaultTranslation() === 'th' ? '' : getDefaultTranslation(),
}

export function appStateReducer(state: IAppState = initialState, action: Action): IAppState {
  switch (action.type) {
    case ISMOBILE:
      return { ...state, isMobile: true }
    case ISNOTMOBILE:
      return { ...state, isMobile: false }
    case SET_FEATURE:
      return { ...state, features: (action as any).prop }
    case SET_LANG:
      const langPath = (action as any).prop === 'th' ? '' : (action as any).prop
      return { ...state, lang: (action as any).prop, langPath }
    default:
      return state
  }
}
