import { Component, OnInit } from '@angular/core';
import { SharedService } from '@flowaccount/landing';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'flowaccount-mobile-pos-app',
  templateUrl: './mobile-pos-app.component.html',
  styleUrls: ['./mobile-pos-app.component.scss'],
})
export class MobilePosAppComponent implements OnInit {
  constructor(public translate: TranslateService, public sharedService: SharedService) {}

  ngOnInit(): void {}


  linkVideo(youtubeURL: string) {
    return this.sharedService.openVideoTutorialModal(youtubeURL)
  }
}
