<section class="invite-to-use-wrapper" [ngClass]="customClassName && 'page-' + customClassName">
  <div class="box-container">
    <div class="invite-topic">
      <ng-container *ngIf="customHeader; else defaultHeader">
        <div
          class="invite-topic__heading invite-topic__heading--desktop color-text"
          [innerHTML]="displayCustomText(customHeader, 'desktop')"
        >
        </div>
        <div
          class="invite-topic__heading invite-topic__heading--mobile color-text"
          [innerHTML]="displayCustomText(customHeader, 'mobile')"
        >
        </div>
      </ng-container>

      <ng-template #defaultHeader>
        <div class="invite-topic__heading invite-topic__heading--desktop color-text" [innerHTML]="headingDefaultText()">
        </div>
        <div class="invite-topic__heading invite-topic__heading--mobile color-text" [innerHTML]="headingDefaultText()">
        </div>
      </ng-template>
    </div>
    <div class="box">
      <div class="box__btn">
        <a
          href="{{ customLink }}"
          rel="noopener noreferrer"
          target="_blank"
          class="flowbtn app-btn box__btn--layout"
          [innerHTML]="displayCustomText(customHeader, 'button')"
        >
        </a>
      </div>
    </div>
  </div>
</section>
