import {
  TranslateLoader,
  TranslateStore,
  TranslateModule,
  TranslateCompiler,
  TranslateFakeCompiler,
  TranslateParser,
  TranslateDefaultParser,
  MissingTranslationHandler,
  USE_STORE,
  USE_DEFAULT_LANG,
  USE_EXTEND,
  DEFAULT_LANGUAGE,
  TranslateService,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core'
import { TransferStateService, isScullyGenerated } from '@scullyio/ng-lib'
import { HttpClient } from '@angular/common/http'
import { Observable, combineLatest } from 'rxjs'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { ActivatedRoute } from '@angular/router'
import { InjectionToken, Inject, Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { distinctUntilChanged, tap, map } from 'rxjs/operators'
import { getDefaultTranslation, IAppState, SET_LANG } from '../store.ismobile'
export class TranslateBrowserLoader implements TranslateLoader {
  constructor(
    private prefix: string = 'i18n',
    private suffix: string = '.json',
    private transferState: TransferStateService,
    private http: HttpClient,
  ) {}
  public getTranslation(lang: string): Observable<any> {
    const key = 'transfer-translate-' + this.prefix + '-'
    if (isScullyGenerated()) {
      return new Observable<any>(observable => {
        this.transferState.getState(key + lang).subscribe(result => {
          if (result !== undefined) {
            observable.next(result)
            observable.complete()
          }
        })
      })
      return
    } else {
      return combineLatest([
        new TranslateHttpLoader(this.http, this.prefix, this.suffix).getTranslation('en'),
        new TranslateHttpLoader(this.http, this.prefix, this.suffix).getTranslation('th'),
      ]).pipe(
        tap(([translationEn, translationTh]) => {
          this.transferState.setState(key + 'en', translationEn)
          this.transferState.setState(key + 'th', translationTh)
        }),
        map(([translationEn, translationTh]) => {
          if (lang === 'th') {
            return translationTh
          } else {
            return translationEn
          }
        }),
      )
    }
  }
}

export function exportTranslateStaticLoader(http: HttpClient, transferState: TransferStateService, path?: string) {
  return new TranslateBrowserLoader(
    path ? `/assets/i18n/${path}/` : `/assets/i18n/common/`,
    '.json',
    transferState,
    http,
  )
}

const LANG_KEY = 'currentLang'
const PATH_CHILDREN = new InjectionToken('PATH_CHILDREN')
export class CustomTranslateStore extends TranslateStore {}

@Injectable()
export class CustomTranslateService extends TranslateService {
  constructor(
    store: TranslateStore,
    currentLoader: TranslateLoader,
    compiler: TranslateCompiler,
    parser: TranslateParser,
    missingTranslationHandler: MissingTranslationHandler,
    store$: Store<{ appState: IAppState }>,
    @Inject(USE_DEFAULT_LANG) useDefaultLang = true,
    @Inject(USE_STORE) isolate = false,
    @Inject(USE_EXTEND) extend = false,
    @Inject(DEFAULT_LANGUAGE) defaultLanguage: string,
  ) {
    super(
      store,
      currentLoader,
      compiler,
      parser,
      missingTranslationHandler,
      useDefaultLang,
      isolate,
      extend,
      defaultLanguage,
    )
    this.onLangChange.pipe(distinctUntilChanged((a, b) => a.lang === b.lang)).subscribe(event => {
      store$.dispatch({ type: SET_LANG, prop: event.lang })
    })
    store$
      .select(s => s.appState.lang)
      .pipe(distinctUntilChanged())
      .subscribe(lang => {
        this.use(lang)
      })
  }
}

export class ScullyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    return params.key
  }
}

export class CustomTranslateModule {
  static forRoot() {
    return {
      ngModule: TranslateModule,
      providers: [
        {
          provide: TranslateLoader,
          useFactory: exportTranslateStaticLoader,
          deps: [HttpClient, TransferStateService],
        },
        { provide: TranslateCompiler, useClass: TranslateFakeCompiler },
        { provide: TranslateParser, useClass: TranslateDefaultParser },
        {
          provide: MissingTranslationHandler,
          useClass: ScullyMissingTranslationHandler,
        },
        TranslateStore,
        { provide: USE_STORE, useValue: true },
        { provide: USE_DEFAULT_LANG, useValue: true },
        { provide: USE_EXTEND, useValue: false },
        {
          provide: DEFAULT_LANGUAGE,
          useFactory: getDefaultTranslation,
          // deps: [ActivatedRoute, TranslateStore]
        },
        { provide: TranslateService, useClass: CustomTranslateService },
      ],
    }
  }
  static forChild(config: { path: string }) {
    return {
      ngModule: TranslateModule,
      providers: [
        {
          provide: TranslateLoader,
          useFactory: exportTranslateStaticLoader,
          deps: [HttpClient, TransferStateService, PATH_CHILDREN],
        },
        { provide: TranslateCompiler, useClass: TranslateFakeCompiler },
        { provide: TranslateParser, useClass: TranslateDefaultParser },
        {
          provide: MissingTranslationHandler,
          useClass: ScullyMissingTranslationHandler,
        },
        { provide: PATH_CHILDREN, useValue: config.path },
        { provide: USE_STORE, useValue: true },
        { provide: USE_DEFAULT_LANG, useValue: true },
        { provide: USE_EXTEND, useValue: false },
        {
          provide: DEFAULT_LANGUAGE,
          useFactory: getDefaultTranslation,
          deps: [ActivatedRoute, TranslateStore],
        },
        { provide: TranslateService, useClass: CustomTranslateService },
      ],
    }
  }
}
