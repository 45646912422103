import { Inject, Injectable } from '@angular/core'
import { Experiment, GrowthBook, Result, TrackingData } from '@growthbook/growthbook'
import { GROWTHBOOK_CONFIG, GrowthBookConfig, GrowthBookExperiment, IGrowthBookService } from '@flowaccount/landing'

@Injectable({
  providedIn: 'root',
})
export class GrowthBookService implements IGrowthBookService {
  private static instance: GrowthBookService
  private readonly growthBook: GrowthBook
  private experimentResult

  constructor(@Inject(GROWTHBOOK_CONFIG) config: GrowthBookConfig) {
    this.growthBook = new GrowthBook({
      ...config,
      trackingCallback: (experiment, result) => {
        this.experimentResult = {
          experimentId: experiment.key,
          variationId: result.key,
        }
      },
    })
  }

  public static async getInstance(config: GrowthBookConfig): Promise<GrowthBookService> {
    if (!GrowthBookService.instance) {
      GrowthBookService.instance = new GrowthBookService(config)
      await GrowthBookService.instance.growthBook.init({ streaming: true })
    }
    return GrowthBookService.instance
  }

  public getGrowthBook(): GrowthBook {
    return GrowthBookService.instance.growthBook
  }

  public getAllExperimentResults() {
    return GrowthBookService.instance.growthBook.getAllResults()
  }

  public getCurrentExperimentResult(experimentId: string): GrowthBookExperiment {
    const growthBookExperimentResults = this.getAllExperimentResults()
    const experimentData = growthBookExperimentResults.get(experimentId)

    if (!experimentData) {
      return this.getForcedExperimentResult()
    }

    const { experiment, result } = experimentData

    if (result.inExperiment) {
      return this.getIncludeExperimentResult(experiment, result)
    } else {
      return this.getExcludeExperimentResult(experiment)
    }
  }

  public getForcedExperimentResult(): GrowthBookExperiment {
    return {
      experimentName: 'Forced',
      experimentKey: 'Forced',
      variationId: 0,
      variationName: 'Forced',
    }
  }

  public getIncludeExperimentResult(experiment: any, result: any): GrowthBookExperiment {
    return {
      experimentName: experiment.name,
      experimentKey: experiment.key,
      variationId: result.variationId,
      variationName: result.name,
    }
  }

  public getExcludeExperimentResult(experiment: any): GrowthBookExperiment {
    const controlVariation = experiment.meta.find((meta: any) => meta.key === '0')
    return {
      experimentName: experiment.name,
      experimentKey: experiment.key,
      variationId: 0,
      variationName: controlVariation ? controlVariation.name : 'Control',
    }
  }
}
