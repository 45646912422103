import { Component, Inject, OnInit, PLATFORM_ID, Optional, APP_ID } from '@angular/core'
import { isPlatformServer } from '@angular/common'
import { RESPONSE } from '../../../express.tokens'
import { Meta } from '@angular/platform-browser'
interface PartialResponse {
  statusMessage: string
  status(code: number): this
}
@Component({
  selector: 'flowaccount-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss'],
})
export class NotfoundComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(APP_ID) private appId: string,
    @Optional() @Inject(RESPONSE) private response: PartialResponse,
    private meta: Meta,
  ) {
    this.meta.updateTag({ name: 'robots', content: 'noindex' })
  }

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      // Only executes server side
      if (this.response) {
        this.response.status(404)
        this.response.statusMessage = '404 - Page Not Found'
      }
    }
  }
}
