import { Component, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'flowaccount-content-call-to-action-privilege',
  templateUrl: './content-call-to-action-privilege.component.html',
  styleUrls: ['./content-call-to-action-privilege.component.scss'],
})
export class ContentCallToActionPrivilegeComponent {
  @Input() styleClass: string
  @Input() contentCTA_1: string
  @Input() contentCTA_2: string
  @Input() contentCTA_mobile: string
  @Input() contentTextLink: string
  constructor(public translate: TranslateService) {}
}
