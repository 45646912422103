import { UserAnalyticEvents as GBEvents } from './event-collector.model'

export const GrowthBookEventData = {
  [GBEvents.DemoSchedule]: {
    StickyDemoSchedule: {
      dataId: '101',
      dataName: 'Sticky Demo Schedule',
    },
    DemoNavBar: {
      dataId: '102',
      dataName: 'Demo NavBar',
    },
    DemoHeroSection: {
      dataId: '103',
      dataName: 'Demo Hero Section',
    },
  },
  [GBEvents.PackageSelection]: {
    Free: {
      dataId: '101',
      dataName: 'Free',
    },
    Standard: {
      dataId: '102',
      dataName: 'Standard',
    },
    Pro: {
      dataId: '103',
      dataName: 'Pro',
    },
    ProBusiness: {
      dataId: '104',
      dataName: 'ProBusiness',
    },
  },
  [GBEvents.PeriodTypeToggle]: {
    MonthPeriod: {
      dataId: '101',
      dataName: 'Month Period',
    },
    YearPeriod: {
      dataId: '102',
      dataName: 'Year Period',
    },
  },
  [GBEvents.Signup]: {
    SignupHeroSection: {
      dataId: '101',
      dataName: 'Signup Hero Section',
    },
    SignupNavBar: {
      dataId: '102',
      dataName: 'Signup NavBar',
    },
  },
  [GBEvents.Login]: {
    LoginNavBar: {
      dataId: '102',
      dataName: 'Login NavBar',
    },
  },
  [GBEvents.Logo]: {
    LogoNavBar: {
      dataId: '102',
      dataName: 'Logo NavBar',
    },
  },
  [GBEvents.SwiperSlideButton]: {
    LeftHeroSection: {
      dataId: '101',
      dataName: 'Left Slide Hero Section',
    },
    RightHeroSection: {
      dataId: '102',
      dataName: 'Right Slide Hero Section',
    },
  },
  [GBEvents.SwiperPaginationBullet]: {
    PaginationBulletHeroSection: {
      dataId: '101',
      dataName: 'Pagination Bullet Hero Section',
    },
  },
  [GBEvents.SwiperCard]: {
    SwiperCardHeroSection: {
      dataId: '101',
      dataName: 'Swiper Card Hero Section',
    },
  },
}
