//Move to @financial-domain-feature
export class Blog {
  coverUrlWebp: string
  coverUrlJpg: string
  heading: string
  shortDescription: string
  link: string
  imageTitle: string
  imageAlt: string
}

export enum Pages {
  Home,
  FunctionBusiness,
  FunctionAccounting,
  FunctionQuotation,
  FunctionReceipt,
  FunctionBillingNoteAndInvoice,
  FunctionTaxInvoice,
  FunctionMobilePosPage,
  Pricing,
  AccountingfirmBusinessOwner,
  AccountingfirmAccountant,
  AccountingfirmBecomeOurPartner,
  Developers,
  Seminars,
  Tutorials,
  MigrateNewFlowAccount,
  Ultimateguide,
  BusinessRegistration,
  VatWhtCalculation,
  Reviews,
}
