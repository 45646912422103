<!-- 1 -->
<flowaccount-mobile-pos-intro></flowaccount-mobile-pos-intro>

<!-- 2 -->
<flowaccount-mobile-pos-cashier></flowaccount-mobile-pos-cashier>

<!-- 3 -->
<flowaccount-mobile-pos-sunmi></flowaccount-mobile-pos-sunmi>

<flowaccount-mobile-pos-thermal-printers></flowaccount-mobile-pos-thermal-printers>

<!-- 4 -->
<flowaccount-mobile-pos-app></flowaccount-mobile-pos-app>

<!-- comment เนื่องจากรายงานในวิดีโอคือรายงานของแอปพลิเคชั่นฟีเจอร์ทั้งหมด ไม่ใช่ POS -->
<!-- <flowaccount-mobile-pos-video></flowaccount-mobile-pos-video> -->

<flowaccount-invite-to-use-section
  [customHeader]="customInviteSection"
  [customClassName]="'mobile-pos'"
  [customLink]="'https://form.flowaccount.com/demo-request-mobile-application'"
></flowaccount-invite-to-use-section>
<!-- 5 -->
<flowaccount-blog-swiper-squidex
  [customHeader]="customHeader"
  [lang]="translate.currentLang"
  [contentSquidex]="'mobilepos-blog'"
  [contentScully]="'mobilepos-blog-key'"
></flowaccount-blog-swiper-squidex>

<flowaccount-faq-accordion-squidex [contentFaq]="'mobile-pos-faq'"></flowaccount-faq-accordion-squidex>

<flowaccount-content-call-to-action-privilege
  [contentCTA_1]="'CONTENT_CTA_PRIVILEGE_MOBILE-POS.HEADING_1'"
  [contentCTA_2]="'CONTENT_CTA_PRIVILEGE_MOBILE-POS.HEADING_2'"
  [contentCTA_mobile]="'CONTENT_CTA_PRIVILEGE_MOBILE-POS.HEADING_MOBILE'"
  [contentTextLink]="'CONTENT_CTA_PRIVILEGE_MOBILE-POS.BUTTON_LINK'"
>
</flowaccount-content-call-to-action-privilege>
