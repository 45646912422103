import { Injectable, Inject } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { Router } from '@angular/router'
import { Meta } from '@angular/platform-browser'

@Injectable()
export class SEOService {
  constructor(@Inject(DOCUMENT) private dom, private router: Router, private meta: Meta) {}
  createCanonicalURL(metaDescription: string) {
    const link: HTMLLinkElement = this.dom.getElementById('canonicalLink')
    link.setAttribute('href', 'https://flowaccount.com' + this.router.url)
    this.meta.updateTag({ name: 'description', content: metaDescription })
  }

  createMetaTag(metaTitle: string, metaDescription: string) {
    const link: HTMLLinkElement = this.dom.getElementById('canonicalLink')
    link.setAttribute('href', 'https://flowaccount.com' + this.router.url)
    this.meta.updateTag({ name: 'title', content: metaTitle })
    this.meta.updateTag({ name: 'description', content: metaDescription })
  }

  createOg(ogImage, ogTitle, ogDescription) {
    const ogUrl = 'https://flowaccount.com' + this.router.url
    this.meta.updateTag({
      property: 'og:url',
      content: ogUrl,
    })
    this.meta.updateTag({
      property: 'og:image',
      content: ogImage,
    })
    this.meta.updateTag({
      property: 'og:title',
      content: ogTitle,
    })
    this.meta.updateTag({
      property: 'og:description',
      content: ogDescription,
    })
  }
  setCanonicalUrl(url: string) {
    let link: HTMLLinkElement = document.querySelector("link[rel='canonical']")

    if (link) {
      link.setAttribute('href', url)
    } else {
      link = document.createElement('link')
      link.setAttribute('rel', 'canonical')
      link.setAttribute('href', url)
      document.head.appendChild(link)
    }
  }
}
