<section class="mobile-pos-intro">
  <div class="box">
    <div class="box__left">
      <div class="box__subheading color-gray">
        <picture>
          <source srcset="https://flowaccountcdn.com/new_landing/icon/MobilePOS_Logo-into.webp" type="image/webp" />
          <img
            src="https://flowaccountcdn.com/new_landing/icon/MobilePOS_Logo-into.png"
            class="MobilePOS-icon"
            alt="MobilePOS icon"
          />
        </picture>
        <span style="padding-top: 3px"> MobilePOS</span>
      </div>
      <h1 class="box__heading color-gray box__heading {{ translate.currentLang }}"
        >{{ 'MOBILE_POS.INTRO.HEADING' | translate }}
      </h1>

      <div class="box__heading-detail box__heading--mw">
        {{ 'MOBILE_POS.INTRO.DETAIL' | translate }}
      </div>
      <div class="box__left__btn desktop">
        <button class="box__left__btn--layout" (click)="showImage()"
          ><span>{{ 'MOBILE_POS.INTRO.BTN_DOWNLOAD_APP' | translate }}</span></button
        >
      </div>
      <div class="box__left__btn mobile">
        <button class="box__left__btn--layout" (click)="showImageMobile()"
          ><span>{{ 'MOBILE_POS.INTRO.BTN_DOWNLOAD_APP' | translate }}</span></button
        >
      </div>
    </div>
    <div class="box__right">
      <picture>
        <source
          srcset="https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobilepos_section_1.webp"
          type="image/webp"
        />
        <img
          src="https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobilepos_section_1.png"
          loading="eager"
          alt="{{ 'MOBILE_POS.INTRO.ALT' | translate }}"
          title="{{ 'MOBILE_POS.INTRO.ALT' | translate }}"
          class="box__image"
        />
      </picture>
    </div>
  </div>
</section>
