import { NgModule } from '@angular/core'
import { NavigationComponent } from './navigation.component'
import { NavigationListComponent } from './navigation-list/navigation-list.component'
import { CustomTranslateModule } from '../services/translate-factory.service'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

@NgModule({
  imports: [CommonModule, CustomTranslateModule.forRoot(), RouterModule],
  declarations: [NavigationComponent, NavigationListComponent],
  exports: [NavigationComponent, NavigationListComponent],
})
export class NavigationModule {}
