import { Injectable } from '@angular/core'
import { TrafficSourceType } from '../enum/landing-enum';

interface TrafficSourceData {
  referrer: string
  paramsObject: any
  domain: string | null
  utmTags: any
  paidAdChannelData: any
  organicSearchData: any
  referralData: any
}

@Injectable({
  providedIn: 'root',
})
export class TrafficSourceService {
  constructor() {}

  getTrafficSource(): TrafficSourceType {
    const referrer = document.referrer
    const domain = this.getDomain(referrer)
    const urlParams = new URLSearchParams(window.location.search || '')
    const paramsObject = Object.fromEntries(urlParams.entries())
    const utmTags = this.getUtmParameters(paramsObject)
    const paidAdChannelData = this.getPaidAdChannelsData(paramsObject)
    const organicSearchData = this.getSearchEngineData(domain, paramsObject)
    const referralData = domain ? { medium: 'referral', source: domain } : null

    return this.determineTrafficSource({
      referrer,
      paramsObject,
      domain,
      utmTags,
      paidAdChannelData,
      organicSearchData,
      referralData,
    })
  }

  private getDomain(url: string) {
    try {
      url = url.startsWith('http') ? url : `https://${url}`
      const hostname = new URL(url).hostname.split('.')
      const domain = hostname.slice(-2)
      return ['com', 'co'].includes(domain[0]) && domain.join('').length <= 5
        ? hostname.slice(-3).join('.')
        : hostname.slice(-2).join('.')
    } catch (e) {
      return null
    }
  }

  private getUtmParameters(paramsObject: any) {
    const utmMap: any = {
      utm_source: 'source',
      utm_medium: 'medium',
      utm_campaign: 'campaign',
      utm_content: 'content',
      utm_term: 'term',
    }
    const utmParams: any = {}
    for (const key in paramsObject) {
      if (utmMap.hasOwnProperty(key)) {
        utmParams[utmMap[key]] = paramsObject[key]
      }
    }
    return Object.keys(utmParams).length > 0 ? utmParams : null
  }

  private getPaidAdChannelsData(paramsObject: any) {
    const channelsMap: any = {
      gclid: { source: 'google', medium: 'cpc' },
      dclid: { source: 'google', medium: 'cpm' },
      fbclid: { source: 'facebook', medium: 'cpc' },
      msclkid: { source: 'bing', medium: 'cpc' },
      gclsrc: { source: 'google', medium: 'cpc' },
      wbraid: { source: 'google', medium: 'cpc' },
      gbraid: { source: 'google', medium: 'cpc' },
    }
    for (const key in paramsObject) {
      if (channelsMap.hasOwnProperty(key)) {
        return channelsMap[key]
      }
    }
    return null
  }

  private getSearchEngineData(domain: string, paramsObject: any) {
    if (!domain) {
      return null
    }

    const searchEngines: any = {
      google: { p: ['q', 'utm_keyword', 'utm_term', 'keyword'], n: 'google' },
      yahoo: { p: ['p'], n: 'yahoo' },
      msn: { p: ['q'], n: 'msn' },
      bing: { p: ['q'], n: 'bing' },
      duckduckgo: { n: 'duckduckgo' },
    }

    const extractTerm = (engine: string, params: any) => {
      const searchEngine = searchEngines[engine]
      if (searchEngine) {
        for (const param of searchEngine.p || []) {
          if (params.hasOwnProperty(param)) {
            return params[param]
          }
        }
      }
      return null
    }

    const hostname = this.extractHostname(domain)
    if (searchEngines.hasOwnProperty(hostname)) {
      const term = extractTerm(hostname, paramsObject)
      return { source: searchEngines[hostname].n, medium: 'organic', term: term }
    }

    return null
  }

  private extractHostname(domain: string): string {
    domain = domain.replace(/^www\./, '')

    const domainParts = domain.split('.')
    return domainParts.length > 2 ? domainParts[domainParts.length - 2] : domainParts[0]
  }

  private determineTrafficSource(data: TrafficSourceData) {
    const { referrer, paramsObject, utmTags, paidAdChannelData, organicSearchData, referralData } = data

    if (this.isDirectSource(referrer, paramsObject)) {
      return TrafficSourceType.DIRECT_TRAFFIC
    }

    if (this.isReferralSource(referralData, organicSearchData, paidAdChannelData)) {
      return TrafficSourceType.REFERRAL_TRAFFIC
    }

    if (organicSearchData) {
      return TrafficSourceType.ORGANIC_SEARCH_TRAFFIC
    }

    if (this.isPaidSearchSource(paidAdChannelData, utmTags)) {
      return TrafficSourceType.PAID_SEARCH_TRAFFIC
    }

    if (this.isMailSource(utmTags)) {
      return TrafficSourceType.MAIL_TRAFFIC
    }

    if (this.isSocialSource(data.domain, utmTags)) {
      return TrafficSourceType.SOCIAL_TRAFFIC
    }

    return TrafficSourceType.UNKNOWN_TRAFFIC
  }

  private isDirectSource(referrer: string, paramsObject: any): boolean {
    return !referrer && Object.keys(paramsObject).length === 0
  }

  private isReferralSource(referralData: any, organicSearchData: any, paidAdChannelData: any): boolean {
    return referralData && !organicSearchData && !paidAdChannelData
  }

  private isPaidSearchSource(paidAdChannelData: any, utmTags: any): boolean {
    return paidAdChannelData || (utmTags && utmTags.medium === 'cpc')
  }

  private isMailSource(utmTags: any): boolean {
    return utmTags && utmTags.medium === 'email'
  }

  private isSocialSource(domain: string | null, utmTags: any): boolean {
    const socialDomains = [
      'facebook.com',
      'twitter.com',
      'instagram.com',
      'linkedin.com',
      'pinterest.com',
      'reddit.com',
      'youtube.com',
    ]

    return (utmTags && utmTags.medium === 'social') || (domain && socialDomains.includes(domain))
  }

  getUtms(): {
    utmSource: string
    utmMedium: string
    utmCampaign: string
    utmTerm: string
    utmContent: string
  } {
    const urlParams = new URLSearchParams(window.location.search)
    return {
      utmSource: urlParams.get('utm_source') || '',
      utmMedium: urlParams.get('utm_medium') || '',
      utmCampaign: urlParams.get('utm_campaign') || '',
      utmTerm: urlParams.get('utm_term') || '',
      utmContent: urlParams.get('utm_content') || '',
    }
  }
}
