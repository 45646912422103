import { Injectable, Inject, PLATFORM_ID } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { Router } from '@angular/router'
import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import { isDevMode } from '@angular/core'

@Injectable()
export class ReferrerService {
  constructor(
    private cookieService: CookieService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  public create() {
    if (isPlatformBrowser(this.platformId)) this._createCookies()
  }

  private _createCookies() {
    let domain = ''
    const expiredDate = new Date()
    const directUrl: string = decodeURIComponent('https://flowaccount.com')
    expiredDate.setDate(expiredDate.getDate() + 90)

    domain = isDevMode() ? this.document.domain : '.flowaccount.com'
    //console.log('cookie domain', domain, isDevMode());
    // if (this.document.referrer === '') {
    //   this.cookieService.set('ReferrerUrl', directUrl, expiredDate, '/', domain)
    //   this.cookieService.set('ReferrerUrlQuery', directUrl, expiredDate, '/', domain)
    // } else {
    //   this.cookieService.set('ReferrerUrl', decodeURIComponent(document.referrer), expiredDate, '/', domain)
    //   this.cookieService.set('ReferrerUrlQuery', decodeURIComponent(document.URL), expiredDate, '/', domain)
    // }

    /*
     *  จะเปลี่ยนค่า ReferrerUrl และ ReferrerUrlQuery เมื่อไม่มีค่าอยู่เท่านั้น
     */

    if (!this.cookieService.check('ReferrerUrlQuery')) {
      // Check referrer is exist or not
      if (this.document.referrer === '') {
        this.cookieService.set('ReferrerUrl', directUrl, expiredDate, '/', domain, false, 'Strict')
      } else {
        this.cookieService.set(
          'ReferrerUrl',
          decodeURIComponent(document.referrer),
          expiredDate,
          '/',
          domain,
          false,
          'Strict',
        )
      }
      // 
      this.cookieService.set(
        'ReferrerUrlQuery',
        decodeURIComponent(document.URL),
        expiredDate,
        '/',
        domain,
        false,
        'Strict',
      )
    }
  }
}
