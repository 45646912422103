import {
  Component,
  OnInit,
  ElementRef,
  Inject,
  PLATFORM_ID,
  HostListener,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ViewChild,
  Renderer2,
  EventEmitter,
  Output,
  Input,
} from '@angular/core'
import { isPlatformBrowser, DOCUMENT } from '@angular/common'
import { TranslateService } from '@ngx-translate/core'
import { Title, Meta } from '@angular/platform-browser'
import { Router, NavigationEnd } from '@angular/router'
import { IAppState } from '../store.ismobile'
import { Store } from '@ngrx/store'
import { Observable, filter } from 'rxjs'
import { DeviceDetectorService } from 'ngx-device-detector'
import { StateSetup, StateItem } from '../component-deps'
import { CookieService } from 'ngx-cookie-service'
import { timeStamp } from 'console'
import { SignupFlowService } from '../services/signup-flow.service'
let initialMenuTop: any
@Component({
  selector: 'landing-navigation',
  templateUrl: './navigation.component.html',
  // styleUrls: [
  // './navigation.component.scss',
  //   'navigation-hamburger.scss',
  //   'navigation-list-menu.scss'
  // ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
@StateSetup(() => {
  const script = `
    function isSafariBrowser() {
      var ua = navigator.userAgent.toLowerCase();
      if(getMobileOperatingSystem() === 'iOS') {
        if(navigator.userAgent.match('CriOS')) {
          return false; // chrome
        } else {
          return true; // safari
        }
      } else {
        if (ua.indexOf('safari') != -1) {
          if (ua.indexOf('chrome') > -1) {
            return false; // Chrome
          } else {
            return true; // Safari
          }
        }
      }
      return false;
    }
    function getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
          // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }
        if (/android/i.test(userAgent)) {
            return "Android";
        }
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }
    function initialMenuTop() {
      const menuContainer = document.querySelector('section.menu-section');
        if (menuContainer) {
          if (window.innerWidth < 768 && window.scrollY == 0 && !isSafariBrowser()) {
            menuContainer.setAttribute('style', 'top:unset');
          } else {
            menuContainer.setAttribute('style', 'top:0');
          }
        }
    }
    function onMobileMenuClick() {
     window['navigation-component-state'].mobileMenuChecked = !window['navigation-component-state'].mobileMenuChecked;
    }
    function onCloseDownloadApp() {
      window['navigation-component-state'].isMobileAppDownloadBarClosed = true;
      document.querySelector('section.menu-section').setAttribute('style', 'top:0');
      document.getElementById('downloadapp').remove();
    }
    window.addEventListener('load', function() {
        initialMenuTop();
        window.addEventListener("scroll", function(e) {
          const menuContainer = document.querySelector('section.menu-section');
          if (window.innerWidth < 768 && window.scrollY === 0 && !isSafariBrowser() && !window['navigation-component-state'].isMobileAppDownloadBarClosed) {
            menuContainer.setAttribute('style', 'top:unset');
          } else {
            menuContainer.setAttribute('style', 'top:0');
          }
        });
        if (getMobileOperatingSystem() === 'iOS') {
          document.getElementById('downloadAppLink-downloadbar').setAttribute('href', 'https://itunes.apple.com/th/app/new-flowaccount/id1454207520');
        } else if (getMobileOperatingSystem() === 'Android') {
          document.getElementById('downloadAppLink-downloadbar')
              .setAttribute('href', 'https://play.google.com/store/apps/details?id=com.flowaccount.android&hl=th');
        }
    });
      `
  return {
    name: 'navigation-component-state',
    state: { mobileMenuChecked: false, isMobileAppDownloadBarClosed: false },
    script: script,
  }
})
export class NavigationComponent implements OnInit {
  public openMenu = {
    function: false,
    accountingFirm: false,
    more: false,
  }
  @StateItem(() => {
    if (window['navigation-component-state'] && window['navigation-component-state'].isMobileAppDownloadBarClosed) {
      return window['navigation-component-state'].isMobileAppDownloadBarClosed
    }
    return null
  })
  public isClose = false
  public openMenuFunction = false
  public openMenuAccountingfirm = false
  public openMenuMore = false
  public showPc = true
  public min_width_menu = 900 // for hamburger menu
  public language: Observable<string>
  private currentPath = ''
  @ViewChild('downloadApp') downloadApp: ElementRef
  @ViewChild('menuSection') menuSection: ElementRef
  @StateItem(() => {
    if (window['navigation-component-state'] && window['navigation-component-state'].mobileMenuChecked) {
      return window['navigation-component-state'].mobileMenuChecked
    }
    return null
  })
  public mobileMenuChecked = false
  public currentMenuState = 'initial'
  public isMobile: Observable<boolean>
  @Output() trackLogoNavEvent = new EventEmitter<{ dataId: string; dataName: string }>()
  @Output() trackSignupNavEvent = new EventEmitter<{ dataId: string; dataName: string }>()
  @Output() trackLoginNavEvent = new EventEmitter<{ dataId: string; dataName: string }>()
  @Output() trackDemoNavEvent = new EventEmitter<{ dataId: string; dataName: string }>()

  public isOnNavbar2022 = true

  constructor(
    private element: ElementRef,
    public meta: Meta,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Record<string, any>,
    public translate: TranslateService,
    private router: Router,
    private el: ElementRef,
    private deviceService: DeviceDetectorService,
    private store: Store<{ appState: IAppState }>,
    private renderer: Renderer2,
    public signupFlowService: SignupFlowService,
  ) {
    this.language = this.store.select((s) => s.appState.langPath)
    this.isMobile = this.store.select((s) => s.appState.isMobile)
  }

  public firstScroll = true
  public offsetFirst = 0
  public switchLanguage(e, language: string) {
    e.preventDefault()
    this.translate
      .use(language)
      .toPromise()
      .then((lang) => {
        if (this.router.url.indexOf('/en') > -1) {
          const url = decodeURIComponent(this.router.url.replace('/en', ''))
          this.router.navigateByUrl(url)
        } else {
          let routerUrl = this.router.url
          if (routerUrl === '/th' || routerUrl === '/') {
            routerUrl = ''
          }
          const url = decodeURIComponent('/' + language + routerUrl)
            .replace('/th', '')
            .replace('/?', '?')
          this.router.navigateByUrl(url)
        }
      })
  }
  ngOnInit() {
    if (this.isClose) {
      this.downloadApp.nativeElement.remove()
      this.menuSection.nativeElement.setAttribute('style', 'top:0')
    } else if (initialMenuTop) {
      initialMenuTop()
    } else {
      setTimeout(() => {
        const menuContainer = document.querySelector('section.menu-section')
        if (menuContainer) {
          if (window.innerWidth < 768 && window.scrollY == 0 && this.deviceService.browser.indexOf('Safari') === -1) {
            menuContainer.setAttribute('style', 'top:unset')
          } else if (window.scrollY == 0) {
            menuContainer.setAttribute('style', 'top:0')
          }
        }
      })
    }

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const url = event.urlAfterRedirects
      this.currentPath = url.substring(url.lastIndexOf('/') + 1)
    })
  }

  setCookiePath() {
    this.signupFlowService.setCookie(this.currentPath, true)
  }

  goToSignup() {
    this.trackSignupNavEvent.emit({ dataId: '102', dataName: 'signup navbar' })
  }

  goToDemoSchedule() {
    this.trackDemoNavEvent.emit({ dataId: '102', dataName: 'demo schedule navbar' })
  }

  goToLogin() {
    this.trackLoginNavEvent.emit({ dataId: '102', dataName: 'login navbar' })
  }

  goToHomePage() {
    this.trackLogoNavEvent.emit({ dataId: '102', dataName: 'logo navbar' })
  }
}
