export const environment = {
  production: true,
  baseHref: '/',
  mobile: false,
  seminarSectionPath: 'https://flowaccountcdn.com/Seminar/seminar-slug-production.json',
  seminarFilePath: 'https://flowaccountcdn.com/Seminar/seminar-detail-production.json',
  seminarLecturer: 'https://flowaccountcdn.com/Seminar/lecturer-list.json',
  tmTrackingID: 'GTM-MBJXDQS',
  mainURL: 'https://flowaccount.com/',
  squidexAuth: {
    url: 'https://cloud.squidex.io',
    appName: 'flowaccount-crm',
    clientId: 'flowaccount-crm:landing',
    clientSecret: '9mx6xoejfcwx8rrtisfns8bjrkuunzqqhvtrxnovs24x',
  },
  numberOfSmallBusiness: '120,000',
  numberOfAccountingFirmPartner: '2,000',
  growthBookEndpoint: {
    apiHost: 'https://cdn.growthbook.io',
    clientKey: 'sdk-v31a6jhUOMlD0A2',
    enableDevMode: true,
  },
  eventTrackerEndpointUrl: 'https://growthbook-api.flowaccount.com/prod',
  eventTrackerEndpointPaths: {
    collect: '/collector',
    csrfGenerator: '/csrf-generator',
  },
  domain: '.flowaccount.com',
}
