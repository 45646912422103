/* tslint:disable */
export class GoogleTagManager {
  static run(config: { tmTrackingID: string }) {
    const tag = document.createElement('script')
    tag.async = true
    tag.src = `https://www.googletagmanager.com/gtm.js?id=${config.tmTrackingID}`
    tag.setAttribute('defer', '')
    document.documentElement.firstChild.appendChild(tag)
    window['dataLayer'] = window['dataLayer'] || []
    window['gtag'] =
      window['gtag'] ||
      function(...args: any[]) {
        window['dataLayer'].push(arguments)
      }
    window['gtag']('js', new Date())
    window['gtag']('config', config.tmTrackingID)
  }
}
