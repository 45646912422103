import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'flowaccount-invite-to-use-section',
  templateUrl: './invite-to-use-section.component.html',
  styleUrls: ['./invite-to-use-section.component.scss']
})
export class InviteToUseSectionComponent implements OnInit {
  @Input() customHeader = ''
  @Input() customClassName = 'default'

  @Input() customLink = ''

  public textHeading
  textButton: any;

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
  }

  headingDefaultText(): string {
    const lang = this.translate.currentLang
    return lang === 'th' ? 'สนใจสมัคร FlowAccount คลิกที่นี่' : 'สนใจสมัคร FlowAccount คลิกที่นี่'
  }

  displayCustomText(textObject, textPosition) {
    const lang = this.translate.currentLang
    const { headingDesktopTH, headingDesktopEN, headingMobileTH, headingMobileEN, buttonNameTH, buttonNameEN } = textObject

    switch (textPosition) {
      case 'desktop':
        return lang === 'th' ? headingDesktopTH : headingDesktopEN
      case 'mobile':
        return lang === 'th' ? headingMobileTH : headingMobileEN
      case 'button':
        return lang === 'th' ? buttonNameTH : buttonNameEN
    }
  }

}
