<section class="sitemap">
  <div class="box">
    <div class="box-wrapper">
      <ng-container *ngFor="let item of contentColumns">
        <div class="box-wrapper__element" [ngClass]="item.class">
          <div class="element-heading"
            ><span>{{ item.heading | translate }}</span></div
          >
          <div class="element-detail">
            <ng-container *ngFor="let item2 of item.link">
              <div class="element-detail__content">
                <a
                  *ngIf="!item2.isThaiOnly"
                  class="element-detail__content--layout"
                  rel="noreferrer noopener"
                  target="_blank"
                  href="https://flowaccount.com/{{ translate.currentLang }}/{{ item2.path }}"
                  ><span>{{ item2.label | translate }}</span>
                </a>
                <a
                  *ngIf="item2.isThaiOnly"
                  class="element-detail__content--layout"
                  rel="noreferrer noopener"
                  target="_blank"
                  href="https://flowaccount.com/{{ item2.path }}"
                  ><span>{{ item2.label | translate }}</span>
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>
