<section class="content-call-to-action-privilege" [ngClass]="styleClass">
  <div class="box">
    <div class="box-wrapper">
      <h3 class="box-wrapper__heading desktop"
        ><span> {{ contentCTA_1 | translate }}</span
        ><br />{{ contentCTA_2 | translate }}</h3
      >
      <div class="box-wrapper__heading mobile"
        ><span> {{ contentCTA_mobile | translate }}</span></div
      >
      <a
        class="box-wrapper__btn"
        href="https://flowaccount.com/{{ translate.currentLang }}/privilege"
        rel="noopener noreferrer"
        target="_blank"
        ><span>{{ contentTextLink | translate }}</span></a
      >
    </div>
  </div>
</section>
