import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { GoogleTagManager } from './scripts'
import { isScullyRunning } from '@scullyio/ng-lib'

//environment.production = true
if (environment.production) {
  enableProdMode()
  // GoogleTagManager.run({ tmTrackingID: environment.tmTrackingID })
  if (!isScullyRunning()) {
    GoogleTagManager.run({ tmTrackingID: environment.tmTrackingID })
  }
} else {
  // mock gtag
  // tslint:disable-next-line
  window['gtag'] = () => {
    console.log('gtag called')
    return null
  }
}

// document.addEventListener('DOMContentLoaded', () => {
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err))
// })
