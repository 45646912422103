import {
  Component,
  OnInit,
  ElementRef,
  Inject,
  HostBinding,
  ChangeDetectionStrategy,
  Injector,
  NgZone,
  ViewEncapsulation,
  ViewChild,
  Renderer2,
  AfterViewInit,
  OnDestroy,
} from '@angular/core'
import { Router, NavigationEnd, ActivationEnd, NavigationStart, Scroll, ActivationStart } from '@angular/router'
import { DOCUMENT, ViewportScroller } from '@angular/common'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { ReferrerService } from '@flowaccount/landing'
import { Meta } from '@angular/platform-browser'
import { Store, State } from '@ngrx/store'
import { IAppState, ISMOBILE, ISNOTMOBILE, SET_FEATURE } from '@flowaccount/landing'
import { ApplicationStateService } from '@flowaccount/landing'
import { Observable } from 'rxjs'
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2'
import { SafeHtml } from '@angular/platform-browser'
import { TransferStateService, isScullyGenerated, isScullyRunning } from '@scullyio/ng-lib'
import { take, filter, tap } from 'rxjs/operators'
import { StateSetup, StateItem } from '@flowaccount/landing'
import { environment } from '../environments/environment';
import { EventTrackerService } from './services/event-tracker.service'
import { VisitorService } from './services/visitor.service'
import { UserAnalyticDataEvent, UserAnalyticEvents } from './models/event-collector.model'

// declare const FB;
const RESULT_KEY = 'isMobile'
declare let ga: any
declare let FB: any
declare global {
  interface Window {
    google_tag_params: any
    fbAsyncInit: any
  }
}
@Component({
  selector: 'flowaccount-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
@StateSetup(() => {
  const script = `
  if(!!this.window && !!this.window.scrollTo){
    const history = this.window.history;
    if (history && history.scrollRestoration) {
      history.scrollRestoration = "manual";
    }
  }
  window.addEventListener('load', function() {
    window.addEventListener("scroll", function(e){
      window['app-component-state'].scrollPosition = [window.scrollX, window.scrollY];
    })
  });`
  return { name: 'app-component-state', state: { scrollPosition: [0, 0] }, script: script }
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public nativeElement
  public display = 'none'
  public lang = 'th'
  public hideCover = false
  public jsonLD: SafeHtml
  // get year
  public _year: number = new Date().getFullYear()

  /* hasContactUsPage ส่วนของ contact form จะย้ายไปหน้าใหม่ route /contact-us */
  /* ส่วนนี้มีความสำคัญตรง footer จึง if = false ไว้ เผื่อเหตุฉุกเฉแินได้กลับมาเปิดใช้ได้  */
  public hasContactUsPage = true

  scullyRunning = false
  displayCaptcha = false
  routerSubscription: Subscription
  public isMobile: Observable<boolean>
  captchaResponseId = ''
  // public defaultImage = 'https://www.placecage.com/1000/1000';
  // public image = 'https://flowaccountcdn.com/assets/image/cover-page/โปรแกรมบัญชี_flowaccount_special_desktop@2x.jpg';
  @HostBinding('class')
  @ViewChild('contactForm', { static: true })
  contactForm: ElementRef
  get hostClasses(): string {
    return [
      'flowaccount-app-root',
      this.lang, // include our new one
    ].join(' ')
  }
  @StateItem(() => {
    if (window['app-component-state'] && window['app-component-state'].scrollPosition) {
      return window['app-component-state'].scrollPosition
    }
    return null
  })
  private scrollPosition: [number, number] = [0, 0]

  public isSafari = false
  constructor(
    public element: ElementRef,
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    public translate: TranslateService,
    private referrerService: ReferrerService,
    private tstate: TransferStateService,
    private store: Store<{ appState: IAppState }>,
    protected applicationStateService: ApplicationStateService,
    private viewportScroller: ViewportScroller,
    private meta: Meta,
    private renderer: Renderer2,
    private zone: NgZone,
    public readonly swalTargets: SwalPortalTargets,
    private visitorService: VisitorService,
    private eventTrackerService: EventTrackerService,
  ) {
    this.scullyRunning = isScullyRunning()
    this.referrerService.create()
    this.translate.onLangChange.subscribe((event) => (this.document.documentElement.lang = event.lang))
    this.isMobile = this.store.select((s) => s.appState.isMobile)
    router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe((e) => {
      if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition(e.position)
      } else if (e.anchor) {
        // anchor navigation
        viewportScroller.scrollToAnchor(e.anchor)
      } else {
        // forward navigation
        /* e.routerEvent.id ==== 1 หมายถึงการเข้าหน้าจอครั้งแรก หรือrefresh หน้าจอใหม่*/
        if (e.routerEvent.id === 1) {
          /* this.scrollPosition เมื่อ user scroll เร็วๆ แกน y ค่าจะเปลี่ยน และเมื่อ angular load เสร็จค่่า y นั้นยังคงอยู่*/
          viewportScroller.scrollToPosition(this.scrollPosition)
        } else {
          /* ถ้ากดเปลี่ยนหน้า จะ scroll ไปที่ตำแหน่ง [0, 0] เสมอ */
          viewportScroller.scrollToPosition([0, 0])
        }
      }
    })
  }
  ngOnInit() {
    this.displayCaptcha = true
    let isMobile = false
    const ditpatchIsMobile = (_isMobile) => {
      if (_isMobile) {
        this.store.dispatch({ type: ISMOBILE })
      } else {
        this.store.dispatch({ type: ISNOTMOBILE })
      }
    }
    if (isScullyGenerated() && this.tstate.stateHasKey(RESULT_KEY)) {
      this.tstate.getState(RESULT_KEY).pipe(take(1)).subscribe(ditpatchIsMobile)
    } else {
      isMobile = this.applicationStateService.getIsMobileResolution()
      this.tstate.setState(RESULT_KEY, isMobile)
      ditpatchIsMobile(isMobile)
    }

    this.router.events.pipe(filter((e) => e instanceof ActivationStart)).subscribe((e) => {
      //** แก้ว ถ้าจะทำอะไรเกี่ยวกับ scroll ให้เซ็ตค่าแบบนี้ด้วย */
      if (window['app-component-state']) {
        window['app-component-state'].scrollPosition = [0, 0]
      }
    })
    /** Load fb scripts **/

    this.isSafariBrowser()
  }
  ngAfterViewInit(): void {
    this.visitorService.initializeRouterEvent()
  }
  openModal() {
    this.display = 'block'
  }
  onCloseHandled() {
    this.display = 'none'
  }
  isEmail(email) {
    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
    return regex.test(email)
  }

  public submitContact() {
    let isValid = true

    // let $nativeElement = $(this.nativeElement);
    const $contactForm = this.contactForm.nativeElement
    const hasError = $contactForm.querySelector('.has-error')
    if (hasError) {
      hasError.classList.remove('has-error')
    }
    const name = $contactForm.querySelector('#name')
    const phone = $contactForm.querySelector('#phone')
    const email = $contactForm.querySelector('#email')
    const message = $contactForm.querySelector('#message')

    if (message.value.trim() === '') {
      isValid = false
      message.parentNode.classList.add('has-error')
      message.parentNode.querySelector('.lbl-valid').classList.remove('hidden')
      message.focus()
    }

    const intRegexPhone = /[0-9 -()+]+$/
    if (phone.value.trim() === '') {
      isValid = false
      phone.parentNode.classList.add('has-error')
      phone.focus()
    } else {
      if (phone.value.trim().length < 6 || !intRegexPhone.test(phone.value.trim())) {
        isValid = false
        phone.parentNode.classList.add('has-error')
        phone.focus()
      }
    }

    if (email.value.trim() === '') {
      isValid = false
      email.parentNode.classList.add('has-error')
      email.parentNode.querySelector('.lbl-valid').classList.remove('hidden')
      email.focus()
    } else if (!this.isEmail(email.value.trim())) {
      isValid = false
      email.parentNode.classList.add('has-error')
      email.parentNode.querySelector('.lbl-valid').classList.remove('hidden')
      email.focus()
    }

    if (name.value.trim() === '') {
      isValid = false
      name.parentNode.classList.add('has-error')
      name.parentNode.querySelector('.lbl-valid').classList.remove('hidden')
      name.focus()
    }
    if (isValid) {
      name.parentNode.querySelector('.lbl-valid').classList.add('hidden')
      email.parentNode.querySelector('.lbl-valid').classList.add('hidden')
      message.parentNode.querySelector('.lbl-valid').classList.add('hidden')
    }

    return this.captchaResponseId !== '' ? isValid : (isValid = false)
  }
  public resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`)
    // if (captchaResponse = null) {
    //   console.log("helllllllllllllllllllllllllll")
    // }
    this.captchaResponseId = captchaResponse
  }

  goToContact() {
    const target = document.getElementById('contactus')
    target.scrollIntoView({ block: 'end', behavior: 'smooth' })
  }

  isSafariBrowser() {
    const isSafari =
      navigator.vendor.match(/apple/i) &&
      !navigator.userAgent.match(/crios/i) &&
      !navigator.userAgent.match(/fxios/i) &&
      !navigator.userAgent.match(/Opera|OPT\//)

    if (isSafari) {
      // Safari browser is used
      this.isSafari = true
    }
  }

  async onLogoNavEventTrack(eventData: UserAnalyticDataEvent): Promise<void> {
    await this.visitorService.track(UserAnalyticEvents.Logo, 'LoginNavBar')
  }

  async onSignupNavEventTrack(eventData: UserAnalyticDataEvent): Promise<void> {
    await this.visitorService.track(UserAnalyticEvents.Signup, 'SignupNavBar')
  }

  async onLoginNavEventTrack(eventData: UserAnalyticDataEvent): Promise<void> {
    await this.visitorService.track(UserAnalyticEvents.Login, 'LoginNavBar')
  }

  async onDemoNavEventTrack(eventData: UserAnalyticDataEvent): Promise<void> {
    await this.visitorService.track(UserAnalyticEvents.DemoSchedule, 'DemoNavBar')
  }

  ngOnDestroy(): void {
    this.visitorService.ngOnDestroy()
  }
}
