<section class="mobile-pos-thermal-printers-section">
  <div class="box">
    <div class="left">
      <div class="img-wrapper">
        <picture class="desktop">
          <source
            srcset="https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobile_pos_thermal-v2.webp"
            type="image/webp"
          />
          <img
            src="https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobile_pos_thermal-v2.jpg"
            class="img-cover"
            width="520px"
            height="350px"
            alt="{{ 'เครื่องพิมพ์ใบเสร็จ' }}"
            title="{{ 'เครื่องพิมพ์ใบเสร็จ' }}"
            loading="lazy"
          />
        </picture>
        <picture class="mobile">
          <source
            srcset="https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobile_pos_thermal-v2-mobile.webp"
            type="image/webp"
          />
          <img
            src="https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobile_pos_thermal-v2.jpg"
            class="img-cover"
            width="275"
            height="175"
            alt="{{ 'เครื่องพิมพ์ใบเสร็จ' }}"
            title="{{ 'เครื่องพิมพ์ใบเสร็จ' }}"
            loading="lazy"
          />
        </picture>
      </div>
    </div>

    <div class="right">
      <h2 class="visuallyhidden" [innerHtml]="'MOBILE_POS.THERMAL_PRINTERS.HEADING' | translate"></h2>
      <p class="title desktop" [innerHtml]="'MOBILE_POS.THERMAL_PRINTERS.HEADING' | translate"></p>
      <p class="title mobile" [innerHtml]="'MOBILE_POS.THERMAL_PRINTERS.HEADING_MOBILE' | translate"></p>
      <div class="detail" [innerHtml]="'MOBILE_POS.THERMAL_PRINTERS.DETAIL' | translate"> </div>
      <div class="btn">
        <button
          class="btn__video"
          (click)="
            showImage(
              'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/ThermalPrinter_Recommend_v2.jpg'
            )
          "
        >
          <span class="btn__text"> {{ 'MOBILE_POS.THERMAL_PRINTERS.BUTTON' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</section>
