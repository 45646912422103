import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map, Subject, Observable } from 'rxjs'
import { APIResponse } from '@flowaccount/core/common'

export interface ResponseAttendanceUser {
  contact_id: number
  meeting_link: string
  demo_start_date: string
  demo_end_date: string
}

export interface AttendanceUser {
  contactId: number
  email: string
  monthDate: string
  dayDate: string
  startTimeDate: string
  endTimeDate: string
  demoStartDate: string
  demoEndDate: string
  meetingLink: string
}

export interface UserAnalyticAttendanceUser {
  ac_id: number
  email: string
  meeting_link: string
  demo_date: string
  demo_start_time: string
  demo_end_time: string
  clicked: string
}

@Injectable({
  providedIn: 'root',
})
export class SeminarTrackingService {
  private readonly baseUrl: string = 'https://tracking-demo-api.vercel.app'
  public formSubmitSubject = new Subject<any>()
  formSubmit$ = this.formSubmitSubject.asObservable()

  constructor(private httpClient: HttpClient) {}

  triggerFormSubmit(email: string): Observable<any> {
    return this.getMeetingByEmail(email).pipe(
      map((response: APIResponse<ResponseAttendanceUser>) => this.transformResponse(response, email)),
    )
  }

  public getUrl(endpoint: string): string {
    return `${this.baseUrl}${endpoint}`
  }

  public getMeetingByEmail(email: string): Observable<any> {
    const url = this.getUrl('/api/hubspot/free-demo-meeting')
    return this.httpClient.post<any>(url, { email })
  }

  public storeAttendaceUser(user: AttendanceUser): Observable<any> {
    const { contactId, email, meetingLink, startTimeDate, endTimeDate, demoStartDate } = user

    const data: UserAnalyticAttendanceUser = {
      ac_id: contactId,
      email: email,
      meeting_link: meetingLink,
      demo_date: new Date(demoStartDate).toLocaleDateString(),
      demo_start_time: startTimeDate,
      demo_end_time: endTimeDate,
      clicked: new Date().toLocaleString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        weekday: 'long',
        hour: '2-digit',
        hour12: false,
        minute: '2-digit',
        second: '2-digit',
      }),
    }

    const url = this.getUrl('/api/google-sheet/attendance')
    return this.httpClient.post<any>(url, data)
  }

  public transformResponse(response: APIResponse<ResponseAttendanceUser>, email: string): AttendanceUser {
    const data = response.data
    const demoStartDate = new Date(data.demo_start_date)
    const demoEndDate = new Date(data.demo_end_date)

    return {
      contactId: data.contact_id,
      email: email,
      monthDate: demoStartDate.toLocaleString('en-US', { month: 'short' }),
      dayDate: demoStartDate.getDate().toString(),
      startTimeDate: demoStartDate.toTimeString().substring(0, 5),
      endTimeDate: demoEndDate.toTimeString().substring(0, 5),
      demoStartDate: data.demo_start_date,
      demoEndDate: data.demo_end_date,
      meetingLink: data.meeting_link,
    }
  }
}
