import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FeatureItemPipe } from './get-feature.pipe'
import { IsArrayPipe } from './is-array.pipe'
import { SafePipe } from './safe.pipe';
import { DateThFormatPipe } from './date-th-format.pipe'

@NgModule({
  declarations: [FeatureItemPipe, SafePipe, IsArrayPipe, DateThFormatPipe],
  imports: [CommonModule],
  exports: [FeatureItemPipe, SafePipe, IsArrayPipe, DateThFormatPipe],
})
export class PipesModule {}
