import { Injectable } from '@angular/core'
import { Blog } from '../models/blog.model';
import { Pages } from './../models/blog.model'

@Injectable()
export class BlogService {
  lang = 'th'

  // blog card
  getBlogs(lang: string, page: Pages): Blog[] {
    this.lang = lang
    switch (page) {
      case Pages.Home:
        return this.homePage()
      case Pages.FunctionBusiness:
        return this.businessFunctionPage()
      case Pages.FunctionAccounting:
        return this.accontingFunctionPage()
      case Pages.FunctionQuotation:
        return this.quotationFunctionPage()
      case Pages.FunctionReceipt:
        return this.receiptFunctionPage()
      case Pages.FunctionBillingNoteAndInvoice:
        return this.billingNoteAndInvoiceFunctionPage()
      case Pages.FunctionTaxInvoice:
        return this.TaxInvoiceFunctionPage()
      case Pages.FunctionMobilePosPage:
        return this.FunctionMobilePosPage()
      case Pages.Pricing:
        return this.pricingPage()
      case Pages.AccountingfirmBusinessOwner:
        return this.businessOwnerPage()
      case Pages.AccountingfirmAccountant:
        return this.accountantPage()
      case Pages.AccountingfirmBecomeOurPartner:
        return this.accountantPage()
      case Pages.Seminars:
        return this.seminarPage()
      case Pages.Tutorials:
        return this.totorialsPage()
      case Pages.MigrateNewFlowAccount:
        return this.migratPage()
      case Pages.Ultimateguide:
        return this.ultimatePage()
    }
    return this.homePage()
  }

  // blog swiper section
  // now use only in homepage
  getBlogSwiperContents(lang: string, page: Pages): Blog[] {
    this.lang = lang
    switch (page) {
      case Pages.Home:
        return this.homePageContent()
      case Pages.BusinessRegistration:
        return this.businessRegistrationPageContent()
      case Pages.VatWhtCalculation:
        return this.VatWhtCalculationPageContent()
      case Pages.Reviews:
        return this.reviewPageContent()
      case Pages.FunctionBusiness:
        return this.businessFunctionPage()
      case Pages.FunctionAccounting:
        return this.accontingFunctionPage()
      case Pages.AccountingfirmBecomeOurPartner:
        return this.accountantPage()
    }
    return this.homePageContent()
  }

  private homePage() {
    const blogA = new Blog()
    blogA.coverUrlWebp = 'https://flowaccountcdn.com/blogs/blog_9reason.webp'
    blogA.coverUrlJpg = 'https://flowaccountcdn.com/blogs/blog_9reason.png'
    blogA.heading = '9 เหตุผลที่ โปรแกรมบัญชี FlowAccount ช่วยคุณทำธุรกิจง่ายกว่าใคร'
    blogA.shortDescription =
      'แนะนำโซลูชั่นใหม่ๆ จากโปรแกรมบัญชีของเรา ที่จะช่วยบริหารงานเอกสาร เพื่อช่วยให้คุณทำงานได้เร็วขึ้น และยังคุมรายได้และค่าใช้จ่ายได้ทันที'
    blogA.link = 'https://flowaccount.com/blog/new-flowaccount'
    blogA.imageAlt = 'ทำบัญชี FlowAccount'
    blogA.imageTitle = 'ทำบัญชี FlowAccount'

    const blogฺB = new Blog()
    blogฺB.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/desktop/Blog/Blog_new_02.webp'
    blogฺB.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/desktop/Blog/Blog_new_02.jpg'
    blogฺB.heading = 'โปรแกรมบัญชี คลาวด์ ช่วยให้ธุรกิจประสบความสำเร็จได้ยังไง'
    blogฺB.shortDescription =
      'ประโยชน์ของการใช้ ระบบบัญชีคลาวด์ ที่จะช่วยให้ทำงานออนไลน์ร่วมกับทีมงานของคุณได้อย่างราบรื่น และทำให้มีฐานข้อมูลไว้ใช้ในการวางแผนธุรกิจ'
    blogฺB.link =
      'https://flowaccount.com/blog/%E0%B9%82%E0%B8%9B%E0%B8%A3%E0%B9%81%E0%B8%81%E0%B8%A3%E0%B8%A1%E0%B8%9A%E0%B8%B1%E0%B8%8D%E0%B8%8A%E0%B8%B5%E0%B8%84%E0%B8%A5%E0%B8%B2%E0%B8%A7%E0%B8%94%E0%B9%8C'
    blogฺB.imageAlt = 'โหลด โปรแกรมบัญชี'
    blogฺB.imageTitle = 'โหลด โปรแกรมบัญชี'

    const blogC = new Blog()
    blogC.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/desktop/Blog/Blog_new_03.webp'
    blogC.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/desktop/Blog/Blog_new_03.jpg'
    blogC.heading = 'เลือก โปรแกรมบัญชี แบบไหนมาใช้ ถึงตอบโจทย์ นักบัญชี มากที่สุด'
    blogC.shortDescription =
      'วิธีเลือกโปรแกรมบัญชี ออนไลน์ สำหรับนักบัญชี และสำนักงานบัญชี เพื่อให้คุณมีเครื่องมือไว้บริการลูกค้าได้อย่างสะดวก ช่วยลดต้นทุน และมีราคาถูก'
    blogC.link =
      'https://flowaccount.com/blog/%E0%B9%82%E0%B8%9B%E0%B8%A3%E0%B9%81%E0%B8%81%E0%B8%A3%E0%B8%A1%E0%B8%97%E0%B8%B3%E0%B8%9A%E0%B8%B1%E0%B8%8D%E0%B8%8A%E0%B8%B5'
    blogC.imageAlt = 'แนะนำโปรแกรมบัญชี'
    blogC.imageTitle = 'แนะนำโปรแกรมบัญชี'

    return [blogA, blogฺB, blogC]
  }

  private businessFunctionPage() {
    const blogA = new Blog()
    blogA.coverUrlWebp = 'https://flowaccountcdn.com/blogs/business-function-blog-01.webp'
    blogA.coverUrlJpg = 'https://flowaccountcdn.com/blogs/business-function-blog-01.jpg'
    blogA.heading = 'ใบเสนอราคา คืออะไร'
    blogA.shortDescription =
      'เอกสารใบแรกที่เจ้าของธุรกิจไว้ใช้ติดต่อลูกค้า ใช้ FlowAccount ออก ใบเสนอราคา ได้ง่ายๆ ทั้งภาษาไทยและอังกฤษ และยังทำได้ทั้งในมือถือและคอมพิวเตอร์'
    blogA.link = 'https://flowaccount.com/blog/ใบเสนอราคา-quotation/'
    blogA.imageAlt = 'ใบเสนอราคา'
    blogA.imageTitle = 'ใบเสนอราคา'

    const blogB = new Blog()
    blogB.coverUrlWebp = 'https://flowaccountcdn.com/blogs/business-function-blog-02.webp'
    blogB.coverUrlJpg = 'https://flowaccountcdn.com/blogs/business-function-blog-02.jpg'
    blogB.heading = 'ใบวางบิล ใบแจ้งหนี้ คืออะไร'
    blogB.shortDescription =
      'เอกสารที่คนทำธุรกิจบริการและขายของต้องใช้กันบ่อยๆ ทำยังไงถึงจะออก ใบวางบิล ใบแจ้งหนี้ ให้เก็บเงินได้เร็วๆ ก็ใช้โปรแกรมบัญชีช่วยทำเอกสารให้ถูกต้อง'
    blogB.link = 'https://flowaccount.com/blog/ใบวางบิล-ใบแจ้งหนี้/'
    blogB.imageAlt = 'ใบวางบิล ใบแจ้งหนี้'
    blogB.imageTitle = 'ใบวางบิล ใบแจ้งหนี้'

    const blogC = new Blog()
    blogC.coverUrlWebp = 'https://flowaccountcdn.com/blogs/business-function-blog-03.webp'
    blogC.coverUrlJpg = 'https://flowaccountcdn.com/blogs/business-function-blog-03.jpg'
    blogC.heading = 'ใบกำกับภาษี คืออะไร'
    blogC.shortDescription =
      'เอกสารสำหรับธุรกิจที่จดทะเบียนภาษีมูลค่าเพิ่ม (VAT) ต้องออกให้กับผู้รับทุกครั้งที่ขายสินค้าหรือให้บริการ ใช้ FlowAccount ออกได้ทั้งแบบเต็มรูปและอย่างย่อ'
    blogC.link = 'https://flowaccount.com/blog/ใบกำกับภาษี/'
    blogC.imageAlt = 'ใบกำกับภาษี'
    blogC.imageTitle = 'ใบกำกับภาษี'
    return [blogA, blogB, blogC]
  }

  private accontingFunctionPage() {
    const blogA = new Blog()
    blogA.coverUrlWebp = 'https://flowaccountcdn.com/blogs/accounting-function-blog-01.webp'
    blogA.coverUrlJpg = 'https://flowaccountcdn.com/blogs/accounting-function-blog-01.jpg'
    blogA.heading = 'ภ.ง.ด.3 / ภ.ง.ด.53 คืออะไร '
    blogA.shortDescription =
      'แบบยื่นภาษีหัก ณ ที่สามารถใช้ FlowAccount รวบรวมข้อมูลภาษี หัก ณ ที่จ่าย เพื่อใช้เป็นข้อมูลเขียน ภ.ง.ด.3 และ ภ.ง.ด.53 ได้ที่นี่'
    blogA.link = 'https://flowaccount.com/blog/ภ-ง-ด-3-หัก-ณ-ที่จ่าย/'
    blogA.imageAlt = 'ภ.ง.ด.3'
    blogA.imageTitle = 'ภ.ง.ด.3'

    const blogB = new Blog()
    blogB.coverUrlWebp = 'https://flowaccountcdn.com/blogs/accounting-function-blog-02.webp'
    blogB.coverUrlJpg = 'https://flowaccountcdn.com/blogs/accounting-function-blog-02.jpg'
    blogB.heading = 'ภ.ง.ด.50 คืออะไร'
    blogB.shortDescription =
      'เอกสารที่นิติบุคคลใช้สำหรับการยื่นเสียภาษีเต็มปี ลองใช้ FlowAccount ช่วยจัดการข้อมูลบัญชี หนังสือรับรอง หัก ณ ที่จ่าย และงบการเงินได้อย่างสะดวกรวดเร็ว'
    blogB.link = 'https://flowaccount.com/blog/ภ-ง-ด-50/'
    blogB.imageAlt = 'ภ.ง.ด.50'
    blogB.imageTitle = 'ภ.ง.ด.50'

    const blogC = new Blog()
    blogC.coverUrlWebp = 'https://flowaccountcdn.com/blogs/accounting-function-blog-03.webp'
    blogC.coverUrlJpg = 'https://flowaccountcdn.com/blogs/accounting-function-blog-03.jpg'
    blogC.heading = 'ภ.ง.ด.1 ก คืออะไร'
    blogC.shortDescription =
      'เอกสารสรุปรายการจ่ายเงินเดือน และภาษีเงินได้ หัก ณ ที่จ่าย ของพนักงาน ที่ใช้ FlowPayroll โปรแกรมเงินเดือน ออนไลน์ เรียกดูยอดจ่ายเงินเดือนและภาษีสะสมได้ง่ายๆ'
    blogC.link = 'https://flowaccount.com/blog/ภ-ง-ด-1-ก%2F'
    blogC.imageAlt = 'ภ.ง.ด.1 ก'
    blogC.imageTitle = 'ภ.ง.ด.1 ก'

    return [blogA, blogB, blogC]
  }

  private quotationFunctionPage() {
    const blogA = new Blog()
    blogA.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/quotation/quotation-blog-01.webp'
    blogA.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/functions/quotation/quotation-blog-01.png'
    blogA.heading = 'Uppercuz Creative บริษัทดิจิทัลเอเจนซี ที่เพิ่มความเชื่อมั่นของธุรกิจด้วยระบบเอกสาร'
    blogA.shortDescription = 'หนึ่งในธุรกิจดิจิทัลเอเจนซี ที่ใช้ใบเสนอราคา FlowAccount สร้างความน่าเชื่อถือให้กับธุรกิจ'
    blogA.link = 'https://flowaccount.com/blog/stories-uppercuz-creative/'
    blogA.imageAlt = 'Uppercuz Creative ดิจิทัลเอเจนซี'
    blogA.imageTitle = 'Uppercuz Creative ดิจิทัลเอเจนซี'

    const blogB = new Blog()
    blogB.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/quotation/quotation-blog-02-v2.webp'
    blogB.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/functions/quotation/quotation-blog-02-v2.png'
    blogB.heading = 'ใบวางบิล ใบแจ้งหนี้คืออะไร'
    blogB.shortDescription =
      'เอกสารที่คนทำธุรกิจบริการและขายของต้องใช้กันบ่อยๆ ทำยังไงถึงจะออกเอกสารให้วางบิลทัน ก็ใช้ FlowAccount ที่สร้างเอกสารต่อจากใบเสนอราคาได้เลยในคลิกเดียว'
    blogB.link = 'https://flowaccount.com/blog/ใบวางบิล-ใบแจ้งหนี้/'
    blogB.imageAlt = 'ใบวางบิล ใบแจ้งหนี้คืออะไร'
    blogB.imageTitle = 'ใบวางบิล ใบแจ้งหนี้คืออะไร'

    const blogC = new Blog()
    blogC.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/quotation/quotation-blog-03-v2.webp'
    blogC.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/functions/quotation/quotation-blog-03-v2.png'
    blogC.heading = 'ใบกำกับภาษีคืออะไร'
    blogC.shortDescription =
      'เอกสารสำหรับธุรกิจที่จดทะเบียนภาษีมูลค่าเพิ่ม (VAT) ต้องออกให้กับผู้รับทุกครั้งที่ขายสินค้าหรือให้บริการ ใช้ FlowAccount ออกใบเสร็จได้ทั้งเต็มรูปและอย่างย่อ'
    blogC.link = 'https://flowaccount.com/blog/ใบกำกับภาษี/'
    blogC.imageAlt = 'ใบกำกับภาษีคืออะไร'
    blogC.imageTitle = 'ใบกำกับภาษีคืออะไร'

    return [blogA, blogB, blogC]
  }

  private billingNoteAndInvoiceFunctionPage() {
    const blogA = new Blog()
    blogA.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/quotation/quotation-blog-03-v2.webp'
    blogA.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/functions/quotation/quotation-blog-03-v2.png'
    blogA.heading = 'ใบกำกับภาษีคืออะไร'
    blogA.shortDescription =
      'เอกสารสำหรับธุรกิจที่จดทะเบียนภาษีมูลค่าเพิ่ม (VAT) ต้องออกให้กับผู้รับทุกครั้งที่ขายสินค้าหรือให้บริการ ใช้ FlowAccount ออกใบเสร็จได้ทั้งเต็มรูปและอย่างย่อ'
    blogA.link = 'https://flowaccount.com/blog/ใบกำกับภาษี/'
    blogA.imageAlt = 'วิธีทำใบแจ้งหนี้'
    blogA.imageTitle = 'วิธีทำใบแจ้งหนี้'

    const blogB = new Blog()
    blogB.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/receipt/blog2-section6-v1.webp'
    blogB.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/functions/receipt/blog2-section6-v1.jpg'
    blogB.heading = 'ใบกำกับภาษีอย่างย่อคืออะไร'
    blogB.shortDescription =
      'เอกสารสำหรับธุรกิจรายย่อยทั้ง ร้านค้าปลีก ร้านสะดวกซื้อ ร้านอาหาร ต้องใช้กันทุกวัน อ่านข้อแตกต่างระหว่างการออกใบเสร็จแบบเต็มรูปและอย่างย่อได้ที่นี่'
    blogB.link = 'https://flowaccount.com/blog/ใบกำกับภาษีอย่างย่อ/'
    blogB.imageAlt = 'ใบกำกับภาษีอย่างย่อคืออะไร'
    blogB.imageTitle = 'ใบกำกับภาษีอย่างย่อคืออะไร'

    const blogC = new Blog()
    blogC.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/billing_note/section5/section_5_3.webp'
    blogC.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/functions/billing_note/section5/section_5_3.jpg'
    blogC.heading = 'วิธีออกเอกสาร เงินมัดจำ เพื่อเก็บเงินลูกค้าล่วงหน้า'
    blogC.shortDescription =
      'เอกสารที่ช่วยให้ปิดการขายได้ง่ายขึ้น และยังช่วยรักษาสภาพคล่องของกิจการ ใช้เป็นหลักฐานได้ด้วยว่าเกิดการซื้อขายขึ้นแล้ว'
    blogC.link = 'https://flowaccount.com/blog/deposit/'
    blogC.imageAlt = 'วิธีทำเอกสารเงินมัดจำ'
    blogC.imageTitle = 'วิธีทำเอกสารเงินมัดจำ'

    return [blogA, blogB, blogC]
  }

  private TaxInvoiceFunctionPage() {
    const blogA = new Blog()
    blogA.coverUrlWebp =
      'https://flowaccountcdn.com/new_landing/image/functions/tax_invoice/section5/section_5_blog_1.webp'
    blogA.coverUrlJpg =
      'https://flowaccountcdn.com/new_landing/image/functions/tax_invoice/section5/section_5_blog_1.jpg'
    blogA.heading = 'Orange Road ที่เลือก FlowAccount เติมเต็มเส้นทางผู้ประกอบการ'
    blogA.shortDescription =
      'คุณป่าน - วสิตา กิจปรีชา ผู้ประกอบการ บริษัท Orange Road คอนเทนต์สตูดิโอ ที่เรียนรู้การเป็นผู้ประกอบการ โดยใช้ FlowAccount เป็นตัวช่วยในการคิดราคา และคำนวณ VAT แต่ละโปรเจกต์ง่ายๆ'
    blogA.link = 'https://flowaccount.com/blog/stories-orange-road/#more-10640'
    blogA.imageAlt = 'ทำบัญชีบริษัทเอเจนซี่'
    blogA.imageTitle = 'ทำบัญชีบริษัทเอเจนซี่'

    const blogB = new Blog()
    blogB.coverUrlWebp =
      'https://flowaccountcdn.com/new_landing/image/functions/tax_invoice/section5/section_5_blog_2.webp'
    blogB.coverUrlJpg =
      'https://flowaccountcdn.com/new_landing/image/functions/tax_invoice/section5/section_5_blog_2.jpg'
    blogB.heading = 'ใบเสร็จรับเงิน มีหน้าที่ทำอะไร แตกต่างกับบิลเงินสดอย่างไร'
    blogB.shortDescription =
      'คุณสามารถออกใบเสร็จรับเงินต่อจาก ใบส่งสินค้า/ใบแจ้งหนี้/ใบกำกับภาษี หรือ ใบวางบิล/ใบแจ้งหนี้ หรือใบวางบิลรวม ที่เคยออกไว้ในระบบบัญชี FlowAccount ได้เลยทันที '
    blogB.link = 'https://flowaccount.com/blog/category/business-documents/'
    blogB.imageAlt = 'ใบเสร็จรับเงิน มีหน้าที่ทำอะไร'
    blogB.imageTitle = 'ใบเสร็จรับเงิน มีหน้าที่ทำอะไร'

    const blogC = new Blog()
    blogC.coverUrlWebp =
      'https://flowaccountcdn.com/new_landing/image/functions/tax_invoice/section5/section_5_blog_3.webp'
    blogC.coverUrlJpg =
      'https://flowaccountcdn.com/new_landing/image/functions/tax_invoice/section5/section_5_blog_1.jpg'
    blogC.heading = 'เชื่อมต่อ โปรแกรมบัญชี กับ Lazada และ Shopee จะมีกี่ร้าน ก็ออกบิลง่ายๆ ในที่เดียว'
    blogC.shortDescription =
      'FlowAccount พัฒนาฟีเจอร์โปรแกรมบัญชี เชื่อมต่อแพลตฟอร์มร้านค้าออนไลน์ Lazada และ Shopee ทำให้ร้านค้าออนไลน์มีระบบบัญชี ที่สามารถออกใบกำกับภาษี '
    blogC.link = 'https://flowaccount.com/blog/connect-flowaccount-with-lazada-and-shopee/'
    blogC.imageAlt = 'เชื่อมต่อ โปรแกรมบัญชี กับ Lazada และ Shopee '
    blogC.imageTitle = 'เชื่อมต่อ โปรแกรมบัญชี กับ Lazada และ Shopee '

    return [blogA, blogB, blogC]
  }

  private receiptFunctionPage() {
    const blogA = new Blog()
    blogA.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/receipt/blog3-section6-v1.webp'
    blogA.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/functions/receipt/blog3-section6-v1.jpg'
    blogA.heading = 'ใบวางบิล ใบแจ้งหนี้คืออะไร'
    blogA.shortDescription =
      'เอกสารที่คนทำธุรกิจบริการและขายของต้องใช้กันบ่อยๆ ทำยังไงถึงจะออกเอกสารให้วางบิลทัน ก็ใช้ FlowAccount ที่สร้างเอกสารต่อจากใบเสนอราคาได้เลยในคลิกเดียว'
    blogA.link = 'https://flowaccount.com/blog/ใบวางบิล-ใบแจ้งหนี้/'
    blogA.imageAlt = 'ใบวางบิล ใบแจ้งหนี้คืออะไร'
    blogA.imageTitle = 'ใบวางบิล ใบแจ้งหนี้คืออะไร'

    const blogB = new Blog()
    blogB.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/receipt/blog1-section6-v1.jpg'
    blogB.coverUrlJpg = 'hhttps://flowaccountcdn.com/new_landing/image/functions/receipt/blog1-section6-v1.jpg'
    blogB.heading = 'ใบกำกับภาษีคืออะไร'
    blogB.shortDescription =
      'เอกสารสำหรับธุรกิจที่จดทะเบียนภาษีมูลค่าเพิ่ม (VAT) ต้องออกให้กับผู้รับทุกครั้งที่ขายสินค้าหรือให้บริการ ใช้ FlowAccount ออกใบเสร็จได้ทั้งเต็มรูปและอย่างย่อ'
    blogB.link = 'https://flowaccount.com/blog/ใบกำกับภาษี/'
    blogB.imageAlt = 'ใบกำกับภาษีคืออะไร'
    blogB.imageTitle = 'ใบกำกับภาษีคืออะไร'

    const blogC = new Blog()
    blogC.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/receipt/blog2-section6-v1.webp'
    blogC.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/functions/receipt/blog2-section6-v1.jpg'
    blogC.heading = 'ใบกำกับภาษีอย่างย่อคืออะไร'
    blogC.shortDescription =
      'เอกสารสำหรับธุรกิจรายย่อยทั้ง ร้านค้าปลีก ร้านสะดวกซื้อ ร้านอาหาร ต้องใช้กันทุกวัน อ่านข้อแตกต่างระหว่างการออกใบเสร็จแบบเต็มรูปและอย่างย่อได้ที่นี่'
    blogC.link = 'https://flowaccount.com/blog/ใบกำกับภาษีอย่างย่อ/'
    blogC.imageAlt = 'ใบกำกับภาษีอย่างย่อคืออะไร'
    blogC.imageTitle = 'ใบกำกับภาษีอย่างย่อคืออะไร'

    return [blogA, blogB, blogC]
  }

  private FunctionMobilePosPage() {
    const blogA = new Blog()
    blogA.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/blog_qr_code_v1.jpg'
    blogA.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/blog_qr_code_v1.webp'
    blogA.heading = 'ช่องทางการชำระเงิน ออนไลน์ เก็บเงินลูกค้าง่าย ผ่าน Mobile App'
    blogA.shortDescription = 'ตัวช่วยใหม่สำหรับพ่อค้าแม่ขายที่มีหน้าร้าน มีมือถือเครื่องเดียว ก็เก็บเงินลูกค้าง่าย'
    blogA.link = 'https://flowaccount.com/blog/mobile-pos/ '
    blogA.imageAlt = 'ช่องทางชำระเงิน ออนไลน์ เก็บเงินลูกค้าง่าย'
    blogA.imageTitle = 'ช่องทางชำระเงิน ออนไลน์ เก็บเงินลูกค้าง่าย'

    const blogB = new Blog()
    blogB.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/blog_product_update_v1.webp'
    blogB.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/blog_product_update_v1.jpg'
    blogB.heading = 'FlowAccount Product Update 2022'
    blogB.shortDescription = 'สำรวจฟังก์ชั่นใหม่ในปี 2022 ทั้งบนเว็บไซต์และในแอปพลิเคชั่นได้ที่บทความนี้ทุกเดือน'
    blogB.link = 'https://flowaccount.com/blog/flowaccount-product-update-2022/'
    blogB.imageAlt = 'FlowAccount Product Update 2022'
    blogB.imageTitle = 'FlowAccount Product Update 2022'

    const blogC = new Blog()
    blogC.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/blog_pos_machine_v1.webp'
    blogC.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/blog_pos_machine_v1.jpg'
    blogC.heading = 'เครื่อง POS ได้เปลี่ยนแปลงวิธีการชำระเงินในธุรกิจปัจจุบันไปอย่างไร'
    blogC.shortDescription = 'เครื่อง POS (Point of Sale) ช่วยให้การชำระเงินของผู้บริโภคมีความปลอดภัยมากขึ้น'
    blogC.link = 'https://flowaccount.com/blog/updated-partners-sunmi-pos-payment/'
    blogC.imageAlt = 'เครื่อง POS ได้เปลี่ยนแปลงวิธีการชำระเงินในธุรกิจปัจจุบันไปอย่างไร'
    blogC.imageTitle = 'เครื่อง POS ได้เปลี่ยนแปลงวิธีการชำระเงินในธุรกิจปัจจุบันไปอย่างไร'

    return [blogA, blogB, blogC]
  }

  private pricingPage() {
    const blogA = new Blog()
    blogA.coverUrlWebp = 'https://flowaccountcdn.com/blogs/blog_9reason.webp'
    blogA.coverUrlJpg = 'https://flowaccountcdn.com/blogs/blog_9reason.png'
    blogA.heading = '9 เหตุผลที่ โปรแกรมบัญชี FlowAccount ช่วยคุณทำธุรกิจได้ง่ายและไวกว่าใคร'
    blogA.shortDescription =
      'โปรแกรมบัญชี FlowAccount แตกต่างจาก FlowAccount Classic อย่างไร และทำไมเจ้าของธุรกิจควรใช้ หาคำตอบได้ในบทความนี้'
    blogA.link = 'https://flowaccount.com/blog/new-flowaccount/'
    blogA.imageAlt = 'โปรแกรมบัญชี FlowAccount'
    blogA.imageTitle = 'โปรแกรมบัญชี FlowAccount'

    const blogB = new Blog()
    blogB.coverUrlWebp = 'https://flowaccountcdn.com/blogs/pricing-blog-02.webp'
    blogB.coverUrlJpg = 'https://flowaccountcdn.com/blogs/pricing-blog-02.jpg'
    blogB.heading = 'โปรแกรมบัญชีสำเร็จรูปบนคลาวด์ ช่วยให้ธุรกิจประสบความสำเร็จได้ยังไง'
    blogB.shortDescription =
      'โปรแกรมบัญชี คลาวด์ จะเป็นเครื่องมือยุคใหม่ของเจ้าของธุรกิจที่ช่วยให้มีข้อมูลธุรกิจติดตัว พร้อมบริหารงานได้ทุกที่ Work from Home สะดวก'
    blogB.link =
      'https://flowaccount.com/blog/%E0%B9%82%E0%B8%9B%E0%B8%A3%E0%B9%81%E0%B8%81%E0%B8%A3%E0%B8%A1%E0%B8%9A%E0%B8%B1%E0%B8%8D%E0%B8%8A%E0%B8%B5%E0%B8%84%E0%B8%A5%E0%B8%B2%E0%B8%A7%E0%B8%94%E0%B9%8C/'
    blogB.imageAlt = 'โปรแกรมบัญชีคลาวด์'
    blogB.imageTitle = 'โปรแกรมบัญชีคลาวด์'

    const blogC = new Blog()
    blogC.coverUrlWebp = 'https://flowaccountcdn.com/blogs/pricing-blog-03.webp'
    blogC.coverUrlJpg = 'https://flowaccountcdn.com/blogs/pricing-blog-03.jpg'
    blogC.heading = 'เลือก โปรแกรมทำบัญชี แบบไหนมาใช้ ถึงตอบโจทย์นักบัญชีมากที่สุด'
    blogC.shortDescription =
      'โปรแกรมทำบัญชี ที่ช่วยให้นักบัญชีทำงานได้อย่างมีประสิทธิภาพ ต้องสามารถลดงานคีย์ข้อมูลซ้ำๆ และมีฟังก์ชั่นที่เจ้าของธุรกิจก็ใช้งานได้ด้วยตนเอง'
    blogC.link =
      'https://flowaccount.com/blog/%E0%B9%82%E0%B8%9B%E0%B8%A3%E0%B9%81%E0%B8%81%E0%B8%A3%E0%B8%A1%E0%B8%97%E0%B8%B3%E0%B8%9A%E0%B8%B1%E0%B8%8D%E0%B8%8A%E0%B8%B5/'
    blogC.imageAlt = 'โปรแกรมทำบัญชี'
    blogC.imageTitle = 'โปรแกรมทำบัญชี'

    return [blogA, blogB, blogC]
  }

  private businessOwnerPage() {
    const blogA = new Blog()
    blogA.coverUrlWebp = 'https://flowaccountcdn.com/blogs/business-owner-blog-01.webp'
    blogA.coverUrlJpg = 'https://flowaccountcdn.com/blogs/business-owner-blog-01.jpg'
    blogA.heading = 'เปิดสำนักงานบัญชี อย่างไร ให้โดดเด่นและประสบความสำเร็จ'
    blogA.shortDescription =
      'เปิดสำนักงานบัญชีให้รุ่ง ต้องสร้างแบรนด์ให้ชัด รู้ความต้องการของลูกค้า ให้บริการที่ดี และใช้เทคโนโลยีเพื่อเพิ่มประสิทธิภาพในการทำงาน'
    blogA.link =
      'https://flowaccount.com/blog/%e0%b9%80%e0%b8%9b%e0%b8%b4%e0%b8%94%e0%b8%aa%e0%b8%b3%e0%b8%99%e0%b8%b1%e0%b8%81%e0%b8%87%e0%b8%b2%e0%b8%99%e0%b8%9a%e0%b8%b1%e0%b8%8d%e0%b8%8a%e0%b8%b5/'
    blogA.imageAlt = 'เปิดสำนักงานบัญชี'
    blogA.imageTitle = 'เปิดสำนักงานบัญชี'

    const blogB = new Blog()
    blogB.coverUrlWebp = 'https://flowaccountcdn.com/blogs/business-owner-blog-02.webp'
    blogB.coverUrlJpg = 'https://flowaccountcdn.com/blogs/business-owner-blog-02.jpg'
    blogB.heading = 'การรับงานจากลูกค้าที่ เปลี่ยนสำนักงานบัญชี'
    blogB.shortDescription =
      'วิธีรับงานบัญชีของกิจการที่อยากย้ายสำนักงานบัญชีจะมีความแตกต่างจากลูกค้าใหม่อยู่พอสมควร เพราะต้องเข้าใจกระบวนการทางบัญชีที่เคยทำมากันเสียก่อน'
    blogB.link =
      'https://flowaccount.com/blog/%e0%b9%80%e0%b8%9b%e0%b8%a5%e0%b8%b5%e0%b9%88%e0%b8%a2%e0%b8%99%e0%b8%aa%e0%b8%b3%e0%b8%99%e0%b8%b1%e0%b8%81%e0%b8%87%e0%b8%b2%e0%b8%99%e0%b8%9a%e0%b8%b1%e0%b8%8d%e0%b8%8a%e0%b8%b5/'
    blogB.imageAlt = 'เปลี่ยนสำนักงานบัญชี'
    blogB.imageTitle = 'เปลี่ยนสำนักงานบัญชี'

    const blogC = new Blog()
    blogC.coverUrlWebp = 'https://flowaccountcdn.com/blogs/business-owner-blog-03.webp'
    blogC.coverUrlJpg = 'https://flowaccountcdn.com/blogs/business-owner-blog-03.jpg'
    blogC.heading = 'รวม มาตรการผ่อนปรนทางบัญชี ช่วง Covid-19 ที่สำนักงานบัญชีควรแนะนำลูกค้า'
    blogC.shortDescription =
      'มาตรการผ่อนปรนทางบัญชี ช่วง Covid-19 ที่สำนักงานบัญชีสามารถแนะนำให้กับลูกค้าที่ดูแลอยู่ เพื่อให้ได้รับสิทธิประโยชน์และลดอุปสรรคในการดำเนินธุรกิจ'
    blogC.link = 'https://flowaccount.com/blog/accounting-measures-covid-19/'
    blogC.imageAlt = 'มาตรการผ่อนปรนทางบัญชี'
    blogC.imageTitle = 'มาตรการผ่อนปรนทางบัญชี'

    return [blogA, blogB, blogC]
  }

  private accountantPage() {
    const blogA = new Blog()
    blogA.coverUrlWebp = 'https://flowaccountcdn.com/blogs/accountingfirm_accountant_1.webp'
    blogA.coverUrlJpg = 'https://flowaccountcdn.com/blogs/accountingfirm_accountant_1.jpg'
    blogA.heading = 'นักบัญชี ต้องมีทักษะอะไรบ้างที่จะทำให้คุณเป็นคู่คิดเจ้าของธุรกิจ'
    blogA.shortDescription =
      'อยากเป็นนักบัญชียุคใหม่ คุณต้องทำได้มากกว่างานคีย์เอกสาร แต่ต้องพัฒนาตัวเองให้เป็นคู่คิดให้เจ้าของธุรกิจได้ จะต้องรู้อะไรบ้าง อ่านได้ในบทความนี้'
    blogA.link = 'https://flowaccount.com/blog/นักบัญชี/'
    blogA.imageAlt = 'วางระบบบัญชี'
    blogA.imageTitle = 'วางระบบบัญชี'

    const blogB = new Blog()
    blogB.coverUrlWebp = 'https://flowaccountcdn.com/blogs/accountingfirm_accountant_2.webp'
    blogB.coverUrlJpg = 'https://flowaccountcdn.com/blogs/accountingfirm_accountant_2.jpg'
    blogB.heading = 'รวมเทคนิค FlowAccount ช่วย นักบัญชี ปิดงบไว WFH สะดวก'
    blogB.shortDescription =
      'แนะนำวิธีใช้ โปรแกรมบัญชี ออนไลน์ FlowAccount ที่จะช่วยให้ทำงานร่วมกับผู้ตรวจสอบบัญชี และเจ้าของธุรกิจ ได้ทุกที่ และย่นระยะเวลาการปิดงบให้ไวขึ้น'
    blogB.link = 'https://flowaccount.com/blog/นักบัญชี-ปิดงบไว/'
    blogB.imageAlt = 'งบการเงิน'
    blogB.imageTitle = 'งบการเงิน'

    const blogC = new Blog()
    blogC.coverUrlWebp = 'https://flowaccountcdn.com/blogs/accountingfirm_accountant_3.webp'
    blogC.coverUrlJpg = 'https://flowaccountcdn.com/blogs/accountingfirm_accountant_3.jpg'
    blogC.heading = 'New Normal ของนักบัญชี หลังช่วง COVID-19'
    blogC.shortDescription =
      'การทำงานของนักบัญชีที่จะเปลี่ยนไปหลังทั่วโลกเผชิญกับวิกฤติ COVID-19 นั่นคือ ทุกคนจะต้องปรับตัวเข้าสู่โลกการทำงานแบบออนไลน์อย่างหลีกเลี่ยงไม่ได้'
    blogC.link = 'https://flowaccount.com/blog/new-normal-ของนักบัญชี-หลัง-covid-19/'
    blogC.imageAlt = 'New Normal ของนักบัญชี'
    blogC.imageTitle = 'New Normal ของนักบัญชี'

    return [blogA, blogB, blogC]
  }

  private seminarPage() {
    const blogA = new Blog()
    blogA.coverUrlWebp = 'https://flowaccountcdn.com/blogs/seminar_1.webp'
    blogA.coverUrlJpg = 'https://flowaccountcdn.com/blogs/seminar_1.jpg'
    blogA.heading = 'ไม่มีบิล ตอนซื้อของ จะทำบัญชียังไงให้เป็นรายจ่ายกิจการ'
    blogA.shortDescription =
      'ไปซื้อของเข้าออฟฟิศ แต่ไม่มีบิล ลองใช้เอกสารแทนบิลใบเสร็จของกรมสรรพากร และ FlowAccount ช่วยออกเอกสารบันทึกค่าใช้จ่าย ดูวิธีทำได้ในบทความนี้'
    blogA.link =
      'https://flowaccount.com/blog/%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B8%A1%E0%B8%B5%E0%B8%9A%E0%B8%B4%E0%B8%A5/'
    blogA.imageAlt = 'ไม่มีบิล'
    blogA.imageTitle = 'ไม่มีบิล'

    const blogB = new Blog()
    blogB.coverUrlWebp = 'https://flowaccountcdn.com/blogs/seminar_2.webp'
    blogB.coverUrlJpg = 'https://flowaccountcdn.com/blogs/seminar_2.jpg'
    blogB.heading = 'การจัดการสินค้าคงเหลือ เทคนิค บริหารสต็อกสินค้าอย่างง่าย'
    blogB.shortDescription =
      'เทคนิคจัดการสินค้าในคลังให้ตรวจสอบง่ายคือ ใช้ระบบสต็อกสินค้าแบบเข้าก่อน-ออกก่อน หรือ FIFO System พร้อมเช็กสินค้าคงเหลือได้ง่ายๆ ทางมือถือ'
    blogB.link =
      'https://flowaccount.com/blog/%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%88%E0%B8%B1%E0%B8%94%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%AA%E0%B8%B4%E0%B8%99%E0%B8%84%E0%B9%89%E0%B8%B2%E0%B8%84%E0%B8%87%E0%B9%80%E0%B8%AB%E0%B8%A5%E0%B8%B7%E0%B8%AD/'
    blogB.imageAlt = 'การจัดการสินค้าคงเหลือ'
    blogB.imageTitle = 'การจัดการสินค้าคงเหลือ'

    const blogC = new Blog()
    blogC.coverUrlWebp = 'https://flowaccountcdn.com/blogs/seminar_3.webp'
    blogC.coverUrlJpg = 'https://flowaccountcdn.com/blogs/seminar_3.jpg'
    blogC.heading = '5 เรื่องที่เจ้าของธุรกิจควรรู้ ก่อน จ่ายเงินเดือน ให้พนักงาน'
    blogC.shortDescription =
      'ศึกษาวิธีการจ่ายเงินเดือนให้พนักงานแบบง่ายๆ ทั้งเรื่องกฎหมาย บัญชี และภาษีที่เกี่ยวข้อง เพื่อให้คุณพร้อมจ่ายเงินเดือนให้พนักงานได้อย่างถูกต้อง'
    blogC.link = 'https://flowaccount.com/blog/payroll-guide/'
    blogC.imageAlt = 'จ่ายเงินเดือน'
    blogC.imageTitle = 'จ่ายเงินเดือน'

    return [blogA, blogB, blogC]
  }
  private totorialsPage() {
    const blogA = new Blog()
    blogA.coverUrlWebp = 'https://flowaccountcdn.com/blogs/tutorials_1.webp'
    blogA.coverUrlJpg = 'https://flowaccountcdn.com/blogs/tutorials_1.jpg'
    blogA.heading = 'บริหารเงินธุรกิจ ด้วยแดชบอร์ด FlowAccount ใหม่! ที่ช่วยให้รู้การเงินได้มากขึ้นกว่าเดิม'
    blogA.shortDescription =
      'แดชบอร์ด FlowAccount กราฟสีสันสดใส ที่ช่วยเช็กยอดขาย ค่าใช้จ่าย ได้ทันที พร้อมบริหารยอดค้างรับ ยอดค้างจ่าย สำหรับงานที่มีการวางเครดิต ดูวิธีใช้ได้ที่นี่'
    blogA.link =
      'https://flowaccount.com/blog/%e0%b8%9a%e0%b8%a3%e0%b8%b4%e0%b8%ab%e0%b8%b2%e0%b8%a3%e0%b9%80%e0%b8%87%e0%b8%b4%e0%b8%99%e0%b8%98%e0%b8%b8%e0%b8%a3%e0%b8%81%e0%b8%b4%e0%b8%88/'
    blogA.imageAlt = 'บริหารเงินธุรกิจ'
    blogA.imageTitle = 'บริหารเงินธุรกิจ'

    const blogB = new Blog()
    blogB.coverUrlWebp = 'https://flowaccountcdn.com/blogs/tutorials_2.webp'
    blogB.coverUrlJpg = 'https://flowaccountcdn.com/blogs/tutorials_2.jpg'
    blogB.heading = 'การทำ บัญชีรายรับ รายจ่าย เบื้องต้น'
    blogB.shortDescription =
      'งานบัญชีเบื้องต้นที่สามารถใช้ FlowAccount ในการจดบันทึกรายรับ และค่าใช้จ่ายของธุรกิจได้อย่างง่ายๆ แถมยังสรุปยอดในแต่ละวันให้คุณได้ทันที'
    blogB.link =
      'https://flowaccount.com/blog/%E0%B8%9A%E0%B8%B1%E0%B8%8D%E0%B8%8A%E0%B8%B5%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%A3%E0%B8%B1%E0%B8%9A-%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%88%E0%B9%88%E0%B8%B2%E0%B8%A2/'
    blogB.imageAlt = 'บัญชีรายรับรายจ่าย'
    blogB.imageTitle = 'บัญชีรายรับรายจ่าย'

    const blogC = new Blog()
    blogC.coverUrlWebp = 'https://flowaccountcdn.com/blogs/tutorials_3.webp'
    blogC.coverUrlJpg = 'https://flowaccountcdn.com/blogs/tutorials_3.jpg'
    blogC.heading = 'รวมเทคนิค FlowAccount ช่วย นักบัญชี ปิดงบไว WFH สะดวก'
    blogC.shortDescription =
      'แนะนำวิธีใช้ โปรแกรมบัญชี ออนไลน์ FlowAccount ที่จะช่วยให้ทำงานร่วมกับผู้ตรวจสอบบัญชี และเจ้าของธุรกิจ ได้ทุกที่ และย่นระยะเวลาการปิดงบให้ไวขึ้น'
    blogC.link =
      'https://flowaccount.com/blog/%e0%b8%99%e0%b8%b1%e0%b8%81%e0%b8%9a%e0%b8%b1%e0%b8%8d%e0%b8%8a%e0%b8%b5-%e0%b8%9b%e0%b8%b4%e0%b8%94%e0%b8%87%e0%b8%9a%e0%b9%84%e0%b8%a7/'
    blogC.imageAlt = 'นักบัญชี ปิดงบไว'
    blogC.imageTitle = 'นักบัญชี ปิดงบไว'

    return [blogA, blogB, blogC]
  }
  private migratPage() {
    const blogA = new Blog()
    blogA.coverUrlWebp = 'https://flowaccountcdn.com/blogs/blog_9reason.webp'
    blogA.coverUrlJpg = 'https://flowaccountcdn.com/blogs/blog_9reason.png'
    blogA.heading = '9 เหตุผลที่ โปรแกรมบัญชี FlowAccount ช่วยคุณทำธุรกิจได้ง่ายและไวกว่าใคร'
    blogA.shortDescription =
      'โปรแกรมบัญชี FlowAccount แตกต่างจาก FlowAccount Classic อย่างไร และทำไมเจ้าของธุรกิจควรใช้ หาคำตอบได้ในบทความนี้'
    blogA.link = 'https://flowaccount.com/blog/new-flowaccount/'
    blogA.imageAlt = 'โปรแกรมบัญชี FlowAccount'
    blogA.imageTitle = 'โปรแกรมบัญชี FlowAccount'

    const blogB = new Blog()
    blogB.coverUrlWebp = 'https://flowaccountcdn.com/blogs/migrat_2_202108.webp'
    blogB.coverUrlJpg = 'https://flowaccountcdn.com/blogs/migrat_2_202108.jpg'
    blogB.heading = '8 คำถาม การโอนย้ายข้อมูล จาก FlowAccount Classic มาใช้ FlowAccount'
    blogB.shortDescription =
      'ย้ายมาใช้ FlowAccount โปรแกรมบัญชี เวอร์ชั่นใหม่ ได้ง่ายๆ อ่านรายละเอียดและ\nขั้นตอนติดต่อทีมงานได้ที่บล็อกนี้เลย'
    blogB.link =
      'https://flowaccount.com/blog/%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b9%82%e0%b8%ad%e0%b8%99%e0%b8%a2%e0%b9%89%e0%b8%b2%e0%b8%a2%e0%b8%82%e0%b9%89%e0%b8%ad%e0%b8%a1%e0%b8%b9%e0%b8%a5/'
    blogB.imageAlt = 'อัพเกรด FlowAccount'
    blogB.imageTitle = 'อัพเกรด FlowAccount'

    const blogC = new Blog()
    blogC.coverUrlWebp = 'https://flowaccountcdn.com/blogs/migrat_3.webp'
    blogC.coverUrlJpg = 'https://flowaccountcdn.com/blogs/migrat_3.jpg'
    blogC.heading = 'บริหารเงินธุรกิจ ด้วยแดชบอร์ด FlowAccount ที่ช่วยให้รู้การเงินได้มากขึ้นกว่าเดิม'
    blogC.shortDescription =
      'แดชบอร์ด FlowAccount ที่จะเป็นผู้ช่วยสรุปรายรับ รายจ่าย ที่เช็กได้ตลอดเวลา พร้อมบริหารเงินธุรกิจ ยอดค้างรับ ค้างจ่ายได้ทั้งทางมือถือและคอมพิวเตอร์'
    blogC.link =
      'https://flowaccount.com/blog/%e0%b8%9a%e0%b8%a3%e0%b8%b4%e0%b8%ab%e0%b8%b2%e0%b8%a3%e0%b9%80%e0%b8%87%e0%b8%b4%e0%b8%99%e0%b8%98%e0%b8%b8%e0%b8%a3%e0%b8%81%e0%b8%b4%e0%b8%88/'
    blogC.imageAlt = 'บริหารเงินธุรกิจ'
    blogC.imageTitle = 'บริหารเงินธุรกิจ'

    return [blogA, blogB, blogC]
  }
  private ultimatePage() {
    const blogA = new Blog()
    blogA.coverUrlWebp = 'https://flowaccountcdn.com/blogs/ultimate_1.jpg'
    blogA.coverUrlJpg = 'https://flowaccountcdn.com/blogs/ultimate_1.jpg'
    blogA.heading = 'การทำบัญชีรายรับ-รายจ่าย เบื้องต้นสำหรับเจ้าของธุรกิจ'
    blogA.shortDescription =
      'บัญชีรายรับ รายจ่าย เป็นงานที่เจ้าของธุรกิจ จำเป็นต้องจัดทำอย่างสม่ำเสมอ เพื่อให้บริหารธุรกิจได้มีประสิทธิภาพมากขึ้น'
    blogA.link =
      'https://flowaccount.com/blog/%E0%B8%9A%E0%B8%B1%E0%B8%8D%E0%B8%8A%E0%B8%B5%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%A3%E0%B8%B1%E0%B8%9A-%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%88%E0%B9%88%E0%B8%B2%E0%B8%A2/'
    blogA.imageAlt = 'การทำบัญชีรายรับ-รายจ่าย เบื้องต้นสำหรับเจ้าของธุรกิจ'
    blogA.imageTitle = 'การทำบัญชีรายรับ-รายจ่าย เบื้องต้นสำหรับเจ้าของธุรกิจ'

    const blogB = new Blog()
    blogB.coverUrlWebp = 'https://flowaccountcdn.com/blogs/ultimate_2.jpg'
    blogB.coverUrlJpg = 'https://flowaccountcdn.com/blogs/ultimate_2.jpg'
    blogB.heading = 'ขายของออนไลน์เสียภาษียังไงบ้าง'
    blogB.shortDescription = 'ขายของออนไลน์ต้องเสียภาษีแบบไหน คำนวณภาษียังไง เตรียมตัวอะไรบ้าง FlowAccount มีคำตอบ '
    blogB.link =
      'https://flowaccount.com/blog/%E0%B8%98%E0%B8%B8%E0%B8%A3%E0%B8%81%E0%B8%B4%E0%B8%88%E0%B8%8B%E0%B8%B7%E0%B9%89%E0%B8%AD%E0%B8%A1%E0%B8%B2%E0%B8%82%E0%B8%B2%E0%B8%A2%E0%B9%84%E0%B8%9B/'
    blogB.imageAlt = 'ขายของออนไลน์เสียภาษียังไงบ้าง'
    blogB.imageTitle = 'ขายของออนไลน์เสียภาษียังไงบ้าง'

    const blogC = new Blog()
    blogC.coverUrlWebp = 'https://flowaccountcdn.com/blogs/ultimate_3.jpg'
    blogC.coverUrlJpg = 'https://flowaccountcdn.com/blogs/ultimate_3.jpg'
    blogC.heading = 'เจ้าของธุรกิจทำประกันสังคมให้พนักงานยังไง'
    blogC.shortDescription =
      'ประกันสังคม ประโยชน์ที่เจ้าของธุรกิจควรรู้ วิธีการคำนวณเงินสมทบกองทุนประกันสังคม และขั้นตอนการยื่นสิทธิอย่างง่าย'
    blogC.link =
      'https://flowaccount.com/blog/%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%b1%e0%b8%99%e0%b8%aa%e0%b8%b1%e0%b8%87%e0%b8%84%e0%b8%a1-%e0%b8%a1%e0%b8%b8%e0%b8%a1%e0%b8%99%e0%b8%b2%e0%b8%a2%e0%b8%88%e0%b9%89%e0%b8%b2%e0%b8%87/'
    blogC.imageAlt = 'บริหารเงินธุรเจ้าของธุรกิจทำประกันสังคมให้พนักงานยังไงกิจ'
    blogC.imageTitle = 'เจ้าของธุรกิจทำประกันสังคมให้พนักงานยังไง'

    const blogD = new Blog()
    blogD.coverUrlWebp = 'https://flowaccountcdn.com/blogs/ultimate_4.jpg'
    blogD.coverUrlJpg = 'https://flowaccountcdn.com/blogs/ultimate_4.jpg'
    blogD.heading = 'เลือก โปรแกรมทำบัญชี แบบไหนมาใช้ ถึงตอบโจทย์นักบัญชีมากที่สุด'
    blogD.shortDescription =
      'โปรแกรมบัญชีที่ดีทำให้ชีวิตมีชัยไปกว่าครึ่ง เราจะเลือกอย่างไร เพื่อให้เริ่มต้นทำงานบัญชีได้ง่ายดายและสะดวกสบายมากที่สุด '
    blogD.link =
      'https://flowaccount.com/blog/%e0%b9%82%e0%b8%9b%e0%b8%a3%e0%b9%81%e0%b8%81%e0%b8%a3%e0%b8%a1%e0%b8%97%e0%b8%b3%e0%b8%9a%e0%b8%b1%e0%b8%8d%e0%b8%8a%e0%b8%b5/'
    blogD.imageAlt = 'เลือก โปรแกรมทำบัญชี แบบไหนมาใช้ ถึงตอบโจทย์นักบัญชีมากที่สุด'
    blogD.imageTitle = 'เลือก โปรแกรมทำบัญชี แบบไหนมาใช้ ถึงตอบโจทย์นักบัญชีมากที่สุด'

    const blogE = new Blog()
    blogE.coverUrlWebp = 'https://flowaccountcdn.com/blogs/ultimate_5.jpg'
    blogE.coverUrlJpg = 'https://flowaccountcdn.com/blogs/ultimate_5.jpg'
    blogE.heading = 'รวมเทคนิค FlowAccount ช่วย นักบัญชี ปิดงบไว WFH สะดวก'
    blogE.shortDescription =
      'ต้อนรับวิถี New Normal กับฟังก์ชั่นโปรแกรมบัญชีออนไลน์ที่ซัพพอร์ตการทำงานของคุณ โดยเฉพาะการปิดงบการเงิน'
    blogE.link =
      'https://flowaccount.com/blog/%e0%b8%99%e0%b8%b1%e0%b8%81%e0%b8%9a%e0%b8%b1%e0%b8%8d%e0%b8%8a%e0%b8%b5-%e0%b8%9b%e0%b8%b4%e0%b8%94%e0%b8%87%e0%b8%9a%e0%b9%84%e0%b8%a7/'
    blogE.imageAlt = 'รวมเทคนิค FlowAccount ช่วย นักบัญชี ปิดงบไว WFH สะดวก'
    blogE.imageTitle = 'รวมเทคนิค FlowAccount ช่วย นักบัญชี ปิดงบไว WFH สะดวก'

    const blogF = new Blog()
    blogF.coverUrlWebp = 'https://flowaccountcdn.com/blogs/ultimate_6.jpg'
    blogF.coverUrlJpg = 'https://flowaccountcdn.com/blogs/ultimate_6.jpg'
    blogF.heading = '8 เช็กลิสต์ขั้นตอน การปิดบัญชี ที่นักบัญชีทุกคนควรทำ'
    blogF.shortDescription =
      'เช็กลิสต์ขั้นตอน การปิดบัญชี อย่างมีระบบ ราบรื่น และช่วยประหยัดเวลาในการทำงานของนักบัญชีมากยิ่งขึ้น'
    blogF.link = 'https://flowaccount.com/blog/การปิดบัญชี/'
    blogF.imageAlt = '8 เช็กลิสต์ขั้นตอน การปิดบัญชี ที่นักบัญชีทุกคนควรทำ'
    blogF.imageTitle = '8 เช็กลิสต์ขั้นตอน การปิดบัญชี ที่นักบัญชีทุกคนควรทำ'

    return [blogA, blogB, blogC, blogD, blogE, blogF]
  }

  private temp() {
    const blogA = new Blog()
    blogA.coverUrlWebp = ''
    blogA.coverUrlJpg = ''
    blogA.heading = ''
    blogA.shortDescription = ''
    blogA.link = ''
    blogA.imageAlt = ''
    blogA.imageTitle = ''

    const blogB = new Blog()
    blogB.coverUrlWebp = ''
    blogB.coverUrlJpg = ''
    blogB.heading = ''
    blogB.shortDescription = ''
    blogB.link = ''
    blogB.imageAlt = ''
    blogB.imageTitle = ''

    const blogC = new Blog()
    blogC.coverUrlWebp = ''
    blogC.coverUrlJpg = ''
    blogC.heading = ''
    blogC.shortDescription = ''
    blogC.link = ''
    blogC.imageAlt = ''
    blogC.imageTitle = ''

    const blogD = new Blog()
    blogD.coverUrlWebp = ''
    blogD.coverUrlJpg = ''
    blogD.heading = ''
    blogD.shortDescription = ''
    blogD.link = ''
    blogD.imageAlt = ''
    blogD.imageTitle = ''

    const blogE = new Blog()
    blogE.coverUrlWebp = ''
    blogE.coverUrlJpg = ''
    blogE.heading = ''
    blogE.shortDescription = ''
    blogE.link = ''
    blogE.imageAlt = ''
    blogE.imageTitle = ''

    const blogF = new Blog()
    blogF.coverUrlWebp = ''
    blogF.coverUrlJpg = ''
    blogF.heading = ''
    blogF.shortDescription = ''
    blogF.link = ''
    blogF.imageAlt = ''
    blogF.imageTitle = ''

    return [blogA, blogB, blogC, blogD, blogE, blogF]
  }

  private homePageContent() {
    const blogA = new Blog()
    blogA.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/home/blog/blog_8_functions_v3.webp'
    blogA.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/home/blog/blog_8_functions_v3.jpg'
    blogA.heading = '8 ฟังก์ชั่น โปรแกรมบัญชี ฟรี ที่คุณเอาไว้ใช้ทำธุรกิจได้'
    blogA.link = 'https://flowaccount.com/blog/free-accounting-software/'
    blogA.imageAlt = '8 ฟังก์ชั่น โปรแกรมบัญชี'
    blogA.imageTitle = '8 ฟังก์ชั่น โปรแกรมบัญชี'

    const blogB = new Blog()
    blogB.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/homepage/flowaccount-product-update-2023.webp'
    blogB.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/homepage/flowaccount-product-update-2023.jpg'
    blogB.heading = 'Product Update FlowAccount 2023'
    blogB.link = 'https://flowaccount.com/blog/flowaccount-product-update-2023/'
    blogB.imageAlt = 'Product Update FlowAccount 2023'
    blogB.imageTitle = 'Product Update FlowAccount 2023'

    const blogC = new Blog()
    blogC.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/home/blog/blog_connect_laz_shopee_v3.webp'
    blogC.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/home/blog/blog_connect_laz_shopee_v3.jpg'
    blogC.heading = 'เชื่อมต่อ โปรแกรมบัญชี กับ Lazada และ Shopee'
    blogC.link = 'https://flowaccount.com/blog/connect-flowaccount-with-lazada-and-shopee/'
    blogC.imageAlt = 'เชื่อมต่อ โปรแกรมบัญชี กับ Lazada และ Shopee'
    blogC.imageTitle = 'เชื่อมต่อ โปรแกรมบัญชี กับ Lazada และ Shopee'

    const blogD = new Blog()
    blogD.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/home/blog/blog_what_is_wht_tax_v3.webp'
    blogD.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/home/blog/blog_what_is_wht_tax_v3.jpg'
    blogD.heading = 'ภาษีเงินได้ หัก ณ ที่จ่าย คืออะไร ทำไมเราต้องถูกหัก'
    blogD.link = 'https://flowaccount.com/blog/ภาษีเงินได้-หัก-ณ-ที่จ่า/'
    blogD.imageAlt = 'ภาษีเงินได้ หัก ณ ที่จ่าย คืออะไร'
    blogD.imageTitle = 'ภาษีเงินได้ หัก ณ ที่จ่าย คืออะไร'

    const blogE = new Blog()
    blogE.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/home/blog/blog_accountant_parner_needed_v3.webp'
    blogE.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/home/blog/blog_accountant_parner_needed_v3.jpg'
    blogE.heading = 'จ้างสำนักงานบัญชีดีไหม ถ้าใช้โปรแกรมบัญชีอยู่แล้ว'
    blogE.link = 'https://flowaccount.com/blog/จ้างสำนักงานบัญชี/'
    blogE.imageAlt = 'จ้างสำนักงานบัญชี ยังจำเป็นอยู่ไหม'
    blogE.imageTitle = 'จ้างสำนักงานบัญชี ยังจำเป็นอยู่ไหม'

    const blogF = new Blog()
    blogF.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/home/blog/blog_file_wht_tax_v3.webp'
    blogF.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/home/blog/blog_file_wht_tax_v3.jpg'
    blogF.heading = 'ไฟล์ หัก ณ ที่จ่าย สำหรับใช้ในโปรแกรม RD Prep'
    blogF.link = 'https://flowaccount.com/blog/online-tax-filling-pnd3-53/'
    blogF.imageAlt = 'ไฟล์ หัก ณ ที่จ่าย'
    blogF.imageTitle = 'ไฟล์ หัก ณ ที่จ่าย'

    return [blogA, blogB, blogC, blogD, blogE, blogF]
  }

  private businessRegistrationPageContent() {
    const blogA = new Blog()
    blogA.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/business/business_blog_1_v1.jpg'
    blogA.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/functions/business/business_blog_1_v1.webp'
    blogA.heading = 'จดทะเบียนบริษัทตอนไหนดี'
    blogA.link = 'https://flowaccount.com/blog/7-crucial-considerations-before-business-registration/'
    blogA.imageAlt = 'จดทะเบียนบริษัทตอนไหนดี'
    blogA.imageTitle = 'จดทะเบียนบริษัทตอนไหนดี'

    const blogB = new Blog()
    blogB.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/business/business_blog_2_v1.jpg'
    blogB.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/functions/business/business_blog_2_v1.jpg'
    blogB.heading = 'ขายของออนไลน์ ต้องจดทะเบียนอะไรบ้าง'
    blogB.link = 'https://flowaccount.com/blog/e-commerce-registration/'
    blogB.imageAlt = ' ขายของออนไลน์ ต้องจดทะเบียนอะไรบ้าง'
    blogB.imageTitle = ' ขายของออนไลน์ ต้องจดทะเบียนอะไรบ้าง'

    const blogC = new Blog()
    blogC.coverUrlWebp = 'https://flowaccount.com/blog/wp-content/uploads/2022/12/Blog-Cover-1200x630-px-3.jpg'
    blogC.coverUrlJpg = 'https://flowaccount.com/blog/wp-content/uploads/2022/12/Blog-Cover-1200x630-px-3.jpg'
    blogC.heading = 'ประเภทของการจดทะเบียนบริษัท'
    blogC.link = 'https://flowaccount.com/blog/e-registration-commercial-registration-vat-registration/'
    blogC.imageAlt = 'ประเภทของการจดทะเบียนบริษัท'
    blogC.imageTitle = 'ประเภทของการจดทะเบียนบริษัท'

    const blogD = new Blog()
    blogD.coverUrlWebp = 'https://flowaccount.com/blog/wp-content/uploads/2023/02/01_Blog-Cover-1200x630-px-5.jpg'
    blogD.coverUrlJpg = 'https://flowaccount.com/blog/wp-content/uploads/2023/02/01_Blog-Cover-1200x630-px-5.jpg'
    blogD.heading = 'ประเภทของการจดทะเบียนบริษัท'
    blogD.link = 'https://flowaccount.com/blog/share-certificate-shareholder-register'
    blogD.imageAlt = 'ประเภทของการจดทะเบียนบริษัท'
    blogD.imageTitle = 'ประเภทของการจดทะเบียนบริษัท'

    const blogE = new Blog()
    blogE.coverUrlWebp = 'https://flowaccount.com/blog/wp-content/uploads/2023/01/Blog-Cover-1200x630-px-3.jpg'
    blogE.coverUrlJpg = 'https://flowaccount.com/blog/wp-content/uploads/2023/01/Blog-Cover-1200x630-px-3.jpg'
    blogE.heading = 'ชีวิตเปลี่ยนไปอย่างไร เมื่อจดทะเบียนบริษัท'
    blogE.link = 'https://flowaccount.com/blog/life-change-after-company-registration-2/'
    blogE.imageAlt = 'ชีวิตเปลี่ยนไปอย่างไร เมื่อจดทะเบียนบริษัท'
    blogE.imageTitle = 'ชีวิตเปลี่ยนไปอย่างไร เมื่อจดทะเบียนบริษัท'

    const blogF = new Blog()
    blogF.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/business/business_blog_2_v1.jpg'
    blogF.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/functions/business/business_blog_2_v1.jpg'
    blogF.heading = 'ขายของออนไลน์ ต้องจดทะเบียนอะไรบ้าง'
    blogF.link = 'https://flowaccount.com/blog/e-commerce-registration/'
    blogF.imageAlt = ' ขายของออนไลน์ ต้องจดทะเบียนอะไรบ้าง'
    blogF.imageTitle = ' ขายของออนไลน์ ต้องจดทะเบียนอะไรบ้าง'

    return [blogA, blogB, blogC, blogD, blogE, blogF]
  }

  private VatWhtCalculationPageContent() {
    const blogA = new Blog()
    blogA.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/vat-wht-calculation/cal_blog_1.webp'
    blogA.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/functions/vat-wht-calculation/cal_blog_1.jpg'
    blogA.heading = 'ภาษีเงินได้ หัก ณ ที่จ่าย คืออะไร ทำไมเราต้องถูกหัก'
    blogA.link = 'https://flowaccount.com/blog/ภาษีเงินได้-หัก-ณ-ที่จ่า/'
    blogA.imageAlt = 'ภาษีเงินได้ หัก ณ ที่จ่าย คืออะไร'
    blogA.imageTitle = 'ภาษีเงินได้ หัก ณ ที่จ่าย คืออะไร'

    const blogB = new Blog()
    blogB.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/vat-wht-calculation/cal_blog_2.webp'
    blogB.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/functions/vat-wht-calculation/cal_blog_2.jpg'
    blogB.heading = 'หัก ณ ที่จ่าย พนักงานอย่างไร ก่อนจ่ายเงินเดือน'
    blogB.link = 'https://flowaccount.com/blog/หัก-ณ-ที่จ่าย-พนักงาน/'
    blogB.imageAlt = ' หัก ณ ที่จ่าย พนักงาน อย่างไร ก่อนจ่ายเงินเดือน'
    blogB.imageTitle = ' หัก ณ ที่จ่าย พนักงาน อย่างไร ก่อนจ่ายเงินเดือน'

    const blogC = new Blog()
    blogC.coverUrlWebp = 'https://flowaccountcdn.com/new_landing/image/functions/vat-wht-calculation/cal_blog_3.webp'
    blogC.coverUrlJpg = 'https://flowaccountcdn.com/new_landing/image/functions/vat-wht-calculation/cal_blog_3.jpg'
    blogC.heading = 'ภ.ง.ด.3/ภ.ง.ด.53 คืออะไร ยื่นภาษีหัก ณ ที่จ่ายอย่างไร'
    blogC.link = 'https://flowaccount.com/blog/ภ-ง-ด-3-หัก-ณ-ที่จ่าย/'
    blogC.imageAlt = 'ภ.ง.ด.3 / ภ.ง.ด.53 คืออะไร ยื่นภาษี หัก ณ ที่จ่าย อย่างไร'
    blogC.imageTitle = 'ภ.ง.ด.3 / ภ.ง.ด.53 คืออะไร ยื่นภาษี หัก ณ ที่จ่าย อย่างไร'
    return [blogA, blogB, blogC]
  }

  private reviewPageContent() {
    const blogA = new Blog()
    blogA.coverUrlWebp =
      'https://flowaccount.com/blog/wp-content/uploads/2021/11/Coffee-Press-วางแผนการขายจากบัญชีธุรกิจเครื่องกาแฟอย่างไร.jpg'
    blogA.coverUrlJpg =
      'https://flowaccount.com/blog/wp-content/uploads/2021/11/Coffee-Press-วางแผนการขายจากบัญชีธุรกิจเครื่องกาแฟอย่างไร.jpg'
    blogA.heading = 'Coffee Press Thailand'
    blogA.link = 'https://flowaccount.com/blog/stories-coffee-press/'
    blogA.imageAlt = 'Coffee Press Thailand'
    blogA.imageTitle = 'Coffee Press Thailand'

    const blogB = new Blog()
    blogB.coverUrlWebp = 'https://flowaccount.com/blog/wp-content/uploads/2021/11/1-Cover.png'
    blogB.coverUrlJpg = 'https://flowaccount.com/blog/wp-content/uploads/2021/11/1-Cover.png'
    blogB.heading = 'GET1020 Official Thailand'
    blogB.link = 'https://flowaccount.com/blog/stories-get1020/'
    blogB.imageAlt = 'GET1020 Official Thailand'
    blogB.imageTitle = 'GET1020 Official Thailand'

    const blogC = new Blog()
    blogC.coverUrlWebp =
      'https://flowaccount.com/blog/wp-content/uploads/2021/11/Uppercuz-Creative-ดิจิทัลเอเจนซี-ที่เพิ่มความเชื่อมั่นของธุรกิจด้วยระบบเอกสาร.jpg'
    blogC.coverUrlJpg =
      'https://flowaccount.com/blog/wp-content/uploads/2021/11/Uppercuz-Creative-ดิจิทัลเอเจนซี-ที่เพิ่มความเชื่อมั่นของธุรกิจด้วยระบบเอกสาร.jpg'
    blogC.heading = ' Uppercuz Creative'
    blogC.link = 'https://flowaccount.com/blog/stories-uppercuz-creative/#more-10488'
    blogC.imageAlt = ' Uppercuz Creative'
    blogC.imageTitle = ' Uppercuz Creative'

    const blogD = new Blog()
    blogD.coverUrlWebp =
      'https://flowaccount.com/blog/wp-content/uploads/2021/11/Upskill-UX-สถาบันสอน-UX_UI-ที่เปลี่ยนงานบัญชีและเงินเดือนให้ทำออนไลน์ได้-100_.jpg'
    blogD.coverUrlJpg =
      'https://flowaccount.com/blog/wp-content/uploads/2021/11/Upskill-UX-สถาบันสอน-UX_UI-ที่เปลี่ยนงานบัญชีและเงินเดือนให้ทำออนไลน์ได้-100_.jpg'
    blogD.heading = 'Upskill UX/UI'
    blogD.link = 'https://flowaccount.com/blog/stories-upskill-ux/'
    blogD.imageAlt = 'Upskill UX/UI'
    blogD.imageTitle = 'Upskill UX/UI'

    const blogE = new Blog()
    blogE.coverUrlWebp = 'https://flowaccount.com/blog/wp-content/uploads/2021/12/สนันตน์รัชต์-กรุ๊ป.jpg'
    blogE.coverUrlJpg = 'https://flowaccount.com/blog/wp-content/uploads/2021/12/สนันตน์รัชต์-กรุ๊ป.jpg'
    blogE.heading = 'SNR Group'
    blogE.link = 'https://flowaccount.com/blog/stories-sananrat-group/'
    blogE.imageAlt = 'SNR Group'
    blogE.imageTitle = 'SNR Group'

    const blogF = new Blog()
    blogF.coverUrlWebp =
      'https://flowaccount.com/blog/wp-content/uploads/2021/12/“สาธารณะ”-กิจการเพื่อสังคม-บริหารภาษี-หัก-ณ-ที่จ่าย-ให้ฟรีแลนซ์ง่ายๆ-เพียง-2-คลิก.jpg'
    blogF.coverUrlJpg =
      'https://flowaccount.com/blog/wp-content/uploads/2021/12/“สาธารณะ”-กิจการเพื่อสังคม-บริหารภาษี-หัก-ณ-ที่จ่าย-ให้ฟรีแลนซ์ง่ายๆ-เพียง-2-คลิก.jpg'
    blogF.heading = 'SATARANA'
    blogF.link = 'https://flowaccount.com/blog/stories-satarana/'
    blogF.imageAlt = 'SATARANA'
    blogF.imageTitle = 'SATARANA'

    const blogG = new Blog()
    blogG.coverUrlWebp =
      'https://flowaccount.com/blog/wp-content/uploads/2021/12/Orange-Road-Content-Studio-ที่เลือก-FlowAccount-เติมเต็มเส้นทางผู้ประกอบการให้ราบรื่น.jpg'
    blogG.coverUrlJpg =
      'https://flowaccount.com/blog/wp-content/uploads/2021/12/Orange-Road-Content-Studio-ที่เลือก-FlowAccount-เติมเต็มเส้นทางผู้ประกอบการให้ราบรื่น.jpg'
    blogG.heading = 'Orange Road'
    blogG.link = 'https://flowaccount.com/blog/stories-orange-road/#more-10640'
    blogG.imageAlt = 'Orange Road'
    blogG.imageTitle = 'Orange Road'

    const blogH = new Blog()
    blogH.coverUrlWebp =
      'https://flowaccount.com/blog/wp-content/uploads/2022/01/อู่พงษ์ไพบูลย์-พลิกโฉมธุรกิจกว่า-60-ปี-ด้วยระบบบัญชีดิจิทัล-ที่ช่วยให้ออกใบกำกับภาษี.jpg'
    blogH.coverUrlJpg =
      'https://flowaccount.com/blog/wp-content/uploads/2022/01/อู่พงษ์ไพบูลย์-พลิกโฉมธุรกิจกว่า-60-ปี-ด้วยระบบบัญชีดิจิทัล-ที่ช่วยให้ออกใบกำกับภาษี.jpg'
    blogH.heading = 'PPB Garage'
    blogH.link = 'https://flowaccount.com/blog/stories-car-ppb-garage/'
    blogH.imageAlt = 'PPB Garage'
    blogH.imageTitle = 'PPB Garage'

    const blogI = new Blog()
    blogI.coverUrlWebp =
      'https://flowaccount.com/blog/wp-content/uploads/2022/02/วราเวิร์ดส์-บริษัทรับแปลภาษา-ที่ทำเอกสารส่งให้ลูกค้าต่างชาติได้ง่ายๆ.jpg'
    blogI.coverUrlJpg =
      'https://flowaccount.com/blog/wp-content/uploads/2022/02/วราเวิร์ดส์-บริษัทรับแปลภาษา-ที่ทำเอกสารส่งให้ลูกค้าต่างชาติได้ง่ายๆ.jpg'
    blogI.heading = 'WARAWORDS'
    blogI.link = 'https://flowaccount.com/blog/stories-warawords-translator/'
    blogI.imageAlt = 'WARAWORDS'
    blogI.imageTitle = 'WARAWORDS'

    const blogJ = new Blog()
    blogJ.coverUrlWebp = 'https://flowaccount.com/blog/wp-content/uploads/2022/03/Penguin-Eat-Shabu.jpg'
    blogJ.coverUrlJpg = 'https://flowaccount.com/blog/wp-content/uploads/2022/03/Penguin-Eat-Shabu.jpg'
    blogJ.heading = 'Penguin Eat Shabu'
    blogJ.link = 'https://flowaccount.com/blog/stories-penguin-eat-shabu/'
    blogJ.imageAlt = 'Penguin Eat Shabu'
    blogJ.imageTitle = 'Penguin Eat Shabu'

    const blogK = new Blog()
    blogK.coverUrlWebp = 'https://flowaccount.com/blog/wp-content/uploads/2022/03/มูลนิธิโรงเรียนวันเสาร์.jpg'
    blogK.coverUrlJpg = 'https://flowaccount.com/blog/wp-content/uploads/2022/03/มูลนิธิโรงเรียนวันเสาร์.jpg'
    blogK.heading = 'Saturday School'
    blogK.link = 'https://flowaccount.com/blog/stories-saturday-school-foundation/#more-11006'
    blogK.imageAlt = 'Saturday School'
    blogK.imageTitle = 'Saturday School'

    const blogL = new Blog()
    blogL.coverUrlWebp = 'https://flowaccount.com/blog/wp-content/uploads/2022/06/Cover-768x403.jpg'
    blogL.coverUrlJpg = 'https://flowaccount.com/blog/wp-content/uploads/2022/06/Cover-768x403.jpg'
    blogL.heading = 'G-Dragon Auto Tech'
    blogL.link = 'https://flowaccount.com/blog/stories-g-dragon-autotech/'
    blogL.imageAlt = 'G-Dragon Auto Tech'
    blogL.imageTitle = 'G-Dragon Auto Tech'

    const blogM = new Blog()
    blogM.coverUrlWebp = 'https://flowaccount.com/blog/wp-content/uploads/2023/02/01_Blog-Cover-1200x630-px-3.jpg'
    blogM.coverUrlJpg = 'https://flowaccount.com/blog/wp-content/uploads/2023/02/01_Blog-Cover-1200x630-px-3.jpg'
    blogM.heading = 'Nerd Optimize'
    blogM.link = 'https://flowaccount.com/blog/happy-business-stories-nerd-optimize/'
    blogM.imageAlt = 'Nerd Optimize'
    blogM.imageTitle = 'Nerd Optimize'

    return [blogA, blogB, blogC, blogD, blogE, blogF, blogG, blogH, blogI, blogJ, blogK, blogL, blogM]
  }
}
