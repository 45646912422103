<section class="cashier-function-swiper">
  <!-- topic -->
  <div class="topic">
    <div class="title desktop" [innerHTML]="'MOBILE_POS.CASHIER_SWIPER.TITLE.DESKTOP' | translate"> </div>
    <div class="title tablet" [innerHTML]="'MOBILE_POS.CASHIER_SWIPER.TITLE.TABLET' | translate"> </div>
    <div class="title mobile" [innerHTML]="'MOBILE_POS.CASHIER_SWIPER.TITLE.MOBILE' | translate"> </div>
    <div class="detail">
      {{ 'MOBILE_POS.CASHIER_SWIPER.TITLE.DETAIL' | translate }}
    </div>
    <div class="tab-list desktop">
      <div class="box__btn-container mobile">
        <button (click)="actionClickSlide('prev')" aria-label="previous-slide"><i
            class="fas fa-arrow-left"></i></button>
      </div>
      <ng-container *ngFor="let item of cashierList; let i = index">
        <div class="item" [ngClass]="{ active: currentSlide === (i + 1) }" (click)="goToSlide(i + 1)">{{
          'MOBILE_POS.CASHIER_SWIPER.MENU_BAR.' + (i + 1) | translate
          }}</div>
      </ng-container>
      <div class="box__btn-container mobile">
        <button (click)="actionClickSlide('next')" aria-label="next-slide"><i class="fas fa-arrow-right"></i></button>
      </div>
    </div>
    <div class="tab-list mobile">
      <div class="box__btn-container mobile">
        <button (click)="actionClickSlide('prev')" aria-label="previous-slide"><i
            class="fas fa-arrow-left"></i></button>
      </div>
      <!-- TAB -->
      <ng-container *ngFor="let item of cashierList; let i = index">
        <div *ngIf="currentSlide === (i + 1)" class="item" [ngClass]="{ active: currentSlide ===  (i + 1) }"
          (click)="goToSlide(i + 1)">{{
          'MOBILE_POS.CASHIER_SWIPER.MENU_BAR.' + (i + 1) | translate
          }}</div>
      </ng-container>
      <div class="box__btn-container mobile">
        <button (click)="actionClickSlide('next')" aria-label="next-slide"><i class="fas fa-arrow-right"></i></button>
      </div>
    </div>
  </div>

  <div class="box">
    <div class="box__btn-container desktop">
      <button (click)="actionClickSlide('prev')" aria-label="previous-slide"><i class="fas fa-arrow-left"></i></button>
    </div>

    <div class="box__content">
      <ng-container *ngFor="let item of cashierList; let index = index">
        <div class="box__item" *ngIf="index + 1 === currentSlide" [ngClass]="displaySlideClass(index + 1)">
          <div class="box__left">
            <div class="box__text">
              <div class="box__text--title desktop" [innerHTML]="item.titleDesktop | translate"> </div>
              <div class="box__text--title mobile" [innerHTML]="item.titleMobile | translate"> </div>
              <div class="box__text--description desktop" [innerHTML]="item.description | translate"> </div>
              <div class="box__text--description mobile" [innerHTML]="item.descriptionMobile | translate"> </div>
            </div>
          </div>

          <div class="box__right">
            <ng-container *ngIf="item.imgWebp">
              <div *ngIf="item.linkVideo" class="box__video" (click)="linkVideo(item.linkVideo)">
                <picture>
                  <source srcset="{{ item.imgWebp }}" type="image/webp" />
                  <img src="{{ item.img }}" loading="lazy" class="box__video--img-cover" alt="{{ item.alt }}"
                    title="{{ item.alt }}" />
                </picture>

                <div *ngIf="item.linkVideo !== ''" class="box__video-cover--icon">
                  <picture>
                    <source srcset="https://flowaccountcdn.com/new_landing/image/mobile/Tutorial/Play_button.webp"
                      type="image/webp" />
                    <source srcset="https://flowaccountcdn.com/new_landing/image/mobile/Tutorial/Play_button.png"
                      type="image/jpeg" />
                    <img src="https://flowaccountcdn.com/new_landing/image/mobile/Tutorial/Play_button.png"
                      class="icon-play" alt="FlowAccount Testimonials" title="FlowAccount Testimonials" loading="lazy"
                      width="50" height="50" />
                  </picture>
                </div>
              </div>

              <div *ngIf="!item.linkVideo" class="box__video">
                <picture>
                  <source srcset="{{ item.imgWebp }}" type="image/webp" />
                  <img src="{{ item.img }}" loading="lazy" class="box__video--img-cover" alt="{{ item.alt }}"
                    title="{{ item.alt }}" />
                </picture>
              </div>
            </ng-container>

            <ng-container *ngIf="!item.imgWebp">
              <div class="img-white"> </div>
            </ng-container>
          </div>
        </div>

        <div class="dot-wrapper" *ngIf="index + 1 === currentSlide">
          <div class="dot" [ngClass]="{ active: index + 1 === 1 }"></div>
          <div class="dot" [ngClass]="{ active: index + 1 === 2 }"></div>
          <div class="dot" [ngClass]="{ active: index + 1 === 3 }"></div>
          <div class="dot" [ngClass]="{ active: index + 1 === 4 }"></div>
        </div>
      </ng-container>
    </div>

    <div class="box__btn-container desktop">
      <button (click)="actionClickSlide('next')" aria-label="next-slide"><i class="fas fa-arrow-right"></i></button>
    </div>
  </div>
</section>