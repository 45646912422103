import { DOCUMENT } from '@angular/common'
import { Component, ElementRef, EventEmitter, Inject, Input, Output, Renderer2 } from '@angular/core'
import { Router } from '@angular/router'
import { IAppState } from '../../store.ismobile'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { DeviceDetectorService } from 'ngx-device-detector'
import { Observable, map } from 'rxjs'
import { StateItem, StateSetup } from '../../component-deps'

@Component({
  selector: 'landing-navigation-list',
  templateUrl: './navigation-list.component.html',
  // styleUrls: ['./navigation-list.component.scss']
})
@StateSetup(() => {
  const script = `
    function onMenuToggle(target, index) {
        const checked = window['navigation-list-component-state'].menuToggleChecked[1];
        const stateIndex = window['navigation-list-component-state'].menuToggleChecked[0];
        window['navigation-list-component-state'].menuToggleChecked = [index, !checked]
        if (stateIndex === index) {
          target.checked = !checked;
        }
    }
    function onMenuClick(target) {
      if(window['navigation-list-component-state'].menuToggleChecked[1]) {
        window['navigation-list-component-state'].menuToggleChecked[1] = false;
        document.getElementsByName('menutoggle')[window['navigation-list-component-state'].menuToggleChecked[0]].checked = false;
      }
      if(window['navigation-component-state'].mobileMenuChecked) {
        window['navigation-component-state'].mobileMenuChecked = false;
        document.getElementById('mobile-menu-check').checked = false;
      }
    }
    document.addEventListener("readystatechange", function() {
        if (document.readyState === "complete") {
            document.body.addEventListener('click', function(e) {
            if(e.target.getAttribute('name') != 'menutoggle' && window['navigation-list-component-state'].menuToggleChecked[1]) {
              const element = document.getElementsByName('menutoggle')[window['navigation-list-component-state'].menuToggleChecked[0]].checked = false;
              window['navigation-list-component-state'].menuToggleChecked[1] = false;
            }
          });
        }
    });
  `
  return { name: 'navigation-list-component-state', state: { menuToggleChecked: [0, false] }, script: script }
})
export class NavigationListComponent {
  @StateItem(() => {
    if (window['navigation-list-component-state'] && window['navigation-list-component-state'].menuToggleChecked) {
      return window['navigation-list-component-state'].menuToggleChecked
    }
    return null
  })
  menuToggleChecked = [0, false]
  @Input() appname: string
  @Output() clickMenuChange = new EventEmitter<boolean>()
  @Output() clickSingleMenu = new EventEmitter<boolean>()

  public language: Observable<string>
  public isMobile: Observable<boolean>

  public menuFunctionList = [
    {
      pageName: 'business',
      icon: 'fas fa-user-tie',
      heading: 'Navigation.Functions_business',
      info: 'Navigation.Functions_business_info',
    },
    {
      pageName: 'accounting',
      icon: 'fas fa-calculator',
      heading: 'Navigation.Functions_accountant',
      info: 'Navigation.Functions_accountant_info',
    },
    {
      pageName: 'quotation',
      icon: 'far fa-file-alt',
      heading: 'Navigation.Functions_quotation',
      info: 'Navigation.Functions_quotation_info',
    },
    {
      pageName: 'receipt',
      icon: 'far fa-file-alt',
      heading: 'Navigation.Functions_receipt',
      info: 'Navigation.Functions_receipt_info',
    },
    {
      pageName: 'invoice',
      icon: 'far fa-file-alt',
      heading: 'Navigation.Functions_billing_note_invoice',
      info: 'Navigation.Functions_billing_note_invoice_info',
    },
    {
      pageName: 'tax-invoice',
      icon: 'far fa-file-alt',
      heading: 'Navigation.Functions_Functions_tax_invoice',
      info: 'Navigation.Functions_Functions_tax_invoice_info',
    },
    {
      pageName: 'e-commerce',
      icon: 'far fa-file-alt',
      heading: 'Navigation.Functions_shopee_lazada',
      info: 'Navigation.Functions_shopee_lazada_info',
    },
    {
      pageName: 'flowpay',
      icon: 'far fa-file-alt',
      heading: 'Navigation.Functions_flowpay',
      info: 'Navigation.Functions_flowpay_info',
    },
  ]
  public menuAcademyList = [
    {
      pageName: 'blog',
      icon: '',
      heading: 'Navigation.Accounting_knowledge_submenu',
      info: 'Navigation.Accounting_knowledge_submenu_info',
    },
    {
      pageName: 'seminars',
      icon: 'fas fa-chalkboard-teacher',
      heading: 'Navigation.Seminar_SubMenu',
      info: 'Navigation.Seminar_SubMenu_info',
    },
    {
      pageName: 'tutorials',
      icon: 'fas fa-play-circle',
      heading: 'Navigation.Videos',
      info: 'Navigation.Videos_info',
    },
    {
      pageName: 'ultimateguide',
      icon: 'fas fa-book-open',
      heading: 'Navigation.Ultimate',
      info: 'Navigation.Ultimate_info',
    },
  ]

  public partnerList = [
    {
      pageName: 'become-our-partner',
      icon: 'fas fa-book-open',
      heading: 'Navigation.Accounting_firm_partner',
      info: 'Navigation.Accounting_firm_partner_info',
      href: ['accountingfirm', 'become-our-partner'],
    },
    {
      pageName: 'flowaccount-for-education',
      icon: 'fas fa-book-open',
      heading: 'Navigation.FlowAccount_for_education',
      info: 'Navigation.FlowAccount_for_education_info',
      href: ['flowaccount-for-education'],
    },
    {
      pageName: 'platform-businesses',
      icon: 'fas fa-book-open',
      heading: 'Navigation.Platform_businesses_and_service',
      info: 'Navigation.Platform_businesses_and_service_info',
      href: ['our-integrations'],
    },
  ]

  public menuServiceList = [
    {
      pageName: 'business-registration',
      icon: 'fas fa-book-open',
      heading: 'Navigation.BUSINESS_REGISTRATION_SUBMENU.TITLE',
      info: 'Navigation.BUSINESS_REGISTRATION_SUBMENU.DETAIL',
    },
    {
      pageName: 'accountingfirm',
      pageName2: 'businessowner',
      icon: 'fas fa-book-open',
      heading: 'Navigation.FIND_AN_ACCOUNTANT_SUBMENU.TITLE',
      info: 'Navigation.FIND_AN_ACCOUNTANT_SUBMENU.DETAIL',
    },
    {
      pageName: 'google-workspace',
      icon: 'fas fa-book-open',
      heading: 'Navigation.GOOGLE_WORKSPACE_SUBMENU.TITLE',
      info: 'Navigation.GOOGLE_WORKSPACE_SUBMENU.DETAIL',
    },
  ]

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public translate: TranslateService,
    private router: Router,
    private el: ElementRef,
    private deviceService: DeviceDetectorService,
    private store: Store<{ appState: IAppState }>,
    private renderer: Renderer2,
  ) {
    this.language = this.store.select((s) => s.appState.langPath)
    this.isMobile = this.store.select((s) => s.appState.isMobile)
  }

  // goToFunctionDiv() {
  //   if (isPlatformBrowser(this.platformId)) {
  //     if (this.router.url !== '/'){
  //       let navigatePath = '/';
  //       if(this.router.url.startsWith('/th')){
  //         navigatePath = '/th';
  //       } else if (this.router.url.startsWith('/en')) {
  //         navigatePath = '/en';
  //       }
  //       this.router.navigateByUrl(navigatePath);

  //       setTimeout(() => {
  //         const target = document.getElementById('home-function');
  //         target.scrollIntoView({ block: 'start', behavior: 'smooth' });
  //       }, 100);
  //     }else{
  //       const target = document.getElementById('home-function');
  //       target.scrollIntoView({ block: 'start', behavior: 'smooth' });
  //     }
  //   }
  // }

  goToContact() {
    // console.log('pos:' + this.findPos(document.querySelector('.advance-flow-footer')));
    const target = document.getElementById('contactus')
    target.scrollIntoView({ block: 'end', behavior: 'smooth' })
    //  setTimeout(_ => {
    //    console.log('timedout')
    //   target.scrollIntoView({block: 'end',  behavior: 'smooth'});
    //   window.scrollBy(0, -100); // Nav's height
    //   // this.scrolled = true;
    //  }, 3000);
  }

  getDynamicRoute(itemHref: string[]): Observable<string[]> {
    return this.language.pipe(map((language) => [language, ...itemHref]))
  }
}
