import { Injectable, Inject, PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'
import Swal from 'sweetalert2'
declare var $: any
@Injectable()
export class SharedService {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}
  openLinkDocumentModal(link) {
    if (isPlatformBrowser(this.platformId)) {
      const showMoreLink = link === 'https://flowaccountcdn.com/assets/images/function/เอกสาร_งบทดลอง.pdf'
      let html =
        '<div class="body-link-document" [ngClass]="{\'more-embed\':showMoreLink}"> \
            <embed class="modal-link-document main" src="' +
        link +
        '" type="application/pdf" width="100%" height="100%" /> \
            <!-- showMoreLink งบการเงิน งบกำไรขาดทุน งบแสดงฐานะทางการเงิน -->'
      if (showMoreLink) {
        html =
          html +
          '<embed class="modal-link-document" \
                    src="https://flowaccountcdn.com/assets/images/function/เอกสาร_งบกำไรขาดทุน.pdf" \
                    type="application/pdf" width="100%" height="100%" /> \
                    <embed class="modal-link-document" \
                    src="https://flowaccountcdn.com/assets/images/function/เอกสาร_งบแสดงฐานะทางการเงิน.pdf"\
                    type="application/pdf" width="100%" height="100%" />'
      }
      html = html + '</div>'
      const swal = Swal.fire({
        html: html,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
        heightAuto: false,
        padding: 0,
        didOpen: (modalElement) => {
          const el = <HTMLElement>modalElement.querySelector('.body-link-document')
          el.focus()
        },
      })
      return false
    }
  }
  showImage(src) {
    if (isPlatformBrowser(this.platformId)) {
      const swal = Swal.fire({
        html:
          '<div id="show-image-div"> \
                            <img src="' +
          src +
          '" \
                            class="modal-show-image"  width="100%" #showImageModal alt="ตัวอย่างเอกสาร" /> \
                        </div>',
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
        heightAuto: false,
        didOpen: (modalElement) => {
          const el = <HTMLElement>modalElement.querySelector('#show-image-div')
          el.focus()
        },
      })
      return false
    }
  }
  openVideoTutorialModal(url) {
    if (isPlatformBrowser(this.platformId) && url) {
      const swal = Swal.fire({
        html:
          '<iframe id="modal-video-function" width="100%" height="100%"  \
                src="https://www.youtube.com/embed/' +
          url +
          '?rel=0&amp;autoplay=1;showinfo=0" \
                 frameborder="0" \
                allowfullscreen=""></iframe>',
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
        heightAuto: false,
        didOpen: (modalElement) => {
          const el = <HTMLElement>modalElement.querySelector('#modal-video-function')
          el.focus()
        },
      })
      return false
    }
  }
  public loadVideos() {
    // console.log(window.mobileAndTabletcheck());
    if (isPlatformBrowser(this.platformId)) {
      let videos: any
      if (!document.getElementsByClassName) {
        // IE8 support
        const getElementsByClassName = function (node, classname) {
          const a = []
          const re = new RegExp('(^| )' + classname + '( |$)')
          const els = node.getElementsByTagName('*')
          for (let i = 0, j = els.length; i < j; i++) if (re.test(els[i].className)) a.push(els[i])
          return a
        }
        videos = getElementsByClassName(document.body, 'youtube')
      } else {
        videos = document.getElementsByClassName('youtube')
      }
      const nb_videos = videos.length
      for (let i = 0; i < nb_videos; i++) {
        // Based on the YouTube ID, we can easily find the thumbnail image
        videos[i].style.backgroundImage = 'url(https://i.ytimg.com/vi/' + videos[i].id + '/maxresdefault.jpg)'

        // Overlay the Play icon to make it look like a video player
        const play = document.createElement('div')
        play.setAttribute('class', 'play')
        videos[i].appendChild(play)

        videos[i].onclick = function () {
          $(this).fadeOut('fast', function () {
            // Create an iFrame with autoplay set to true
            const iframe = document.createElement('iframe')
            let iframe_url = 'https://www.youtube.com/embed/' + this.id + '?autoplay=1&autohide=1&rel=0'

            if (this.getAttribute('data-params')) iframe_url += '&' + this.getAttribute('data-params')

            iframe.setAttribute('class', 'embed-responsive-item')
            iframe.setAttribute('src', iframe_url)
            iframe.setAttribute('frameborder', '0')
            iframe.setAttribute('allowfullscreen', '')

            if (this.getAttribute('class') == 'youtube vdo big' && $(window).width() > 671) {
              iframe.setAttribute('width', '420')
              iframe.setAttribute('height', '237')
            } else {
              iframe.setAttribute('width', '300')
              iframe.setAttribute('height', '169')
            }

            if (this.getAttribute('class') == 'youtube vdo big seminar' && $(window).width() > 671) {
              iframe.setAttribute('width', '640')
              iframe.setAttribute('height', '360')
            }
            iframe.setAttribute('id', this.id)

            // The height and width of the iFrame should be the same as parent
            iframe.style.width = this.style.width
            iframe.style.height = (169).toString()
            // Replace the YouTube thumbnail with YouTube Player
            this.parentNode.replaceChild(iframe, this)
            setTimeout(function () {
              // onYouTubePlayerAPIReady(iframe.getAttribute('id'),300);
            }, 3000)
          })
        }
      }
    } else {
      const videos = $('.youtube.vdo')
      const nb_videos = videos.length
      this.loadMobileVideo(videos[0], 0, videos)
    }
  }

  getTutorialsPath(lang: string) {
    const langSlug = lang === 'th' ? '' : `${lang}/`
    return `https://flowaccount.com/${langSlug}tutorials`
  }

  private loadMobileVideo(video, index, videos) {
    const seft = this
    const iframe = document.createElement('iframe')
    let iframe_url = 'https://www.youtube.com/embed/' + video.id + '?autohide=1'
    if (video.getAttribute('data-params')) iframe_url += '&' + video.getAttribute('data-params')
    iframe.setAttribute('src', iframe_url)
    iframe.setAttribute('frameborder', '0')
    iframe.setAttribute('allowfullscreen', '')
    if (video.getAttribute('class') == 'youtube vdo big' && $(window).width() > 671) {
      iframe.setAttribute('width', '420')
      iframe.setAttribute('height', '236')
      iframe.style.height = (236).toString()
    } else {
      iframe.setAttribute('width', '300')
      iframe.setAttribute('height', '169')
      iframe.style.height = (169).toString()
    }

    if (video.getAttribute('class') == 'youtube vdo big seminar' && $(window).width() > 671) {
      iframe.setAttribute('width', '640')
      iframe.setAttribute('height', '360')
      iframe.style.height = (360).toString()
    }

    iframe.setAttribute('id', video.id)
    // The height and width of the iFrame should be the same as parent
    iframe.style.width = video.style.width
    // Replace the YouTube thumbnail with YouTube Player
    video.parentNode.replaceChild(iframe, video)

    // iframe.setAttribute("onclick","gaTrackEvent('Video', 'View',' " + video.getAttribute('name') + " )" );
    $(iframe).on('load', function () {
      // do stuff
      if (videos.length - 1 > index) {
        seft.loadMobileVideo(videos[index + 1], index + 1, videos)
      }
    })
  }
}
