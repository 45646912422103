import { Component, ViewEncapsulation } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'flowaccount-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SitemapComponent {
  public contentColumns = [
    {
      heading: 'Navigation.Products',
      link: [
        { label: 'FlowAccount', path: '' },
        { label: 'MobilePOS', path: 'mobile-pos' },
        { label: 'Payroll', path: 'payroll' },
        { label: 'AutoKey', path: 'autokey' },
      ],
    },
    {
      heading: 'FlowAccount',
      link: [
        { label: 'Sitemap.BusinessFunctions', path: 'functions/business' },
        { label: 'Sitemap.AccountingFunctions', path: 'functions/accounting' },
        { label: 'Sitemap.quotation', path: 'functions/quotation' },
        { label: 'Sitemap.receipt', path: 'functions/receipt' },
        { label: 'Navigation.Developer', path: 'developers' },
      ],
    },
    {
      heading: 'Navigation.Seminar',
      link: [
        { label: 'Navigation.Seminar_SubMenu', path: 'seminars' },
        { label: 'Sitemap.knowledge-accounting', path: 'blog', isThaiOnly: true },
        { label: 'Sitemap.Tutorial', path: 'tutorials' },
        { label: 'Navigation.Ultimate', path: 'ultimateguide' },
        { label: 'Navigation.Faq', path: 'faq', isThaiOnly: true },
      ],
    },
    {
      heading: 'Navigation.About Us',
      link: [
        { label: 'Sitemap.Become-partner', path: 'accountingfirm/become-our-partner' },
        { label: 'Sitemap.About', path: 'about-us' },
        { label: 'Navigation.Contact Us', path: 'contact-us' },
        { label: 'Navigation.Jobs', path: 'jobs' },
        { label: 'Navigation.termOfUse', path: 'term-of-use' },
        { label: 'Navigation.Privacy', path: 'privacy-statement' },
        { label: 'Navigation.Migration', path: 'upgrade-to-new-flowaccount' },
      ],
    },
    {
      class: 'calculation',
      link: [{ label: 'Sitemap.vat-wht-calculation', path: 'functions/calculator-tax-and-profit' }],
    },
    {
      heading: 'Payroll',
      class: 'payroll',
      link: [{ label: 'Sitemap.BusinessFunctions', path: 'payroll' }],
    },
    {
      heading: 'AutoKey',
      class: 'autokey',
      link: [
        { label: 'Sitemap.BusinessFunctions', path: 'autokey' },
        { label: 'Sitemap.AccountingFunctions', path: 'autokey' },
      ],
    },
    {
      class: 'businessowner',
      link: [{ label: 'Sitemap.find-accountant', path: 'accountingfirm/businessowner' }],
    },
  ]

  constructor(public translate: TranslateService) {}
}
