<div class="notfound container">
  <div class="notfound-head">ไม่พบหน้าเพจที่ต้องการ</div>
  <div class="notfounf-detail"
    >หากต้องการค้นหาข้อมูลส่วนใดสามารถกดเลือกจากเมนูได้เลย หรือ กดปุ่มกลับสู่หน้าหลักที่ด้านล่างนี้</div
  >
  <div class="gotohomepage-margin mobile">
    <a class="gotohomepage" href="https://flowaccount.com/">กลับสู่หน้าหลัก</a>
  </div>
  <div class="notfound-img">
    <img src="https://flowaccountcdn.com/image/404.png" width="100%" alt="FlowAccount 404 pagenotfound"/>
  </div>
  <div class="gotohomepage-margin desktop">
    <a class="gotohomepage" href="https://flowaccount.com/">กลับสู่หน้าหลัก</a>
  </div>
  <div class="notfound-tell">ต้องการความช่วยเหลือโทร. 02-0268989</div>
</div>
