import { Component } from '@angular/core'
import { SharedService } from '@flowaccount/landing'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'flowaccount-mobile-pos-intro',
  templateUrl: './mobile-pos-intro.component.html',
  styleUrls: ['./mobile-pos-intro.component.scss'],
})
export class MobilePosIntroComponent {
  constructor(public translate: TranslateService, public sharedService: SharedService) {}
  showImage() {
    const url = 'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobile_pos_popup_download_th.jpg'
    return this.sharedService.showImage(url)
  }
  showImageMobile() {
    const url =
      'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobile_pos_popup_download_th_mobile.jpg'
    return this.sharedService.showImage(url)
  }
}
