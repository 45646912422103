<section class="mobile-pos-app color-gray-light">
  <div class="box-container">
    <div class="box">
      <div class="box__left">
        <!-- desktop heading -->
        <div class="box__heading color-gray box__heading--desktop" [innerHtml]="'MOBILE_POS.APP.HEADING' | translate">
        </div>

        <!-- tablet heading -->
        <div
          class="box__heading color-gray box__heading--tablet"
          [innerHtml]="'MOBILE_POS.APP.HEADING_TABLET' | translate"
        >
        </div>

        <!-- mobile heading -->
        <div
          class="box__heading color-gray box__heading--mobile"
          [innerHtml]="'MOBILE_POS.APP.HEADING_MOBILE' | translate"
        >
        </div>

        <div class="box__detail">
          {{ 'MOBILE_POS.APP.DETAIL' | translate }}
        </div>
        <!-- mobile app -->
        <div class="box__app">
          <!-- ios -->
          <a
            href="https://itunes.apple.com/th/app/new-flowaccount/id1454207520"
            rel="nofollow noopener"
            target="_blank"
            aria-label="Download iOS"
          >
            <div class="ios-icon">
              <picture>
                <source srcset="https://flowaccountcdn.com/new_landing/icon/app-store-logo-v2.webp" type="image/webp" />
                <source srcset="https://flowaccountcdn.com/new_landing/icon/app-store-logo-v2.png" type="image/png" />
                <img
                  src="https://flowaccountcdn.com/new_landing/icon/app-store-logo-v2.png"
                  width="90"
                  height="20"
                  alt="flowaccount ios application"
                  title=""
                  loading="lazy"
                />
              </picture>
            </div>
          </a>

          <!-- play store -->
          <a
            href="https://play.google.com/store/apps/details?id=com.flowaccount.android&hl=th"
            rel="nofollow noopener"
            target="_blank"
            aria-label="Donwload Android"
          >
            <div class="android-icon">
              <picture>
                <source
                  srcset="https://flowaccountcdn.com/new_landing/icon/google-play-logo-v2.webp"
                  type="image/webp"
                />
                <source
                  srcset="https://flowaccountcdn.com/new_landing/icon/google-play-logo-v2.png"
                  type="image/jpeg"
                />
                <img
                  src="https://flowaccountcdn.com/new_landing/icon/google-play-logo-v2.png"
                  width="99"
                  height="30"
                  alt="flowaccount google play application"
                  title=""
                  loading="lazy"
                />
              </picture>
            </div>
          </a>

          <!-- huawei app gallery -->
          <a
            href="https://appgallery.huawei.com/app/C105848487"
            rel="nofollow noopener"
            target="_blank"
            aria-label="Download  huawei app gallery"
          >
            <div class="huawei-app-icon">
              <picture>
                <source srcset="https://flowaccountcdn.com/new_landing/icon/huawei-logo-v2.webp" type="image/webp" />
                <source srcset="https://flowaccountcdn.com/new_landing/icon/huawei-logo-v2.png" type="image/png" />
                <img
                  src="https://flowaccountcdn.com/new_landing/icon/huawei-logo-v2.png"
                  width="99"
                  height="30"
                  alt="flowaccount  huawei app gallery application"
                  title=""
                  loading="lazy"
                />
              </picture>
            </div>
          </a>
        </div>
      </div>
      <div class="box__right">
        <div class="box__item" (click)="linkVideo('4Eq8GS6kXZs')" data-toggle="modal" data-target="#functionVideo">
          <div class="box__item-cover">
            <div class="box__item-cover--bg"></div>
            <picture>
              <source
                srcset="https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobile_pos_s4_v2.webp"
                type="image/webp"
              />
              <source
                srcset="https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobile_pos_s4_v2.png"
                type="image/png"
              />
              <img
                src="https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobile_pos_s4_v2.png"
                class="box__image"
                alt="แคชเชียร์บนมือถือ"
                title="แคชเชียร์บนมือถือ"
                loading="lazy"
              />
            </picture>

            <div class="box__item-cover--icon">
              <picture>
                <source
                  srcset="https://flowaccountcdn.com/new_landing/image/mobile/Tutorial/Play_button.png"
                  type="image/webp"
                />
                <source
                  srcset="https://flowaccountcdn.com/new_landing/image/mobile/Tutorial/Play_button.png"
                  type="image/jpeg"
                />
                <img
                  src="https://flowaccountcdn.com/new_landing/image/mobile/Tutorial/Play_button.png"
                  class="icon-play"
                  alt="icon play video POS บนมือถือ"
                  title="icon play video POS บนมือถือ"
                  loading="lazy"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
