import {
  ɵComponentDef as ComponentDef,
  ɵDirectiveDef as DirectiveDef,
  ɵNgModuleDef as ModuleDef,
  ɵPipeDef as PipeDef,
  Type,
  SchemaMetadata,
  Injector,
  Renderer2,
  Directive,
} from '@angular/core'

export interface ComponentDepsConfig {
  imports?: Type<any>[]
  directives?: Type<any>[]
  pipes?: Type<any>[]
  schemas?: SchemaMetadata[]
}
function getDirectiveDef<T>(t: Type<T>): DirectiveDef<T> {
  if (t['ɵdir']) {
    return t['ɵdir'] as DirectiveDef<T>
  }
  if (t['ɵcmp']) {
    return t['ɵcmp'] as ComponentDef<T>
  }
  throw new Error('No Angular definition found for ' + t.name)
}
function getDirectiveDefs(types: Type<any>[]): DirectiveDef<any>[] {
  return types.map(t => getDirectiveDef(t))
}

function getPipeDef<T>(t: Type<T>): PipeDef<T> {
  if (t['ɵpipe']) {
    return t['ɵpipe'] as PipeDef<T>
  }
  throw new Error('No Angular definition found for ' + t.name)
}

export function getPipeDefs(types: Type<any>[]): PipeDef<any>[] {
  return types.map(t => getPipeDef(t))
}

function getModuleDef<T>(t: Type<T>): ModuleDef<T> {
  if (t['ɵmod']) {
    return t['ɵmod'] as ModuleDef<T>
  }
  throw new Error('No Angular definition found for ' + t.name)
}
export function getModuleDefs(types: Type<any>[]): ModuleDef<any>[] {
  return types.map(t => getModuleDef(t))
}
export function ComponentDeps(config: ComponentDepsConfig) {
  return (componentType: Type<any>) => {
    const def = componentType['ɵcmp'] || componentType['ngComponentDef']
    // directives or components
    def.directiveDefs = [...getDirectiveDefs(config.directives || [])]

    // pipes
    def.pipeDefs = [...getPipeDefs(config.pipes || [])]

    def.importDefs = [...getModuleDefs(config.imports || [])]

    // def.schemas = config.schemas;
  }
}

interface IStateDirective {
  renderer: Renderer2
  document: Document
  onDestroy?: () => void
  onInit?: () => void
  id: string
}

export declare interface StateSetup extends Directive {


}
export function StateSetup(state: () => Object) {
  return (target: any) => {
    target.prototype.originalOnInit = target.prototype.ngOnInit
    target.prototype.ngOnInit = function() {
      if (this.originalOnInit) {
        this.originalOnInit()
      }
      const stateConfig = state.apply(target)
      if (window && !window[stateConfig.name]) {
        /* adding state to page */
        const scriptAction = this.renderer.createElement('script')
        scriptAction.id = `${stateConfig.name}`
        scriptAction.type = 'text/javascript'
        scriptAction.textContent = `${stateConfig.script}`
        const scriptState = this.renderer.createElement('script')
        scriptState.id = `${stateConfig.name}`
        scriptState.textContent = `window['${stateConfig.name}']=${JSON.stringify(stateConfig.state)}`
        this.renderer.appendChild(this.document.body, scriptAction)
        this.renderer.appendChild(this.document.body, scriptState)
      }
    }
  }
}

export function StateItem(stateGetter: () => Object) {
  return (target: Object, key: string) => {
    const stateValue = stateGetter.apply(this)
    let val = target[key]
    if (stateValue != null) {
      val = stateValue
    }
    const getter = () => {
      if (stateValue != null) {
        return stateValue
      }
      return val
    }
    const setter = next => {
      val = next
    }
    Object.defineProperty(target, key, {
      configurable: false,
      get: getter,
      set: setter,
    })
  }
}
