import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SquidexService } from '../../../services/squidex.service';
import { Subscription } from 'rxjs'
import { isScullyGenerated, TransferStateService } from '@scullyio/ng-lib'
import { take } from 'rxjs/operators'
import { SwiperOptions } from 'swiper/types'
@Component({
  selector: 'flowaccount-blog-swiper-squidex',
  templateUrl: './blog-swiper-squidex.component.html',
  styleUrls: ['./blog-swiper-squidex.component.scss'],
})
export class BlogSwiperSquidexComponent implements OnInit {
  @Input() customHeader: string
  @Input() styleClass: string
  @Input() lang = 'th'
  @Input() contentSquidex: string
  @Input() contentScully: string
  public ServiceList: any
  private subscriptionService: Subscription[] = []
  public config: SwiperOptions
  constructor(
    public translate: TranslateService,
    public readonly squidexService: SquidexService,
    protected tstate: TransferStateService,
    public cdref: ChangeDetectorRef,
  ) {}
  ngOnInit(): void {
    // After scully builded
    if (isScullyGenerated()) {
      this.tstate.useScullyTransferState(this.contentScully, this.ServiceList).subscribe((data: object[]) => {
        this.ServiceList = data
        this.cdref.markForCheck()
      })
    } else {
      // run every build
      this.getAccountingService()
    }
    this.swiperConfig()
  }
  async getAccountingService() {
    this.subscriptionService.push(
      this.squidexService
        .getContentSquidexApiOrder(this.contentSquidex)
        .pipe(take(1))
        .subscribe((data: object[]) => {
          this.ServiceList = data
          this.tstate.setState(this.contentScully, data)
          this.cdref.markForCheck()
        }),
    )
  }

  swiperConfig() {
    this.config = {
      observer: true,
      observeParents: true,
      speed: 700,
      keyboard: false,
      mousewheel: true,
      scrollbar: false,
      navigation: {
        nextEl: '.swiper-button-next-blog',
        prevEl: '.swiper-button-prev-blog',
      },
      breakpoints: {
        1000: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        650: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        400: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
      },
    }
  }

  getText(text: string) {
    return this.translate.instant('BLOG_SWIPER_TOPIC.' + text)
  }
  displayCustomText(textObject, textPosition) {
    const { heading, detail } = textObject

    switch (textPosition) {
      case 'heading':
        return this.translate.instant(heading)
        break
      case 'detail':
        return this.translate.instant(detail)
        break
    }
  }
  ngOnDestroy() {
    this.subscriptionService.forEach((x) => x.unsubscribe())
  }
}
