export class Faq {
  coverUrlWebp: string
  coverUrlJpg: string
  heading: string
  shortDescription: string
  link: string
  imageTitle: string
  imageAlt: string
  head_class: string
  detail_class: string
}

export enum Pages {
  Home,
  FunctionBusiness,
  FunctionAccounting,
  Pricing,
  AccountingfirmBusinessOwner,
  AccountingfirmAccountant,
  Developers,
  Seminars,
  Tutorials,
  MigrateNewFlowAccount,
  FunctionBillingNoteAndInvoiceFAQ,
  TaxInvoiceFAQ,
  FlowPay
}
