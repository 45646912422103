<section class="social-chat">
  <div (click)="toggleDisplay()" class="box">
    <img class="box-icon" src="https://flowaccountcdn.com/new_landing/image/social_chat.svg" alt="social_chat" />
    <div [ngClass]="{ active: isShow }" class="box-content">
      <div class="box-content__icon">
        <a
          *ngFor="let item of mockContent"
          class="layout_chat"
          [ngClass]="item.class"
          [href]="item.link"
          target="_blank"
          [attr.aria-label]="item.text"
        >
          <img [src]="item.icon" [alt]="item.text" />
        </a>
      </div>
    </div>
  </div>
</section>
