import { Component, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'flowaccount-social-chat',
  templateUrl: './social-chat.component.html',
  styleUrl: './social-chat.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SocialChatComponent {
  public isShow = false
  mockContent = [
    {
      class: 'icon-fb',
      link: 'https://m.me/1395566264070503',
      text: 'Messenger',
      icon: 'https://flowaccountcdn.com/new_landing/image/messenger_chat.svg',
    },
    {
      class: 'icon-line',
      link: 'https://page.line.me/flowaccount?openQrModal=true',
      text: 'Line',
      icon: 'https://flowaccountcdn.com/new_landing/image/line_chat.svg',
    },
  ]
  toggleDisplay() {
    if (window.innerWidth <= 1100) {
      this.isShow = !this.isShow
    }
  }
}
