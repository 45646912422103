import { Pipe, PipeTransform } from '@angular/core'
import { Feature, IAppState } from '@flowaccount/landing'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { takeUntil, take } from 'rxjs/operators'
import { isScullyRunning, isScullyGenerated } from '@scullyio/ng-lib'

let featuresObj: Feature = {}
@Pipe({ name: 'featureItem', pure: true })
export class FeatureItemPipe implements PipeTransform {
  private ngUnsubScribe = new Subject()
  constructor(private store: Store<{ appState: IAppState }>) {
    if (Object.keys(featuresObj).length === 0) {
      this.store
        .select(s => s.appState.features)
        .pipe(takeUntil(this.ngUnsubScribe))
        .subscribe(features => {
          featuresObj = features
        })
    }
  }

  transform(value: string): any {
    // if (isScullyGenerated()) {
    //   return;
    // }
    return value.split('.').reduce((o, i) => o[i], featuresObj)
  }
}
