import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'flowaccount-mobile-pos-sunmi',
  templateUrl: './mobile-pos-sunmi.component.html',
  styleUrls: ['./mobile-pos-sunmi.component.scss'],
})
export class MobilePosSunmiComponent implements OnInit {
  constructor(public translate: TranslateService) {}

  ngOnInit(): void {}
}
