export enum TaxInvoiceTab {
  FullTaxInvoice = 1,
  AbbreviatedTaxInvoice = 2,
  ElectronicTaxInvoice = 3,
}

export enum FlowPayThirdParty {
  Stripe = 1,
  Omise = 2,
}

export enum PartnerPrivilege {
  PROMOTE = 1,
  BE_PART_OF_TEAM = 2,
  SPECIAL_PRIZE = 3,
  SPECIAL_TAKE_CARE = 4,
}

export enum ActionType {
  prev = 1,
  next = 3,
}

export enum TrafficSourceType {
  DIRECT_TRAFFIC = 'Direct Traffic',
  REFERRAL_TRAFFIC = 'Referral Traffic',
  ORGANIC_SEARCH_TRAFFIC = 'Organic Search Traffic',
  PAID_SEARCH_TRAFFIC = 'Paid Search Traffic',
  MAIL_TRAFFIC = 'Mail Traffic',
  SOCIAL_TRAFFIC = 'Social Traffic',
  UNKNOWN_TRAFFIC = 'Unknown Traffic',
}

export enum LandingGrowthBookFeatureFlag {
  PRICING_TABLE_FEATURE_FLAG = 'landing-pricing-table',
  MOST_POPULAR_FEATURE_FLAG = 'landing-pricing-most-popular',
}

export enum SignupPreviousPath {
  FLOWACCOUNT = 'flowaccount',
  FLOWPAYROLL = 'payroll',
  MOBILE_POS = 'mobile-pos',
  AUTOKEY = 'autokey',
  FREE_PACKAGE = 'free-package',
  STANDARD_PACKAGE = 'standard-package',
  PRO_PACKAGE = 'pro-package',
  PRO_BUSINESS_PACKAGE = 'pro-business-package',
  QUOTATION = 'quotation',
  INVOICE = 'invoice',
  RECEIPT = 'receipt',
  E_COMMERCE = 'e-commerce',
  BUSINESS = 'business',
  ACCOUNTING = 'accounting',
  TAX_INVOICE = 'tax-invoice',
  ULTIMATEGUIDE = 'ultimateguide',
}
