<ng-container>
  <div id="downloadapp" #downloadAppTab class="download-app-tab">
    <div class="pull-left">
      <div id="closeDownloadMobileButton" onclick="onCloseDownloadApp()" class="app-close"
        ><i class="fas fa-times"></i
      ></div>
      <div class="app-logo">
        <picture>
          <source
            srcset="https://flowaccountcdn.com/images/cover-page/App-icon-normalsize_2021_80px.webp"
            type="image/webp"
          />
          <source srcset="https://flowaccountcdn.com/images/cover-page/Flowaccount_logo2021.png" type="image/jpeg" />
          <img
            src="https://flowaccountcdn.com/images/cover-page/Flowaccount_logo2021.png"
            alt="flowaccount application"
            title=""
          />
        </picture>
      </div>
      <div class="app-detail"
        >FlowAccount<br /><span style="font-size: 12px" translate="App_sticky.Download_App"></span
      ></div>
    </div>
    <div class="pull-right">
      <div class="app-download">
        <a
          id="downloadAppLink-downloadbar"
          href="#"
          #appDownloadButton
          class="app-download-button"
          rel="noreferrer"
          target="_blank"
          translate="App_sticky.Download_App_button"
        ></a>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
  <!-- HEADER -->
  <header class="flowaccount-navigation">
    <section #menuSection class="menu-section clearfix">
      <div class="wrapper">
        <!-- menu-support -->
        <div class="menu-support clearfix">
          <a
            class="suport-contact gray55"
            href="tel:020268989"
            routerLinkActive="current"
            title="{{ 'Navigation.Contact Sales' | translate }}"
          >
            <i class="fas fa-phone-volume"></i><span>{{ 'Navigation.Contact Sales' | translate }}</span>
          </a>
          <a
            href="https://auth.flowaccount.com/{{ translate.currentLang }}"
            class="app-with-icon-btn"
            (click)="goToLogin()"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="icon-btn"
            >
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 10.5H0V8.5H12V10.5Z" fill="#2898CB" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.20717 5.29297L12.7072 8.79296C13.0977 9.18346 13.0977 9.81666 12.7072 10.2072L9.20717 13.7072L7.79297 12.293L10.5859 9.50006L7.79297 6.70718L9.20717 5.29297Z"
                fill="#2898CB"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5 1.5C5 0.94772 5.44772 0.5 6 0.5H18C18.5523 0.5 19 0.94772 19 1.5V17.5C19 18.0523 18.5523 18.5 18 18.5H6C5.44772 18.5 5 18.0523 5 17.5V14.5H7V16.5H17V2.5H7V4.5H5V1.5Z"
                fill="#2898CB"
              />
            </svg>
            {{ 'Navigation.Log in' | translate }}
          </a>
          <div class="language-icon">
            <a
              *ngIf="translate.currentLang === 'en'"
              href="#"
              (click)="switchLanguage($event, 'th')"
              aria-label="thai language"
            >
              <picture>
                <source srcset="https://flowaccountcdn.com/assets/image/language-th.webp?v=2" type="image/webp" />
                <img
                  src="https://flowaccountcdn.com/assets/image/language-th.png?v=2"
                  width="25"
                  height="25"
                  alt="Thai language"
                />
              </picture>
            </a>
            <a
              *ngIf="translate.currentLang === 'th'"
              href="#"
              (click)="switchLanguage($event, 'en')"
              aria-label="english language"
            >
              <picture>
                <source srcset="https://flowaccountcdn.com/assets/image/language-en.webp?v=2" type="image/webp" />
                <img
                  src="https://flowaccountcdn.com/assets/image/language-en.png?v=2"
                  alt="english"
                  width="25"
                  height="25"
                  alt="English language"
                />
              </picture>
            </a>
          </div>
        </div>
        <!-- menu-flowaccount -->
        <div id="menu-flowaccount">
          <div class="clearfix">
            <!-- flowaccount-logo -->
            <div id="flowaccount-logo">
              <a
                [routerLink]="[translate.currentLang === 'th' ? '/' : translate.currentLang]"
                routerLinkActive="current"
                (click)="goToHomePage()"
              >
                <picture>
                  <source
                    srcset="
                      https://flowaccountcdn.com/new_landing/image/FlowAccount_Products_Logo_Logo_FlowAccount.webp
                    "
                    type="image/webp"
                  />
                  <source
                    srcset="https://flowaccountcdn.com/new_landing/image/FlowAccount_Products_Logo_Logo_FlowAccount.png"
                    type="image/jpeg"
                  />
                  <img
                    src="https://flowaccountcdn.com/new_landing/image/FlowAccount_Products_Logo_Logo_FlowAccount.png"
                    width="100%"
                    height="auto"
                    alt="โปรแกรมบัญชี FlowAccount"
                    title="โปรแกรมบัญชี FlowAccount"
                  />
                </picture>
                <!-- <img src="https://flowaccountcdn.com/new_landing/image/FlowAccount_Logo_2020.png?v=2" /> -->
              </a>
            </div>
            <!-- flowaccount-menu -->
            <div id="flowaccount-menu" class="clearfix flowaccount-landing">
              <!-- hamburger -->
              <div class="hamburger-div">
                <!--  (click)="toggleMobileMenu($event)" -->
                <input
                  type="checkbox"
                  id="mobile-menu-check"
                  aria-label="mobile menu check"
                  onclick="onMobileMenuClick()"
                  [checked]="mobileMenuChecked"
                />
                <div class="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <!-- flowaccount-list-menu -->
                <div id="flowaccount-list-menu" class="clearfix">
                  <!-- flow-menu-div -->
                  <landing-navigation-list appName="flowaccount-landing"></landing-navigation-list>
                  <div id="flow-button-div">
                    <a
                      href="https://auth.flowaccount.com/{{ translate.currentLang }}/Account/Register"
                      class="flowbtn app-btn"
                      (click)="setCookiePath()"
                      [ngClass]="{ 'custom-navbar-btn': isOnNavbar2022 }"
                      (click)="goToSignup()"
                      >{{ 'Navigation.Sign up' | translate }}</a
                    >
                    <a
                      href="https://form.flowaccount.com/register-seminar-business-owner-beginner"
                      class="flowbtn app-border-btn"
                      [ngClass]="{ 'custom-navbar-btn': isOnNavbar2022 }"
                      (click)="goToDemoSchedule()"
                      >{{ 'Navigation.Demo' | translate }}</a
                    >
                    <a
                      href="https://auth.flowaccount.com/{{ translate.currentLang }}"
                      class="flowbtn app-no-border-btn"
                      [ngClass]="{ 'custom-navbar-btn': isOnNavbar2022 }"
                      (click)="goToLogin()"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon-btn"
                      >
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 10.5H0V8.5H12V10.5Z" fill="#2898CB" />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.20717 5.29297L12.7072 8.79296C13.0977 9.18346 13.0977 9.81666 12.7072 10.2072L9.20717 13.7072L7.79297 12.293L10.5859 9.50006L7.79297 6.70718L9.20717 5.29297Z"
                          fill="#2898CB"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5 1.5C5 0.94772 5.44772 0.5 6 0.5H18C18.5523 0.5 19 0.94772 19 1.5V17.5C19 18.0523 18.5523 18.5 18 18.5H6C5.44772 18.5 5 18.0523 5 17.5V14.5H7V16.5H17V2.5H7V4.5H5V1.5Z"
                          fill="#2898CB"
                        />
                      </svg>
                      {{ 'Navigation.Log in' | translate }}
                    </a>
                    <a class="flowbtn showres-navdisplay"></a>
                  </div>
                </div>
                <div id="flowaccount-bg-ipad">&nbsp;</div>
                <!--  (click)="toggleMobileMenu($event)" -->
                <!-- end - flowaccount-list-menu -->
              </div>
            </div>
          </div>
        </div>
        <!-- end menu-flowaccount -->
      </div>
    </section>
  </header>
  <!-- END - HEADER -->
</ng-container>
