import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SharedService } from '@flowaccount/landing'

@Component({
  selector: 'flowaccount-mobile-pos-thermal-printers',
  templateUrl: './mobile-pos-thermal-printers.component.html',
  styleUrls: ['./mobile-pos-thermal-printers.component.scss'],
})
export class MobilePosThermalPrintersComponent implements OnInit {
  constructor(public translate: TranslateService, public sharedService: SharedService) {}

  ngOnInit(): void {}
  showImage(src) {
    return this.sharedService.showImage(src)
  }
}
