import { Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'

@Injectable()
export class SignupFlowService {
  private readonly cookieName = 'fa_pr'

  constructor(private cookieService: CookieService) {}

  setCookie(value: string, isProduction: boolean): void {
    if (this.cookieService.get(this.cookieName)) {
      this.cookieService.delete(this.cookieName)
    }

    this.cookieService.set(this.cookieName, value, {
      secure: true,
      sameSite: 'Lax',
      path: '/',
      expires: this.getSevenExpiresDay(),
      domain: isProduction ? '.flowaccount.com' : 'localhost',
    })
  }

  private getSevenExpiresDay(): Date {
    const date = new Date()
    date.setDate(date.getDate() + 7)
    return date
  }
}
