<div class="flowaccount-landing">
  <landing-navigation
    (trackLogoNavEvent)="onLogoNavEventTrack($event)"
    (trackSignupNavEvent)="onSignupNavEventTrack($event)"
    (trackDemoNavEvent)="onDemoNavEventTrack($event)"
    (trackLoginNavEvent)="onLoginNavEventTrack($event)"
  >
  </landing-navigation>
  <div class="advance-flow-container" [ngClass]="{ safari: isSafari }">
    <router-outlet></router-outlet>
  </div>
  <footer class="advance-flow-footer">
    <!-- site map -->
    <flowaccount-sitemap></flowaccount-sitemap>
    <!-- contactus -->
    <div id="contactus">
      <div class="detail font_Prakas">
        <!-- max-width: 980px; -->
        <!-- ngIf False -->
        <div class="container" style="padding-bottom: 15px; text-align: left">
          <div class="row-footer footer-contact-section">
            <div class="center-block col-detail detail-left footer-contact-section__left-div">
              <!-- old version for contact 23/12/2020 -->
              <div *ngIf="false">
                <div class="contactus-heading font_Prompt">
                  <div>
                    <div [innerHtml]="'call-us' | translate | safe: 'html'"></div>
                    <div class="margin-contact-heading">
                      <a href="tel:020268989" class="linkwhite"> 02-026-8989</a>
                    </div>
                    <!-- <i class="far fa-smile"></i>                           -->
                  </div>

                  <!-- ซ่อนไว้อย่าเปิด -->
                  <div *ngIf="false">
                    <span> <a href="tel:020268989" class="linkwhite">02-026-8989</a>, </span>
                    <span>
                      <a href="tel:022374777" class="linkwhite">02-237-4777</a>
                    </span>
                  </div>

                  <div class="email margin-contact-heading">
                    <a
                      href="mailto:support@flowaccount.com?Subject=ติดต่อสอบถามข้อมูล FlowAccount"
                      target="_top"
                      class="linkwhite"
                      translate="{{ 'Email' }}"
                    ></a>
                  </div>
                </div>

                <div class="working-time-margin">
                  <span style="display: flex; flex-direction: row">
                    <i class="fas fa-clock" style="position: relative; top: 3px"></i>&nbsp;
                    <p
                      class="working-fz"
                      style="margin: 0 0 0px"
                      [innerHtml]="'Working hours' | translate | safe: 'html'"
                    ></p>
                  </span>
                  <div class="working-fz">
                    <span class="day">{{ 'Working hours2_line1' | translate }}</span
                    ><span>9:00-18:00</span><br />
                    <!-- <span class="day">{{'Working hours2_line2' | translate}}</span><span>9:00-18:00</span> -->
                  </div>

                  <div class="social-style-mobile">
                    <a
                      href="https://www.facebook.com/flowaccount"
                      aria-label="social-link-facebook"
                      rel="nofollow noopener"
                      target="_blank"
                    >
                      <div class="image-fbicon icon-style">
                        <i class="fab fa-facebook-square"></i>
                      </div>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCPQ_g-oZBwyLPKk_1IWAWOg"
                      aria-label="social-link-youtube"
                      rel="nofollow noopener"
                      target="_blank"
                    >
                      <div class="image-yticon icon-style youtube-position">
                        <i class="fab fa-youtube"></i>
                      </div>
                    </a>
                    <a
                      href="https://lin.ee/oM5V2Un"
                      aria-label="social-link-line"
                      rel="nofollow noopener"
                      target="_blank"
                    >
                      <div class="image-lineicon icon-style line-position">
                        <i class="fab fa-line"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="!hasContactUsPage">
                <div class="contact-sales">
                  <div class="head">{{ 'NEW_CONTACT.CONTACT_CUSTOMET_SERVICE' | translate }}</div>
                  <div class="detail"><i class="fas fa-phone"></i><a href="tel:020268989">02-026-8989</a></div>
                  <div class="detail"
                    ><i class="fas fa-envelope"></i
                    ><a href="mailto:support@flowaccount.com?Subject=ติดต่อฝ่ายบริการลูกค้า FlowAccount" target="_top"
                      >support&#64;flowaccount.com</a
                    ></div
                  >
                  <div class="detail"
                    ><i class="fas fa-clock"></i>{{ 'NEW_CONTACT.CCS_WORKING_HOUR' | translate }}<br />
                    <span class="ml-30">{{ 'NEW_CONTACT.MON-FRI' | translate }}</span
                    ><br />
                    <span class="ml-30">{{ 'NEW_CONTACT.CS_HOLIDAY_HOUR' | translate }}</span>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="hasContactUsPage">
                <div class="contact-sale-container">
                  <div class="contact-sales">
                    <div class="head">{{ 'NEW_CONTACT.CONTACT_CUSTOMET_SERVICE' | translate }}</div>
                    <div class="detail"
                      ><i class="fas fa-phone"></i
                      ><a class="fa-hover-line-white" href="tel:020268989"
                        >02-026-8989<i class="fas fa-angle-right right"></i></a
                    ></div>
                    <div class="detail"
                      ><i class="fas fa-envelope"></i
                      ><a
                        class="fa-hover-line-white"
                        href="mailto:support@flowaccount.com?Subject=ติดต่อฝ่ายบริการลูกค้า FlowAccount"
                        target="_top"
                        >support&#64;flowaccount.com<i class="fas fa-angle-right right"></i></a
                    ></div>
                    <div class="detail"
                      ><i class="fas fa-clock"></i>{{ 'NEW_CONTACT.CCS_WORKING_HOUR' | translate }}<br />
                      <span class="ml-30">{{ 'NEW_CONTACT.MON-FRI' | translate }}</span
                      ><br />
                      <div class="ml-30">
                        <span>{{ 'NEW_CONTACT.CS_HOLIDAY_HOUR' | translate }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- contact-sale-tablet -->
                  <div class="contact-sales contact-sale-tablet">
                    <div class="head">{{ 'NEW_CONTACT.CONTACT_SALES' | translate }}</div>
                    <div class="detail mobile">{{ 'NEW_CONTACT.CONTACT_SALES_DETAIL' | translate }}</div>
                    <div class="detail"
                      ><i class="fas fa-phone"></i
                      ><a href="tel:020268991">02-026-8991<i class="fas fa-angle-right right"></i></a
                    ></div>
                    <div class="detail"
                      ><i class="fas fa-envelope"></i
                      ><a href="mailto:demo@flowaccount.com?Subject=ติดต่อฝ่ายขาย FlowAccount" target="_top"
                        >demo&#64;flowaccount.com <i class="fas fa-angle-right right"></i></a
                    ></div>
                    <div class="detail mobile"
                      ><i class="fas fa-clock"></i
                      ><div>
                        {{ 'NEW_CONTACT.CCS_WORKING_HOUR' | translate }} <br />
                        <span>{{ 'NEW_CONTACT.CS_WORKING_HOUR' | translate }}</span></div
                      ></div
                    >
                    <div class="detail link-to-sale-page"
                      ><i class="fas fa-comment"></i
                      ><a href="javascript:void(0)" [routerLink]="['contact-us']"
                        >{{ 'SEND MESSAGE' | translate }}<i class="fas fa-angle-right right"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- social icon for mobile -->
              <div class="social-icon-mobile">
                <div class="social-wrapper">
                  <!--  facebook -->
                  <a
                    href="https://www.facebook.com/flowaccount"
                    aria-label="social-link-facebook"
                    rel="nofollow noopener"
                    target="_blank"
                  >
                    <div class="image-fbicon icon-style">
                      <i class="fab fa-facebook-square"></i>
                    </div>
                  </a>
                  <!-- youtube -->
                  <a
                    href="https://www.youtube.com/channel/UCPQ_g-oZBwyLPKk_1IWAWOg"
                    aria-label="social-link-youtube"
                    rel="nofollow noopener"
                    target="_blank"
                    class="youtube-icon"
                  >
                    <div class="image-yticon icon-style">
                      <i class="fab fa-youtube"></i>
                    </div>
                  </a>
                  <!-- instagram -->
                  <a
                    href="https://www.instagram.com/flowaccount/"
                    aria-label="social-link-instagram"
                    rel="nofollow noopener"
                    target="_blank"
                    class="instagram-icon"
                  >
                    <div class="image-igicon icon-style">
                      <i class="fab fa-instagram"></i>
                    </div>
                  </a>
                  <!-- tiktok -->
                  <a
                    href="https://www.tiktok.com/@flowaccountchannel?is_from_webapp=1&sender_device=pc"
                    aria-label="social-link-tiktok"
                    rel="nofollow noopener"
                    target="_blank"
                    class="tiktok-icon"
                  >
                    <div class="image-tticon icon-style tiktok-position">
                      <svg
                        class="tiktok-svg-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="26.5"
                        height="25"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="currentColor"
                          d="M448 209.91a210.06 210.06 0 0 1-122.77-39.25v178.72A162.55 162.55 0 1 1 185 188.31v89.89a74.62 74.62 0 1 0 52.23 71.18V0h88a121.18 121.18 0 0 0 1.86 22.17A122.18 122.18 0 0 0 381 102.39a121.43 121.43 0 0 0 67 20.14Z"
                        />
                      </svg>
                    </div>
                  </a>
                  <!-- medium -->
                  <a
                    href="https://medium.com/flowaccount-tech"
                    aria-label="social-link-medium"
                    rel="nofollow noopener"
                    target="_blank"
                    class="medium-icon"
                  >
                    <picture>
                      <source
                        srcset="https://flowaccountcdn.com/new_landing/image/work-with-us/techBlog/Medium.webp"
                        type="image/webp"
                      />
                      <source
                        srcset="https://flowaccountcdn.com/new_landing/image/work-with-us/techBlog/Medium.png"
                        type="image/png"
                      />
                      <img
                        src="https://flowaccountcdn.com/new_landing/image/work-with-us/techBlog/Medium.png"
                        class="icon-play"
                        alt="medium logo"
                        title="medium logo"
                        width="100"
                        height="auto"
                        loading="lazy"
                      />
                    </picture>
                  </a>
                </div>
              </div>

              <div class="line-div iPhone-5-layout" [ngClass]="{ 'social-app-container': hasContactUsPage }">
                <!-- <div class="padding-line">
                            <div class="line-it-button" data-lang="en" data-type="friend" data-lineid="https://line.me/ti/p/U2ujvjVqD2" data-count="true"
                                data-home="true" style="display: none;"></div>
                            <script src="https://d.line-scdn.net/r/web/social-plugin/js/thirdparty/loader.min.js" async="async" defer="defer"></script>
                            <div>
                                    <a href="https://line.me/ti/p/U2ujvjVqD2" target="_blank">
                                        <img src="/images/addline.png" class="image-line" alt="flowaccount qrcode">
                                    </a>
                            </div>
                        </div> -->

                <div class="padding-line padding-qr">
                  <!-- social and download app -->
                  <div style="display: table" class="app-btn-wrapper">
                    <div class="social-qrcode" style="display: table-cell; vertical-align: bottom">
                      <a href="https://lin.ee/oM5V2Un" rel="nofollow noopener" target="_blank">
                        <div class="image-line">
                          <picture>
                            <source
                              srcset="https://flowaccountcdn.com/assets/image/_WebP/qrcodeline.webp"
                              type="image/webp"
                            />
                            <source srcset="https://flowaccountcdn.com/images/qrcodeline.JPG" type="image/jpeg" />
                            <img
                              src="https://flowaccountcdn.com/images/qrcodeline.JPG"
                              alt="flowaccount qrcode"
                              title=""
                              width="100%"
                              loading="lazy"
                            />
                          </picture>
                        </div>
                        <!-- <img lazy-load src="https://flowaccountcdn.com/images/qrcodeline.JPG"
                                                        class="image-line" alt="flowaccount qrcode"> -->
                      </a>
                    </div>

                    <div style="display: table-cell">
                      <div class="downloadapp-desktop">
                        <a
                          href="https://itunes.apple.com/th/app/new-flowaccount/id1454207520"
                          rel="nofollow noopener"
                          target="_blank"
                          aria-label="Donwload IOS"
                        >
                          <div class="app-ios" style="max-width: 90px; width: 100%; margin-left: 25px">
                            <picture>
                              <source
                                srcset="https://flowaccountcdn.com/new_landing/image/logo/iOS.webp"
                                type="image/webp"
                              />
                              <source
                                srcset="https://flowaccountcdn.com/new_landing/image/logo/iOS.png"
                                type="image/png"
                              />
                              <img
                                src="https://flowaccountcdn.com/new_landing/image/logo/iOS.png"
                                width="100%"
                                height="auto"
                                alt="flowaccount ios application"
                                title=""
                                loading="lazy"
                              />
                            </picture>
                          </div>
                        </a>

                        <a
                          href="https://play.google.com/store/apps/details?id=com.flowaccount.android&hl=th"
                          rel="nofollow noopener"
                          target="_blank"
                          aria-label="Donwload Android"
                        >
                          <div class="app-android" style="max-width: 100px; width: 100%; margin-left: 15px">
                            <picture>
                              <source
                                srcset="https://flowaccountcdn.com/new_landing/image/logo/Android.webp"
                                type="image/webp"
                              />
                              <source
                                srcset="https://flowaccountcdn.com/new_landing/image/logo/Android.png"
                                type="image/jpeg"
                              />
                              <img
                                src="https://flowaccountcdn.com/new_landing/image/logo/Android.png"
                                width="100%"
                                height="auto"
                                alt="flowaccount ios application"
                                title=""
                                loading="lazy"
                              />
                            </picture>
                          </div>
                        </a>

                        <!-- huawei app gallery -->
                        <a
                          href="https://appgallery.huawei.com/app/C105848487"
                          rel="nofollow noopener"
                          target="_blank"
                          aria-label="Download  huawei app gallery"
                        >
                          <div style="max-width: 100px; width: 100%; margin-left: 15px">
                            <picture>
                              <source
                                srcset="https://flowaccountcdn.com/new_landing/image/logo/Huawei.webp"
                                type="image/webp"
                              />
                              <source
                                srcset="https://flowaccountcdn.com/new_landing/image/logo/Huawei.png"
                                type="image/png"
                              />
                              <img
                                src="https://flowaccountcdn.com/new_landing/image/logo/Huawei.png"
                                width="100%"
                                height="auto"
                                alt="flowaccount  huawei app gallery application"
                                title=""
                                loading="lazy"
                              />
                            </picture>
                          </div>
                        </a>
                      </div>

                      <div class="downloadapp-mobile">
                        <div>
                          <a
                            href="https://itunes.apple.com/th/app/new-flowaccount/id1454207520"
                            rel="nofollow noopener"
                            target="_blank"
                            aria-label="Donwload ios gallery"
                          >
                            <div class="app-ios">
                              <picture>
                                <source
                                  srcset="https://flowaccountcdn.com/new_landing/image/logo/iOS.webp"
                                  type="image/webp"
                                />
                                <source
                                  srcset="https://flowaccountcdn.com/new_landing/image/logo/iOS.png"
                                  type="image/jpeg"
                                />
                                <img
                                  src="https://flowaccountcdn.com/new_landing/image/logo/iOS.png"
                                  width="100%"
                                  height="auto"
                                  alt="flowaccount ios application"
                                  title=""
                                  loading="lazy"
                                />
                              </picture>
                            </div>
                          </a>
                        </div>

                        <div>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.flowaccount.android&hl=th"
                            rel="nofollow noopener"
                            target="_blank"
                            aria-label="Donwload android"
                          >
                            <div class="app-android">
                              <picture>
                                <source
                                  srcset="https://flowaccountcdn.com/new_landing/image/logo/Android.webp"
                                  type="image/webp"
                                />
                                <source
                                  srcset="https://flowaccountcdn.com/new_landing/image/logo/Android.png"
                                  type="image/jpeg"
                                />
                                <img
                                  src="https://flowaccountcdn.com/new_landing/image/logo/Android.png"
                                  width="100%"
                                  height="auto"
                                  alt="flowaccount ios application"
                                  title=""
                                  loading="lazy"
                                />
                              </picture>
                            </div>
                          </a>
                        </div>

                        <!-- huawei app gallery -->
                        <div>
                          <a
                            href="https://appgallery.huawei.com/app/C105848487"
                            rel="nofollow noopener"
                            target="_blank"
                            aria-label="Donwload  huawei app gallery"
                          >
                            <div class="app-gallery">
                              <picture>
                                <source
                                  srcset="https://flowaccountcdn.com/new_landing/image/logo/huawei_app_gallery.webp"
                                  type="image/webp"
                                />
                                <source
                                  srcset="https://flowaccountcdn.com/new_landing/image/logo/Huawei.png"
                                  type="image/jpeg"
                                />
                                <img
                                  src="https://flowaccountcdn.com/new_landing/image/logo/Huawei.png"
                                  width="100%"
                                  height="auto"
                                  alt="flowaccount huawei app gallery application"
                                  title=""
                                  loading="lazy"
                                />
                              </picture>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="social-style">
                        <a
                          href="https://www.facebook.com/flowaccount"
                          aria-label="social-link-facebook"
                          rel="nofollow noopener"
                          target="_blank"
                        >
                          <div class="image-fbicon icon-style">
                            <i class="fab fa-facebook-square"></i>
                          </div>
                        </a>
                        <a
                          href="https://www.youtube.com/channel/UCPQ_g-oZBwyLPKk_1IWAWOg"
                          aria-label="social-link-youtube"
                          rel="nofollow noopener"
                          target="_blank"
                          class="youtube-icon"
                        >
                          <div class="image-yticon icon-style youtube-position">
                            <i class="fab fa-youtube"></i>
                          </div>
                        </a>
                        <a
                          href="https://www.instagram.com/flowaccount/"
                          aria-label="social-link-instagram"
                          rel="nofollow noopener"
                          target="_blank"
                          class="instagram-icon"
                        >
                          <div class="image-yticon icon-style instagram-position">
                            <i class="fab fa-instagram"></i>
                          </div>
                        </a>
                        <a
                          href="https://www.tiktok.com/@flowaccountchannel?is_from_webapp=1&sender_device=pc"
                          aria-label="social-link-tiktok"
                          rel="nofollow noopener"
                          target="_blank"
                          class="tiktok-icon"
                        >
                          <div class="image-tticon icon-style tiktok-position">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26.5" height="30" viewBox="0 0 500 512">
                              <path
                                fill="currentColor"
                                d="M448 209.91a210.06 210.06 0 0 1-122.77-39.25v178.72A162.55 162.55 0 1 1 185 188.31v89.89a74.62 74.62 0 1 0 52.23 71.18V0h88a121.18 121.18 0 0 0 1.86 22.17A122.18 122.18 0 0 0 381 102.39a121.43 121.43 0 0 0 67 20.14Z"
                              />
                            </svg>
                          </div>
                        </a>
                        <!-- medium -->
                        <a
                          href="https://medium.com/flowaccount-tech"
                          aria-label="social-link-medium"
                          rel="nofollow noopener"
                          target="_blank"
                          class="medium-icon"
                        >
                          <picture>
                            <source
                              srcset="https://flowaccountcdn.com/new_landing/image/work-with-us/techBlog/Medium.webp"
                              type="image/webp"
                            />
                            <source
                              srcset="https://flowaccountcdn.com/new_landing/image/work-with-us/techBlog/Medium.png"
                              type="image/png"
                            />
                            <img
                              src="https://flowaccountcdn.com/new_landing/image/work-with-us/techBlog/Medium.png"
                              class="icon-play"
                              alt="medium logo"
                              title="medium logo"
                              width="100"
                              height="auto"
                              loading="lazy"
                            />
                          </picture>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="social-style" style="margin-left: 0; margin-top: 5px">
                    <a
                      class="line-add-friend"
                      href="https://lin.ee/mZPhj7W"
                      rel="nofollow noopener"
                      target="_blank"
                      aria-label="Line add  friend"
                      ><img
                        src="https://scdn.line-apps.com/n/line_add_friends/btn/{{
                          translate.currentLang === 'th' ? 'th' : 'en'
                        }}.png"
                        alt="เพิ่มเพื่อน"
                        height="36"
                        width="auto"
                        border="0"
                    /></a>
                  </div>
                </div>
              </div>
              <div class="line-icon-mobile">
                <a
                  class="line-add-friend"
                  href="https://lin.ee/mZPhj7W"
                  rel="nofollow noopener"
                  target="_blank"
                  aria-label="Line add  friend"
                  ><img
                    src="https://scdn.line-apps.com/n/line_add_friends/btn/{{
                      translate.currentLang === 'th' ? 'th' : 'en'
                    }}.png"
                    alt="เพิ่มเพื่อน"
                    height="36"
                    width="auto"
                    border="0"
                /></a>
              </div>
              <!--(ตำแหน่งเก่า) : ก่อนจะย้ายไปแทนที่ contact-form -->
              <div *ngIf="!hasContactUsPage" class="detail-bottom" style="margin-top: 0px">
                <div class="detail-top">
                  <span [innerHtml]="'FlowaccountCompany' | translate | safe: 'html'"></span>
                  <br />
                  <span [innerHtml]="'ADDRESSDETAILS' | translate | safe: 'html'"></span><br />
                  <a
                    href="https://goo.gl/maps/FHh3jQTjovv"
                    rel="nofollow noopener"
                    class="footer_index"
                    target="_blank"
                    [innerHtml]="'ViewMap' | translate | safe: 'html'"
                    ><i class="far fa-map"></i
                  ></a>
                </div>
                <div class="social">
                  <!-- <a class="ground" target="_blank" href="https://www.youtube.com/channel/UCPQ_g-oZBwyLPKk_1IWAWOg">
                                <i class="fa fa-youtube-play" />
                                <span>Youtube</span>
                            </a>
                            <a class="ground" target="_blank" href="https://www.facebook.com/flowaccount">
                                <i class="fa fa-facebook-official" />
                                <span>Facebook</span>
                            </a> -->
                  <!-- <a class="ground" href="#" ng-click="open()">
                                <i class="fa fa-commenting" aria-hidden="true" />
                                <span>LINE @Flowaccount</span>
                            </a> -->
                  <br />
                  <!-- <a href="https://mixpanel.com/f/partner" rel="nofollow" target="_blank">
                                <img src="//cdn.mxpnl.com/site_media/images/partner/badge_light.png" alt="Mobile Analytics" style="width: 90px;">
                            </a> -->
                </div>
              </div>
            </div>
            <div
              class="form center-block col-send send-right footer-contact-section__right-div"
              [ngClass]="{ 'footer-contact-section__right-div': hasContactUsPage, 'send-right': !hasContactUsPage }"
            >
              <div *ngIf="false" style="margin-bottom: 23px">
                <div class="contactus-heading font_Prompt" translate="{{ 'SEND MESSAGE' }}"></div>
                <div class="contactus-detail" translate="{{ 'toFlowAccount' }}"></div>
              </div>

              <!-- contact-sale-desktop -->
              <div class="contact-sales contact-sale-desktop">
                <div class="head">{{ 'NEW_CONTACT.CONTACT_SALES' | translate }}</div>
                <div class="detail mobile">{{ 'NEW_CONTACT.CONTACT_SALES_DETAIL' | translate }}</div>
                <div class="detail"
                  ><i class="fas fa-phone"></i
                  ><a class="fa-hover-line-white" href="tel:020268991"
                    >02-026-8991 <i class="fas fa-angle-right right"></i></a
                ></div>
                <div class="detail"
                  ><i class="fas fa-envelope"></i
                  ><a
                    class="fa-hover-line-white"
                    href="mailto:demo@flowaccount.com?Subject=ติดต่อฝ่ายขาย FlowAccount"
                    target="_top"
                    >demo&#64;flowaccount.com <i class="fas fa-angle-right right"></i></a
                ></div>
                <div class="detail mobile"
                  ><i class="fas fa-clock"></i
                  ><div>
                    {{ 'NEW_CONTACT.CCS_WORKING_HOUR' | translate }} <br />
                    <span>{{ 'NEW_CONTACT.CS_WORKING_HOUR' | translate }}</span></div
                  ></div
                >
                <div class="detail link-to-sale-page"
                  ><i class="fas fa-comment"></i
                  ><a href="javascript:void(0)" [routerLink]="['contact-us']"
                    >{{ 'SEND MESSAGE' | translate }}<i class="fas fa-angle-right right"></i
                  ></a>
                </div>
              </div>

              <!-- ย้ายส่วนของ contact form ไปหน้า /contact-us (อนาคตลบได้ถ้าไม่เกิดปัญหาอะไร)  -->
              <ng-cotainer *ngIf="!hasContactUsPage">
                <form
                  style="margin-top: 20px"
                  action="https://app.flowaccount.com/email/ContactUsEmailFlowAccount"
                  method="POST"
                  id="contact-form"
                  #contactForm
                >
                  <div class="row">
                    <div class="col-md-12 input-border">
                      <div class="form-group name">
                        <label for="name" style="display: none">{{ 'Your Name' | translate }}</label>
                        <input
                          class="flow-input form-control flow-validate"
                          type="text"
                          placeholder="{{ 'Your Name' | translate }}"
                          id="name"
                          name="name"
                        />
                        <p id="p1" class="hidden lbl-valid" translate="{{ 'Please enter your name' }}"></p>
                      </div>
                      <div class="form-group phone">
                        <label for="phone" style="display: none">{{ 'Your Phone' | translate }}</label>
                        <input
                          class="flow-input form-control flow-validate"
                          type="tel"
                          placeholder="{{ 'Your Phone' | translate }}"
                          id="phone"
                          name="phone"
                        />
                        <p id="p1" class="hidden lbl-valid" translate="{{ 'Please enter your phone' }}"></p>
                      </div>
                    </div>
                    <div class="col-md-12 input-email">
                      <div class="form-group">
                        <label for="email" style="display: none">{{ 'E-MAIL' | translate }}</label>
                        <input
                          class="flow-input form-control flow-validate"
                          type="email"
                          placeholder="{{ 'E-MAIL' | translate }}"
                          id="email"
                          name="email"
                        />
                        <p id="p2" class="hidden lbl-valid" translate="{{ 'Please enter your email' }}"></p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="message" style="display: none">{{ 'Enter message here...' | translate }}</label>
                        <textarea
                          class="flow-textarea form-control flow-validate"
                          id="message"
                          placeholder="{{ 'Enter message here...' | translate }}"
                          name="message"
                        ></textarea>
                        <p id="p4" class="hidden lbl-valid" translate="{{ 'Please enter the Messege' }}"></p>
                      </div>
                    </div>
                  </div>

                  <div class="captcha-submit" *ngIf="!scullyRunning && displayCaptcha">
                    <div class="recaptcha">
                      <!-- <recaptcha-demo-wrapper> -->
                      <re-captcha
                        name="captcha"
                        (resolved)="resolved($event)"
                        siteKey="6LdT1tMUAAAAAOcJfvpP6F7F8Y7RyBwyTi2ajPLm"
                        #captchaResponseId
                        required
                      ></re-captcha>
                      <!-- <div [hidden]="!captchaResponseId" class="error">The form must be filled out</div> -->
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <button
                            id="tracking-contactsales"
                            class="flow-btn-submit"
                            type="submit"
                            (click)="submitContact()"
                            translate="{{ 'SEND MESSAGE' }}"
                            [innerHTML]="captchaResponseId ? '' : 'disabled'"
                          >
                          </button>
                          <button
                            id="tracking-contactsales-mobile"
                            class="flow-btn-submit_mobile"
                            type="submit"
                            (click)="submitContact()"
                            translate="{{ 'SEND MESSAGE_mobile' }}"
                            [innerHTML]="captchaResponseId ? '' : 'disabled'"
                          >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="recaptcha-check">{{ 'Recaptcha_check' | translate }}</div>
                </form>
              </ng-cotainer>

              <!-- (ตำแหน่งใหม่) นำส่วนของที่อยู่บริษัทมาแทนที่ contact form -->
              <ng-container *ngIf="hasContactUsPage">
                <div class="detail-bottom footer-company-address">
                  <div class="detail-top">
                    <span [innerHtml]="'FlowaccountCompany' | translate | safe: 'html'"></span>
                    <br />
                    <span [innerHtml]="'ADDRESSDETAILS' | translate | safe: 'html'"></span><br />
                    <a
                      href="https://goo.gl/maps/FHh3jQTjovv"
                      rel="nofollow noopener"
                      class="footer_index"
                      target="_blank"
                      [innerHtml]="'ViewMap' | translate | safe: 'html'"
                      ><i class="far fa-map"></i
                    ></a>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="row text-center copyright no-padding" style="display: block">
            <p class="center-block">Copyright © {{ _year }} FlowAccount Co., Ltd. All rights reserved.</p>
          </div>
        </div>

        <!-- New footer -->
      </div>
    </div>
  </footer>
  <!-- modal -->
  <div class="backdrop" [ngStyle]="{ display: display }"></div>
  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{ display: display }">
    <div class="modal-dialog" style="max-width: 700px">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">{{ 'Complete_message' | translate }}</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="onCloseHandled()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body"> {{ 'Thanks' | translate }}<br />{{ 'Thanks2' | translate }} </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="onCloseHandled()">{{ 'Close' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<swal #videoSwal showCancelButton="false" showConfirmButton="false">
  <iframe
    *swalPartial
    id="modal-video-function"
    width="100%"
    height="100%"
    src=""
    frameborder="0"
    allowfullscreen=""
  ></iframe>
</swal>

<flowaccount-social-chat></flowaccount-social-chat>
