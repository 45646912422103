import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { SharedService } from '@flowaccount/landing'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'flowaccount-mobile-pos-cashier',
  templateUrl: './mobile-pos-cashier.component.html',
  styleUrls: ['./mobile-pos-cashier.component.scss'],
})
export class MobilePosCashierComponent implements OnInit {
  public currentSlide = 1
  public intervalSlide
  constructor(
    public translate: TranslateService,
    public sharedService: SharedService,
    public cdref: ChangeDetectorRef,
  ) {}

  public cashierList = [
    {
      subtitle: 'MOBILE_POS.CASHIER_SWIPER.LIST.1.SUB_TITLE',
      titleDesktop: 'MOBILE_POS.CASHIER_SWIPER.LIST.1.TITLE',
      titleTablet: 'MOBILE_POS.CASHIER_SWIPER.LIST.1.TITLE',
      titleMobile: 'MOBILE_POS.CASHIER_SWIPER.LIST.1.TITLE_MOBILE',
      description: 'MOBILE_POS.CASHIER_SWIPER.LIST.1.DESCRIPTION',
      descriptionMobile: 'MOBILE_POS.CASHIER_SWIPER.LIST.1.DESCRIPTION_MOBILE',
      imgWebp: 'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobilepos_section_2_Slide_1.webp',
      img: 'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobilepos_section_2_Slide_1.png',
      linkVideo: '',
      readMore: '',
      alt: 'โปรแกรมทำผังบัญชี',
    },
    {
      subtitle: 'MOBILE_POS.CASHIER_SWIPER.LIST.2.SUB_TITLE',
      titleDesktop: 'MOBILE_POS.CASHIER_SWIPER.LIST.2.TITLE',
      titleTablet: 'MOBILE_POS.CASHIER_SWIPER.LIST.2.TITLE',
      titleMobile: 'MOBILE_POS.CASHIER_SWIPER.LIST.2.TITLE_MOBILE',
      description: 'MOBILE_POS.CASHIER_SWIPER.LIST.2.DESCRIPTION',
      descriptionMobile: 'MOBILE_POS.CASHIER_SWIPER.LIST.2.DESCRIPTION_MOBILE',
      imgWebp: 'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobilepos_section_2_Slide_2_v2.webp',
      img: 'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobilepos_section_2_Slide_2_v2.png',
      linkVideo: '',
      readMore: '',
      alt: 'โปรแกรมบันทึกรายได้และค่าใช้จ่าย',
    },
    {
      subtitle: 'MOBILE_POS.CASHIER_SWIPER.LIST.3.SUB_TITLE',
      titleDesktop: 'MOBILE_POS.CASHIER_SWIPER.LIST.3.TITLE',
      titleTablet: 'MOBILE_POS.CASHIER_SWIPER.LIST.3.TITLE',
      titleMobile: 'MOBILE_POS.CASHIER_SWIPER.LIST.3.TITLE_MOBILE',
      description: 'MOBILE_POS.CASHIER_SWIPER.LIST.3.DESCRIPTION',
      descriptionMobile: 'MOBILE_POS.CASHIER_SWIPER.LIST.3.DESCRIPTION_MOBILE',
      imgWebp: 'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobilepos_section_2_Slide_3.webp',
      img: 'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobilepos_section_2_Slide_3.png',
      linkVideo: '',
      readMore: '',
      alt: 'ดูกระทบยอด',
    },
    {
      subtitle: 'MOBILE_POS.CASHIER_SWIPER.LIST.4.SUB_TITLE',
      titleDesktop: 'MOBILE_POS.CASHIER_SWIPER.LIST.4.TITLE',
      titleTablet: 'MOBILE_POS.CASHIER_SWIPER.LIST.4.TITLE',
      titleMobile: 'MOBILE_POS.CASHIER_SWIPER.LIST.4.TITLE_MOBILE',
      description: 'MOBILE_POS.CASHIER_SWIPER.LIST.4.DESCRIPTION',
      descriptionMobile: 'MOBILE_POS.CASHIER_SWIPER.LIST.4.DESCRIPTION_MOBILE',
      imgWebp: 'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobilepos_section_2_Slide_4.webp',
      img: 'https://flowaccountcdn.com/new_landing/image/functions/mobile_pos/mobilepos_section_2_Slide_4.png',
      linkVideo: '',
      readMore: '',
      alt: 'ยื่นภาษีออนไลน์',
    },
  ]

  ngOnInit(): void {
    this.setTimeOutNexSlide()
  }

  ngDestroy() {
    clearInterval(this.intervalSlide)
  }

  actionClickSlide(actionName: string) {
    const slide = this.currentSlide
    const speakerListLength = this.cashierList.length
    if (actionName === 'prev') {
      if (slide === 1) {
        this.currentSlide = speakerListLength
      } else {
        this.currentSlide -= 1
      }
    } else if (actionName === 'next') {
      if (slide === speakerListLength) {
        this.currentSlide = 1
      } else {
        this.currentSlide += 1
      }
    }
    clearInterval(this.intervalSlide)
    this.setTimeOutNexSlide()
  }

  displaySlideClass(index) {
    if (index === this.currentSlide) return 'slide--' + index
  }

  linkVideo(youtubeURL: string) {
    return this.sharedService.openVideoTutorialModal(youtubeURL)
  }

  goToSlide(slideNo: number) {
    clearInterval(this.intervalSlide)
    this.setTimeOutNexSlide()
    return (this.currentSlide = slideNo)
  }

  setTimeOutNexSlide() {
    // 60 second for seeing each slide detail
    const time = 60000
    this.intervalSlide = setInterval(() => {
      if (this.currentSlide === this.cashierList.length) {
        this.currentSlide = 1
      } else {
        this.currentSlide += 1
      }
      // detect change for slide value change
      this.cdref.detectChanges()
    }, time)
  }
}
